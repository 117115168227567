import {useState} from 'react';
import useAxios from 'library/axios'; 
import { useTranslation } from 'react-i18next';
import IEnumeration, {defaultEnumerationItem, IEnumerationItem, IEnumerationSearch} from '../models/Enumeration';
import { HeadCell } from 'components/ui/EnhancedTable';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useRecoilValue } from 'recoil';
import { currentUserSessionAtom } from 'library/store';
import { isFalsy } from 'utility-types';
import { IResult } from 'library/interface';

import { useSnackbar } from 'notistack';

const _ = () => {

    const axios = useAxios();    

    const createEnumeration = async (enumeration: IEnumeration)  =>       
      await (await axios.post('/api/configuration/enumeration/create', enumeration)).data;

    const updateEnumeration = async (enumeration: IEnumeration)  =>       
      await (await axios.post('/api/configuration/enumeration/update', enumeration)).data;

    const createEnumerationItem = async (enumerationItem: IEnumerationItem) : Promise<IResult<IEnumeration>>  =>       
      await (await axios.post('/api/configuration/enumeration/create-item', enumerationItem)).data;

    const createEnumerationItemWithEnumeration = async (enumerationItem: IEnumerationItem) : Promise<IResult<IEnumeration>>  =>       
      await (await axios.post('/api/configuration/enumeration/create-item-with-enumeration', enumerationItem)).data;

    const updateEnumerationItem = async (enumerationItem: IEnumerationItem) : Promise<IResult<IEnumeration>> =>       
      await (await axios.post('/api/configuration/enumeration/update-item', enumerationItem)).data;

    const getEnumeration = async (enumerationId  : number )  => {        
        const {data} = (await axios.get(`/api/configuration/enumeration/get-enumeration/${enumerationId}`));
        return await data;
      }

    const getEnumerations = async (criteria  : IEnumerationSearch) : Promise<IEnumeration[]> => {
        const {code, name, description} = criteria;
        
        const {data} = (await axios.get(`api/configuration/enumeration/get-enumerations?code=${code}&name=${name}&description=${description}`));
        return await data;
      }

      const getEnumerationItemsByEnumeration = async (enumerationId: number)  => {
        const {data} = (await axios.get(`api/configuration/enumeration/get-enumerationItems-by-enumeration/${enumerationId}`));
        return await data;
      }

    const getEnumerationItemsByEnumerationCodes = async (enumerationCodes: string[]) : Promise<IEnumerationItem[]>  => {
      const codes = enumerationCodes.join(':-:');
      const {data} = (await axios.get(`api/configuration/enumeration/get-enumerationItems-by-enumerationCodes/${codes}`));
      return await data;
    }

    const getEnumerationItemsByEnumerationCode = async (enumerationCode: string) : Promise<IEnumerationItem[]>  => {
        const {data} = (await axios.get(`api/configuration/enumeration/get-enumerationItems-by-enumerationCode/${enumerationCode}`));
        return await data;
      }

    const getExtensionEnumerationItemsBySchoolYear = async (schoolYearId: number) : Promise<IEnumerationItem[]>  => {
      const {data} = (await axios.get(`api/configuration/enumeration/get-extension-enumerationItems-by-schoolYear/${schoolYearId}`));
      return await data;
    }
    
    const getExtensionEnumerationItemsByProduct = async (productId: number) : Promise<IEnumerationItem[]>  => {
      const {data} = (await axios.get(`api/configuration/enumeration/get-extension-enumerationItems-by-product/${productId}`));
      return await data;
    }

    const getParameterEnumerationItemsByEdition = async (editionId: number) : Promise<IEnumerationItem[]>  => {
      const {data} = (await axios.get(`api/configuration/enumeration/get-parameter-enumerationItems-by-edition/${editionId}`));
      return await data;
    }    
    
    const getLanguagesList = async ()  => { //api/{module:controllerNamespace}/[controller]
        const {data} = (await axios.get(`api/configuration/enumeration/get-languages/`));
        return await data;
      }

      const getEnumerationItemsLanguage = async ()  => {
        const {data} = (await axios.get(`api/configuration/enumeration/get-enumerationItems-language/`));
        return await data;
      }  

      const getAsOptions = (enumItems: IEnumerationItem[] | undefined, enumerationCode: string): {value: string|number, name: string}[] => 
        (enumItems||[]).filter( itm => enumerationCode === '' || itm.enumerationCode === enumerationCode)
          .map( ({code, name}) => ({value:code, name}))
      

      
    return {    
        createEnumeration,    
        updateEnumeration,  

        createEnumerationItem,
        createEnumerationItemWithEnumeration,
        updateEnumerationItem,

        getEnumeration,

        getEnumerations,     
        getEnumerationItemsByEnumeration,
        
        getEnumerationItemsByEnumerationCodes, 

        getExtensionEnumerationItemsBySchoolYear,
        getExtensionEnumerationItemsByProduct,

        getParameterEnumerationItemsByEdition,

        getEnumerationItemsByEnumerationCode,
        getEnumerationItemsLanguage,
        
        getLanguagesList,
        getAsOptions,
      
    } 
}

export default _;


export const useBasicFilterEnumeration = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IEnumeration) => void  ) => {

  const { getEnumerations } = _();

  const { t, i18n } = useTranslation();   
    

  const [headEnumerationCells, setHeadEnumerationCells]  = useState<HeadCell<IEnumeration>[]>([
    {id:'code', label : t('Code'),  display: true, type: 'string', },
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },

  ]); 

  const [filterElements, ] = useState([       
        {name: 'code', text: t('Code'), value: ''},
        {name: 'name', text: t('Name'), value: ''}, 
        {name: 'description', text: t('Description'), value: ''},       
      ]);

  const [filteredEnumerations, ] = useState<IEnumeration[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IEnumeration[]> => {
            
    const code = filterElements.find( elt => elt.name === 'code')?.value || '';
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    
    const arr = await getEnumerations( {code, name, description} );
    
    return arr;
  }

  const objKey: keyof IEnumeration = 'id';

  return {
    title: t('Enumeration'), headCells: headEnumerationCells, objKey,
    filterElements, rows: filteredEnumerations, 
    onFilterButtonClick, onRowDoubleClick
  }
}

export const useEnumerationItemCreateUpdate = (  ) => {

  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {language: lg, taskCodes, userDescription, userName, 
    employeeId, roleEntities, applicationSetup, userStores} = useRecoilValue(currentUserSessionAtom);

  const [enumerationItem, setEnumerationItem] = useState<IEnumerationItem>(defaultEnumerationItem);
  const [crudAction, setCrudAction] = useState<'C'|'U'>('C');

  const [displayEnumerationItemDialog, setDisplayEnumerationItemDialog] = useState<boolean>(false);

  const {createEnumerationItem, createEnumerationItemWithEnumeration, updateEnumerationItem} = _();
  
  const [canAddEnumerationItem, setCanAddEnumerationItem] = useState<boolean>(
    roleEntities.some(e => e.entityName === 'Enumeration') && 
    (roleEntities.find(e => e.entityName === 'Enumeration')?.roleEntityFeatures || []).some(f => 
              f.featureName === 'CreateEnumerationItemWithEnumerationHandler' ||
              f.featureName === 'CreateEnumerationItemEnumerationHandler') );
  
  const [canUpdateEnumerationItem, setCanUpdateEnumerationItem] = useState<boolean>(
    roleEntities.some(e => e.entityName === 'Enumeration') && 
    (roleEntities.find(e => e.entityName === 'Enumeration')?.roleEntityFeatures || []).some(f => 
              f.featureName === 'UpdateEnumerationItemEnumerationHandler') );

  const [enumerationItemActionAdd, setEnumerationItemActionAdd] = useState<string>(
    roleEntities.some(e => e.entityName === 'Enumeration') && 
    (roleEntities.find(e => e.entityName === 'Enumeration')?.roleEntityFeatures || []).some(f => 
              f.featureName === 'CreateEnumerationItemWithEnumerationHandler') ? 
                'with-enumeration' :
    roleEntities.some(e => e.entityName === 'Enumeration') && 
    (roleEntities.find(e => e.entityName === 'Enumeration')?.roleEntityFeatures || []).some(f => 
              f.featureName === 'CreateEnumerationItemEnumerationHandler') ? 
                'without-enumeration' :  '' );

    const onAddItemClick = async (enumerationCode: string, enumerationName: string, enumerationDescription: string)  => {

      setCrudAction('C');
      setEnumerationItem({...defaultEnumerationItem, enumerationCode, enumerationName, enumerationDescription,
        code: '', name: '', description: '' });
      setDisplayEnumerationItemDialog(true);
    }

    const onUpdateItemClick = async (enumerationCode: string, enumerationName: string, enumerationDescription: string,
      enumerationItem?: IEnumerationItem )  => {
        
      if(isFalsy(enumerationItem)) return;

      const {id, code, name, description} = enumerationItem!;

      setEnumerationItem({...defaultEnumerationItem, id, code, name, description, enumerationCode, enumerationName, enumerationDescription });
      setCrudAction('U');
      setDisplayEnumerationItemDialog(true);
    }

    const saveEnumerationItem = async (enumItem: IEnumerationItem) : Promise<boolean> => {

      const res = (crudAction === 'U') ? await updateEnumerationItem(enumItem) :
                  (crudAction === 'C' && enumerationItemActionAdd === 'with-enumeration') ? await createEnumerationItemWithEnumeration(enumItem):
                  (crudAction === 'C' && enumerationItemActionAdd === 'without-enumeration') ? await createEnumerationItem(enumItem)
                    : await createEnumerationItem(enumItem);
      if(res.succeeded) {
        enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
        setDisplayEnumerationItemDialog(false);
      }
      
      return res.succeeded;
    } 

  
  const hideEnumerationItemDialog = () => {setDisplayEnumerationItemDialog(false);}

  return {
    canAddEnumerationItem, canUpdateEnumerationItem, enumerationItemActionAdd,
    enumerationItem, setEnumerationItem,
    onAddItemClick, onUpdateItemClick, 

    saveEnumerationItem,
    displayEnumerationItemDialog,
    hideEnumerationItemDialog
    // title: t('Enumeration'), headCells: headEnumerationCells, objKey,
    // filterElements, rows: filteredEnumerations, 
    // onFilterButtonClick, onRowDoubleClick
  }
}