import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';

import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import FunctionsIcon from '@mui/icons-material/Functions';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import TableRowsIcon from '@mui/icons-material/TableRows';
import MoreIcon from '@mui/icons-material/More';


import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { defaultFeatureDescription, IEntity, IFeatureDescription, IFeatureParameter, IResult } from 'library/interface';

import useTicketService, { useBasicFilterTicket } from './services/Ticket';

import {  ITicket, defaultTicket, ITicketComment, ITicketResolution, ITicketMessage, TicketType, ITicketEmployee, defaultTicketEmployee } from './models/Ticket';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';

import useEnumerationService, { useBasicFilterEnumeration, useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { Checkbox, Chip, FormControlLabel, FormHelperText, IconButton, InputAdornment, Link, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import IEnumeration, { Enum_TICKET_CATEGORY, Enum_TICKET_RESOLUTION, Enum_TICKET_SEVERITY, Enum_OPERATION_STATUS, Enum_TICKET_EMPLOYEE_ROLE,
                       Enum_TICKET_ENTITY, Enum_TARGET_SERVICE, IEnumerationItem, Enum_TICKET_STATUS_PURPOSE, Enum_TICKET_CLASS } from 'features/configuration/models/Enumeration';

import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import { typographyGroupBoxStyling, justifyCenter } from 'themes/commonStyles';
import { MdOutlineAdd } from 'react-icons/md';
import ExpressionBox from 'components/ui/ExpressionBox';
import EnhancedTable from 'components/ui/EnhancedTable';
import { useBasicFilterUser } from 'features/security/services/User';
import { IUser } from 'features/security/models/User';


import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { useBasicFilterProblem } from './services/Problem';
import { IProblem } from './models/Problem';

import {addYears, addMonths, addDays, subDays, addHours} from 'date-fns';
import { BasicPersonForm } from './BasicPersonForm';
import ArrayField from 'components/ui/ArrayField';
import { useBasicFilterEmployee } from 'features/setup/services/Employee';
import { IEmployee } from 'features/setup/models/Employee';
import { DialogEnumerationItemForm } from 'features/configuration/DialogEnumerationItemForm';
import { RichWysiwygEditor } from 'components/ui/RichWysiwygEditor';
import { stripHtml } from 'string-strip-html';

export const TicketForm: FC<ITicket> = (props: ITicket = defaultTicket) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { enqueueSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntitySaveAuthorization, executeEntityFeature} = useEntityService();
  const {createTicket, updateTicket} = useTicketService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();
 

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);


  const {language: lg, roleEntities, applicationSetup} = useRecoilValue(currentUserSessionAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterTicket = useBasicFilterTicket( 
    
    async (event: React.MouseEvent<unknown>, row: ITicket) => {
      const {id, title } = row;
      
      setIsSearchBoxShow(false);
      _setId(row.id);
    }
  );

  const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
    onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();

  
  const [openEmployeeFilter, setOpenEmployeeFilter] = useState(false);
  const basicFilterEmployee = useBasicFilterEmployee( 
      (event: React.MouseEvent<unknown>, row: IEmployee) => {
        const {id, firstName, lastName} = row;
          
        if(getValues().ticketEmployees.some(e => e.employeeId === id)) return;

        (refAppendTicketEmployees.current??emptyFunc)({...defaultTicketEmployee, id: 0, employeeId: id, firstName, lastName });

          setOpenEmployeeFilter(false);
      }
  );

  const refType = useRef<number>(0);

  
  const [openProblemFilter, setOpenProblemFilter] = useState(false);
  const basicFilterProblem = useBasicFilterProblem( 
      (event: React.MouseEvent<unknown>, row: IProblem) => {
          const {id, title} = row;
          setValue('problemId', id);
          setValue('problemTitle', title);

          //setOpenEmployeeFilter(false);
      }
  );
  
  const emptyFunc = (obj: any) => {}

  const methods = useForm<ITicket>({defaultValues:defaultTicket});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;
      
  const watchProblemId = watch('problemId');
  const watchStoreId = watch('storeId');
  const watchBillingId = watch('billingId');
  const watchBillingDetailId = watch('billingDetailId');

  const watchCategoryCode = watch('categoryCode');
  const watchResolutionCode = watch('resolutionCode');
  const watchTicketClassCode = watch('ticketClassCode');
  const watchSeverityCode = watch('severityCode');
  const watchTargetServiceCode = watch('targetServiceCode');

  const methodsEntityAction = useForm<IFeatureDescription>( {defaultValues:defaultFeatureDescription} );
    const { register: registrationEntityAction, setValue: setEntityActionValue ,getValues: getEntityActionValues ,
            watch: watchEntityAction, reset: resetEntityAction ,control: controlEntityAction , formState: { errors: errorsEntityAction } } = methodsEntityAction;
    let {fields: actionParams} = useFieldArray<IFeatureDescription,'params'>({
    control: controlEntityAction, 
    name: 'params'});

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ITicket>,Error,ITicket>(
      _id>0?updateTicket:createTicket, {   
        onSuccess: (data: IResult<ITicket>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Ticket')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Ticket',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            setIsSaveLoading(false);
        }
      });

          
    const {data: _data, refetch} = useQuery<ITicket>(['Ticket', _id], () => retrieveEntity('Ticket',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems, refetch: refetchEnumerationItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Ticket'], () => getEnumerationItemsByEnumerationCodes
        ( [ Enum_TICKET_CATEGORY, Enum_TICKET_SEVERITY, Enum_TICKET_ENTITY, Enum_TICKET_EMPLOYEE_ROLE,Enum_TICKET_CLASS,
           Enum_TICKET_STATUS_PURPOSE, Enum_TARGET_SERVICE, Enum_TICKET_RESOLUTION, Enum_OPERATION_STATUS ] ));
       
      // const handleClickOpenUserFilter = (event: any) => {        
      //   setOpenEmployeeFilter(true);
      // }

      // const handleClickRemoveUser = (event: any) => {
        
      //   setValue('assignedUserName', '');     
      // }

      
      const handleClickOpenProblemFilter = (event: any) => {        
        setOpenProblemFilter(true);
      }

      const handleClickRemoveProblem = (event: any) => {
        setValue('problemId', 0);
        setValue('problemTitle', '');     
      }


    const handleAddTicketEmployees = (event: any) => {     
      
      setOpenEmployeeFilter(true);   
    }

    const getResolution = (row: ITicketResolution, cellId: keyof ITicketResolution, 
      opts: {value: string, name: string}[]) => {
        return getAsOptions(refEnumItems.current ?? [],Enum_TICKET_RESOLUTION);
    }
      

    const getTicketEmployeeRole = (row: ITicketEmployee, cellId: keyof ITicketEmployee, 
      opts: {value: string|number, name: string}[]) : {value: string|number, name: string}[]=> {        
      
        return getAsOptions(refEnumItems.current ?? [],Enum_TICKET_EMPLOYEE_ROLE);
    }

   const cellEditableTicketEmployee = (row: ITicketEmployee, cellId: keyof ITicketEmployee) => { 
        return true;
    }
  
    const [headTicketEmployeeCells, setHeadTicketEmployeeCells]  = useState<HeadCell<ITicketEmployee>[]>([]);
    useEffect(() => {
      setHeadTicketEmployeeCells([   

        {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 30 },
        {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 30},

        {id:'ticketEmployeeRoleCode', label : t('Role'),  display: true, type: 'string', width: 30, 
            isEditable: cellEditableTicketEmployee, getOptions: getTicketEmployeeRole}, 
        {id:'workloadPart', label : `${t('Workload')} %`,  display: true, type: 'numeric', width: 10, decimalScale: 2, isEditable: cellEditableTicketEmployee}, 
      ]);
    }, [t,i18n]);
        
  
    const refAppendTicketEmployees = useRef<(value: Partial<FieldArray<ITicket>> | Partial<FieldArray<ITicket>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateTicketEmployee = useRef<(index: number,value: Partial<FieldArray<ITicket>> ) => void>(null);
    const refRemoveTicketEmployee = useRef<(index: number ) => void>(null);
    
    const ticketEmployeeRowActionIcon = ( row: ITicketEmployee) : ActionIconTableRow<ITicket,ITicketEmployee> => {
              
      const res: ActionIconTableRow<ITicket,ITicketEmployee> = {
        toolTip: 'viewDetails',
        icon: RemoveCircleIcon,
        hasAction: (getValues().status === '95'),  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: ITicketEmployee) => {
          
          (refRemoveTicketEmployee.current??emptyFunc)(index);                        
        }
      }
      return res;
  }   

  const [displayResolutionReport, setDisplayResolutionReport] = useState<boolean>(false);
  const [ticketResolutionIndex, setTicketResolutionIndex] = useState<number>(-1);
  const ticketResolutionRowActionIcon = ( row: ITicketResolution) : ActionIconTableRow<ITicket,ITicketResolution> => {
              
    const res: ActionIconTableRow<ITicket,ITicketResolution> = {
      toolTip: 'viewDetails',
      icon: InsightsIcon,
      hasAction: true,  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: ITicketResolution) => {
        setTicketResolutionIndex(index);
        setDisplayResolutionReport(true);                   
      }
    }
    return res;
}

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems]);


  useEffect( () => {              
    setCurrentFormNameAtom(t('Ticket'));  
    setCurrentBasicTextFilterProps(basicFilterTicket);

    reset({...defaultTicket, effectiveDate: new Date(), 
      dueDate: addHours(new Date(), applicationSetup.defaultTicketDurationHours) });  
  }, []);

  

  /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
and the new useEffect will take place ********************/
useEffect( () => {
         
    setCurrentFormNameAtom(_id>0?`${t('Ticket')} - # ${_id} # -`: `${t('Ticket')}` );
    if(_id > 0)
      retrieveData('Ticket',_id, refetch);  
  }, [_id] );


useEffect( () => {
   
    if(_data && _data.id > 0) {
    reset(_data);
}
}, [_data]);

const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
  _setId(0);           
  reset({...defaultTicket, effectiveDate: new Date(), 
                           dueDate: addHours(new Date(), applicationSetup.defaultTicketDurationHours) });    
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
  
  if(!checkEntitySaveAuthorization('Ticket', _id)){
    setIsSaveLoading(false);
    return;
  }
    
  const data = getValues(); 
  if(data.title.trim() === '' || data.description.trim() === '') {
      enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
    }

  

    // if(data.contactId <= 0) {
    //   enqueueSnackbar( t('Contact is not specified'), { variant: 'warning',
    //     anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
    //   setIsSaveLoading(false);
    //   return;
    // }

  mutate(data);
}

const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
  openEntityActionDrawer('Ticket', _id);
}



const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
  queryClient.invalidateQueries(['Ticket',_id]);        
  await retrieveData('Ticket',_id, refetch);        
  reset(_data);       
}

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                              <TextField sx={{width:'calc(75% - 8px)'}} id="title" label={t('Title')} {...register('title')}
                                inputProps={ {  autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              
                              <Controller control={control}
                                name='issueDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Issue date')} 
                                    onChange={onChange} disabled               
                                    value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                  /> )}
                              />
                              
                              
                             { openEmployeeFilter && <FormDialog open={openEmployeeFilter} maxWidth='md'
                                okText={''} cancelText={t('Cancel')} title={t('Employee filter')} onCancel={()=> {setOpenEmployeeFilter(false);}} 
                                onClose={()=> {setOpenEmployeeFilter(false);}} onOk={()=> {}}  >
                                    <BasicTextFilterForm<IEmployee> {...basicFilterEmployee } />
                              </FormDialog> }   
                            
                              { openProblemFilter && <FormDialog open={openProblemFilter} maxWidth='md'
                                  okText={''} cancelText={t('Cancel')} title={t('Problem')} onCancel={()=> {setOpenProblemFilter(false);}} 
                                  onClose={()=> {setOpenProblemFilter(false);}} onOk={()=> {}}  >
                                      <BasicTextFilterForm<IProblem> {...basicFilterProblem } />
                              </FormDialog> }
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} 
                              inputProps={ { readOnly: false, autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              {...register('description')} multiline rows={2} />
                            </Box>
                            <BasicPersonForm personKeyPath='personId' personPath='person' isIdentityOnly={false} />
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller 
                                  name={`categoryCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="categoryCode"
                                      label={t('Category')} inputProps={ {readOnly: false}}
                                      helperText={ 
                                        <FormHelperText>                                    
                                          { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_TICKET_CATEGORY, 
                                                                `${t('Ticket')} - ${t('Category')}`, `${t('Ticket')} - ${t('Category')}`)}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                            {t('Add')}
                                          </Link> }
                                          { (canUpdateEnumerationItem && !isFalsy(watchCategoryCode) ) && <Link href="#" onClick={(event) => {
                                                 onUpdateItemClick( Enum_TICKET_CATEGORY, `${t('Ticket')} - ${t('Category')}`, `${t('Ticket')} - ${t('Category')}`,
                                                      refEnumItems.current?.find(x => x.enumerationCode === Enum_TICKET_CATEGORY && x.code === watchCategoryCode)
                                                      ); }}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                            {t('Update')}
                                          </Link> }
                                        </FormHelperText>
                                      }>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TICKET_CATEGORY ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />  
                              <Controller control={control}
                                name='effectiveDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DateTimePicker label={t('Effective date')} 
                                    onChange={onChange}               
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                  /> )}
                              />
                              <Controller control={control}
                                name='dueDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DateTimePicker label={t('Due date')} 
                                    onChange={onChange}               
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                  /> )}
                              />
                            </Box>
                            <Box sx={{ mt: 4, width: '100%' }} >
                              <Controller 
                                  name={`ticketClassCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="ticketClassCode"
                                      label={t('Class')} inputProps={ {readOnly: false}}
                                      helperText={ 
                                        <FormHelperText>                                    
                                          { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_TICKET_CLASS, 
                                                                `${t('Ticket')} - ${t('Class')}`, `${t('Ticket')} - ${t('Class')}`)}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                            {t('Add')}
                                          </Link> }
                                          { (canUpdateEnumerationItem && !isFalsy(watchTicketClassCode) ) && <Link href="#" onClick={(event) => {
                                                 onUpdateItemClick( Enum_TICKET_CLASS, `${t('Ticket')} - ${t('Class')}`, `${t('Ticket')} - ${t('Class')}`,
                                                        refEnumItems.current?.find(x => x.enumerationCode === Enum_TICKET_CLASS && x.code === watchTicketClassCode)
                                                      ); }}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                            {t('Update')}
                                          </Link> }
                                        </FormHelperText>
                                      }>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TICKET_CLASS ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                              <Controller 
                                  name={`severityCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="severityCode"
                                      label={t('Severity')} inputProps={ {readOnly: false}}
                                      helperText={ 
                                        <FormHelperText>                                    
                                          { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_TICKET_SEVERITY, 
                                                                `${t('Ticket')} - ${t('Severity')}`, `${t('Ticket')} - ${t('Severity')}`)}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                            {t('Add')}
                                          </Link> }
                                          { (canUpdateEnumerationItem && !isFalsy(watchSeverityCode) ) && <Link href="#" onClick={(event) => {
                                                 onUpdateItemClick( Enum_TICKET_SEVERITY, `${t('Ticket')} - ${t('Severity')}`, `${t('Ticket')} - ${t('Severity')}`,
                                                        refEnumItems.current?.find(x => x.enumerationCode === Enum_TICKET_SEVERITY && x.code === watchSeverityCode)
                                                      ); }}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                            {t('Update')}
                                          </Link> }
                                        </FormHelperText>
                                      }>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TICKET_SEVERITY ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                              <Controller 
                                  name={`targetServiceCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="targetServiceCode"
                                      label={t('Target service or department')} inputProps={ {readOnly: false}}
                                      helperText={ 
                                        <FormHelperText>                                    
                                          { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_TARGET_SERVICE, 
                                                                `${t('Service')} / ${t('Department')}`, `${t('Service')} / ${t('Department')}`)}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                            {t('Add')}
                                          </Link> }
                                          { (canUpdateEnumerationItem && !isFalsy(watchTargetServiceCode) ) && <Link href="#" onClick={(event) => {
                                                 onUpdateItemClick( Enum_TARGET_SERVICE, `${t('Service')} / ${t('Department')}`, `${t('Service')} / ${t('Department')}`,
                                                        refEnumItems.current?.find(x => x.enumerationCode === Enum_TARGET_SERVICE && x.code === watchTargetServiceCode)
                                                      ); }}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                            {t('Update')}
                                          </Link> }
                                        </FormHelperText>
                                      }>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TARGET_SERVICE ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />                              
                            </Box>
                            
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller 
                                  name={`resolutionCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="resolutionCode"
                                      label={t('Resolution step')} inputProps={ {readOnly: true}}
                                      helperText={ 
                                        <FormHelperText>                                    
                                          { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_TICKET_RESOLUTION, 
                                                                `${t('Ticket')} - ${t('Resolution step')}`, `${t('Ticket')} - ${t('Resolution step')}`)}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                            {t('Add')}
                                          </Link> }
                                          { (canUpdateEnumerationItem && !isFalsy(watchResolutionCode) ) && <Link href="#" onClick={(event) => {
                                                 onUpdateItemClick( Enum_TICKET_RESOLUTION, `${t('Ticket')} - ${t('Resolution step')}`, `${t('Ticket')} - ${t('Resolution step')}`,
                                                        refEnumItems.current?.find(x => x.enumerationCode === Enum_TICKET_RESOLUTION && x.code === watchResolutionCode)
                                                      ); }}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                            {t('Update')}
                                          </Link> }
                                        </FormHelperText>
                                      }>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TICKET_RESOLUTION ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                              <Controller 
                                  name={`status`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(23% - 8px)'}} id="status"
                                      label={t('Status')} inputProps={ {readOnly: true}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                              <Controller control={control}
                                  name='statusDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Date')} 
                                      onChange={onChange}  readOnly disableOpenPicker                   
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(12% - 8px)'}  }} }
                                    /> )}
                                />
                              <Controller 
                                name={`statusPurpose`}
                                control={control}
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="statusPurpose"
                                    label={t('Status purpose')} inputProps={ {readOnly: true}}>                                
                                    {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_TICKET_STATUS_PURPOSE ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                  </TextField>
                                )}
                              />
                            </Box> 
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(25% - 8px)'}} id="problemTitle" label={t('Associated to problem')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                {...register('problemTitle')} 
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: ( 
                                    <InputAdornment position="end">
                                      { (isFalsy(watchProblemId) ||  watchProblemId<=0) ? 
                                        <IconButton color="primary" onClick={handleClickOpenProblemFilter}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton> :
                                        <IconButton color="primary" onClick={handleClickRemoveProblem}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      }                                                                                         
                                  </InputAdornment>
                                )
                              }} />
                              
                              <TextField sx={{width:'calc(25% - 8px)'}} id="storeName" label={t('Associated to store')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                {...register('storeName')} 
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: ( 
                                    <InputAdornment position="end">
                                      { (isFalsy(watchStoreId) ||  watchStoreId<=0) ? 
                                        <IconButton color="primary" onClick={handleClickOpenProblemFilter}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton> :
                                        <IconButton color="primary" onClick={handleClickRemoveProblem}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      }                                                                                         
                                  </InputAdornment>
                                )
                              }} /> 
                                <TextField sx={{width:'calc(25% - 8px)'}} id="problemTitle" label={t('Associated to billing')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                {...register('billingReference')} 
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: ( 
                                    <InputAdornment position="end">
                                      { (isFalsy(watchBillingId) ||  watchBillingId<=0) ? 
                                        <IconButton color="primary" onClick={handleClickOpenProblemFilter}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton> :
                                        <IconButton color="primary" onClick={handleClickRemoveProblem}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      }                                                                                         
                                  </InputAdornment>
                                )
                              }} />
                              <TextField sx={{width:'calc(25% - 8px)'}} id="billingDetailId" label={t('Associated to billing item')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                {...register('productName')} 
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: ( 
                                    <InputAdornment position="end">
                                      { (isFalsy(watchBillingDetailId) ||  watchBillingDetailId<=0) ? 
                                        <IconButton color="primary" onClick={handleClickOpenProblemFilter}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton> :
                                        <IconButton color="primary" onClick={handleClickRemoveProblem}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      }                                                                                         
                                  </InputAdornment>
                                )
                              }} />                                
                                
                            </Box>                           
                        </Stack>                        
                    </Grid>                   
                    <Grid item xs={12}  md={6} component={Paper} >
                        <Stack flexDirection='column'>      
                            <Box sx={{ mt: 1, width: '100%' }} key={`details infos  `}  >
                                <ArrayFieldTableEx<ITicket,ITicketEmployee,'id'> 
                                  mainObject={getValues()} fieldKey='id' 
                                  headCells={headTicketEmployeeCells} rowsPathName={`ticketEmployees`}
                                  title={t('Assigned to')} rowActionIcon={ticketEmployeeRowActionIcon}  
                                  //onRowSelected={handleBillingDetailSelected}
                                  //onRowDoubleClick={handleBillingDetailRowDoubleClick}
                                                      
                                  refAppend={refAppendTicketEmployees as MutableRefObject<(value: Partial<FieldArray<ITicket>> | Partial<FieldArray<ITicket>>[], options?: FieldArrayMethodProps) => void>}
                                  refUpdate={refUpdateTicketEmployee as MutableRefObject<(index: number,value: Partial<FieldArray<ITicket>>) => void>}
                                  refRemove={refRemoveTicketEmployee as MutableRefObject<(index: number) => void>}

                                  //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                  //displayMore={undefined}
                                  toolbarActions={                                
                                    [
                                      { toolTip: `${t('Add')}...`, onClickIcon: handleAddTicketEmployees ,icon: AddCircleIcon }, 
                                    ]
                                  } 
                                  canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                                />
                              </Box>          
                            <Box sx={{ mt: 4, width: '100%' }} >
                              <EnhancedTable<ITicketComment> 
                                    rows={getValues().ticketComments} 
                                    headCells={[            
                                      
                                      {id:'commentText', label : t('Name'),  display: true, type: 'string', width: 75,  },
                                      {id:'date', label : t('Date'),  display: true, type: 'date', width: 25,  },
                                      
                                    ]} 
                                    title={t('Comments')} objKey={'id'} 
                                    
                                    onRowSelected={undefined} onRowDoubleClick={undefined} 
                                    onRowCheckedSelectChange={undefined} toolbarActions={undefined} order='asc' 
                                    orderBy={'id'}
                                />                        
                            </Box>
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <EnhancedTable<ITicketResolution> 
                                    rows={getValues().ticketResolutions} 
                                    headCells={[            
                                      {id:'title', label : t('Title'),  display: true, type: 'string', width: 60,  },
                                      {id:'originalResolutionCode', label : t('Previous'),  display: true, type: 'string', width: 20, getOptions: getResolution  },
                                      {id:'currentResolutionCode', label : t('Next'),  display: true, type: 'string', width: 20, getOptions: getResolution  },
                                      
                                      
                                    ]} 
                                    title={t('Resolution history')} objKey={'id'} 
                                    rowActionIcon={ticketResolutionRowActionIcon}
                                    onRowSelected={undefined} onRowDoubleClick={undefined} 
                                    onRowCheckedSelectChange={undefined} toolbarActions={undefined} order='asc' 
                                    orderBy={'id'}
                                />
                                { displayResolutionReport && <FormDialog open={displayResolutionReport} maxWidth='md' height='75vh'
                                  okText={''} cancelText='' title={`${t('Report')} ...`} onCancel={()=> {}} 
                                  onClose={()=> {setDisplayResolutionReport(false);}} onOk={()=> {setDisplayResolutionReport(false);}}  >
                                  <Stack flexDirection='column'>      
                                    <Box sx={{ mt: 1, width: '100%' }} > 
                                      <Controller
                                        render={({ field }) => 
                                          <RichWysiwygEditor {...field} 
                                            //mentionSuggestions={getValues().reportFields.map(({alias}) => ({text: alias, value: "${"+alias+"}"}))}
                                            id={`key- resolution report`} />}
                                            name={`ticketResolutions.${ticketResolutionIndex}.resolutionReport`}
                                            control={control}
                                            //defaultValue=""
                                            
                                            rules={{
                                              validate: {
                                                required: (v) =>
                                                  (v && stripHtml(v).result.length > 0) ||
                                                  "Description is required",
                                                maxLength: (v) =>
                                                  (v && stripHtml(v).result.length <= 8000) ||
                                                  "Maximum character limit is 8000",
                                              },
                                            }}
                                        />
                                    </Box>
                                  </Stack>
                              </FormDialog> }
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              {/* <EnhancedTable<ITicketMessage> 
                                    rows={getValues().ticketMessages} 
                                    headCells={[            
                                      
                                     
                                      {id:'contractMessageBody', label : t('Next'),  display: true, type: 'string', width: 100,  },
                                                                            
                                    ]} 
                                    title={t('Messages')} objKey={'id'} 
                                    
                                    onRowSelected={undefined} onRowDoubleClick={undefined} 
                                    onRowCheckedSelectChange={undefined} toolbarActions={undefined} order='asc' 
                                    orderBy={'id'}
                                /> */}
                            </Box>

                            {/* { openActionParams && <FormDialog open={openActionParams} 
                              maxWidth={actionParams.length===0?'sm':'xs'} height={actionParams.length<=3?'45vh':'90vh'}
                            okText='Ok' cancelText='Cancel' title={actionParamsDialogTitle} 
                              onCancel={()=> {setOpenActionParams(false);}} onClose={()=> {setOpenActionParams(false);}} onOk={handleOkActionParams}  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              {actionParams.length===0?
                                t('Confirm this action') :
                                <FormProvider {...methodsEntityAction} >                
                                    <ArrayField<IFeatureParameter> params={actionParams} itemsPerRow={1} paramsName={'params'}
                                        valueKey='value' labelKey='label' dateValueKey='dateValue' disabledKey='isReadonly'   /> 
                                </FormProvider> 
                              }               
                            </Box>
                            </FormDialog> } */}
                            { displayEnumerationItemDialog && <DialogEnumerationItemForm 
                              {...{open: displayEnumerationItemDialog, 
                                    title: '', 
                                    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
                                    afterSave: async () => {await refetchEnumerationItems()}}} 
                              />
                          }    
                        </Stack> 
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
  )
}
