import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useMatrixService, { useBasicFilterMatrix } from './services/Matrix';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Chip, IconButton, InputAdornment, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import { IMatrix, defaultMatrix } from './models/Matrix';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration from 'features/configuration/models/Enumeration';

import { IExtensionType  } from './models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from './services/ExtensionType';

import { MdOutlineAdd } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import { justifyCenter } from 'themes/commonStyles';
import { isFalsy } from 'utility-types';
import EntityExpression from 'components/ui/EntityExpression';
import ExtensionValueField from 'components/ui/ExtensionValueField';

export const MatrixForm: FC<IMatrix> = (props: IMatrix = defaultMatrix) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const { getEnumerationItemsByEnumeration, getAsOptions } = useEnumerationService();

  const { createMatrix, updateMatrix , getMatrixVectorText, isInputVectorEqual} = useMatrixService();

  const {defaultValue, valueType} = useExtensionTypeService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterMatrix = useBasicFilterMatrix( 
    (event: React.MouseEvent<unknown>, row: IMatrix) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  
  const [openExtensionTypeFilter, setOpenExtensionTypeFilter] = useState(false);
  const basicFilterExtensionType = useBasicFilterExtensionType( 
      (event: React.MouseEvent<unknown>, row: IExtensionType) => {
          const {id, name, description, type, baseType} = row;

          if(openMatrixInput) {
            setValue('currentInputExtensionTypeId', id);
            setValue('currentInputExtensionTypeName', name);  
            setValue('currentInputType', type);   
            setValue('currentInputBaseType', baseType);    
          } else if(openMatrixOutput) {
            setValue('currentOutputExtensionTypeId', id);
            setValue('currentOutputExtensionTypeName', name);     
            setValue('currentOutputType', type);    
            setValue('currentOutputBaseType', baseType);          
          }
          
                           
          setOpenExtensionTypeFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const methods = useForm<IMatrix>({defaultValues:defaultMatrix});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  let { fields : matrixInputs, append: appendMatrixInputs, update: updateMatrixInput ,remove: removeMatrixInput,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
    name: `matrixInputs`,
    control,            
  });

  let { fields : matrixOuputs, append: appendMatrixOutputs, update: updateMatrixOutput ,remove: removeMatrixOutput,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
    name: `matrixOutputs`,
    control,            
  });

  let { fields : matrixVectors, append: appendMatrixVectors, update: updateMatrixVector ,remove: removeMatrixVector,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
    name: `matrixVectors`,
    control,            
  });

  const watchUsage = watch('usage');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IMatrix>,Error,IMatrix>(
      _id>0?updateMatrix:createMatrix, {   
        onSuccess: (data: IResult<IMatrix>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Matrix',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IMatrix>(['Matrix', _id], () => retrieveEntity('Matrix',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const [matrixInputIndex, setMatrixInputIndex] = useState<number>(-1);
      const [openMatrixInput, setOpenMatrixInput] = useState(false);
      const handleClickAddMatrixInput = (event: any) => {
        setMatrixInputIndex(-1);

        
        setValue('currentInputAlias','');
        setValue('currentInputValueExpression','');
        setValue('currentInputExtensionTypeId',0);
        setValue('currentInputExtensionTypeName','');
        setValue('currentInputDescription','');
        setValue('currentInputBaseType','string');
        setValue('currentInputType', 'text');

        setOpenMatrixInput(true);
      }

      const handleClickEditMatrixInput = (index: number) => {
        const matrixInput = getValues().matrixInputs.at(index);
        if(isFalsy(matrixInput)) return;

        setMatrixInputIndex(index);
        const {alias, description, extensionTypeId, extensionTypeName, valueExpression, baseType} = matrixInput!;

        setValue('currentInputAlias',alias);        
        setValue('currentInputExtensionTypeId',extensionTypeId);
        setValue('currentInputExtensionTypeName',extensionTypeName);
        setValue('currentInputDescription',description);
        setValue('currentInputValueExpression',valueExpression);
        setValue('currentInputBaseType',baseType);
        
        setOpenMatrixInput(true);
      }

      const handleOkMatrixInput =  () => {
        const { currentInputAlias, currentInputValueExpression, currentInputType, currentInputBaseType,
            currentInputDescription,
            currentInputExtensionTypeId, currentInputExtensionTypeName } = getValues();
        
        if(isFalsy(currentInputAlias) || isFalsy(currentInputExtensionTypeId) || isFalsy(currentInputValueExpression)) return;

        if(matrixInputIndex<0 && getValues().matrixInputs.some( x => x.alias.trim() === currentInputAlias.trim())) return;

        if(matrixInputIndex>=0 && getValues().matrixInputs.findIndex( o => o.alias === currentInputAlias) !== matrixInputIndex)
          return;

        if(matrixInputIndex<0)
          appendMatrixInputs( {
            id: 0, matrixId: _id,          
            extensionTypeId: currentInputExtensionTypeId, extensionTypeName: currentInputExtensionTypeName,          
            alias: currentInputAlias, valueExpression: currentInputValueExpression, 
            type: currentInputType, baseType: currentInputBaseType,
            description: currentInputDescription,
          });
        else {
          const matrixInput = getValues().matrixInputs.at(matrixInputIndex)!;
          updateMatrixInput(matrixInputIndex,{...matrixInput,
            extensionTypeId: currentInputExtensionTypeId, extensionTypeName: currentInputExtensionTypeName,
            type: currentInputType, baseType: currentInputBaseType,
            alias: currentInputAlias, description: currentInputDescription });
        }
        setOpenMatrixInput(false);
      }
      
      const [matrixOutputIndex, setMatrixOutputIndex] = useState<number>(-1);
      const [openMatrixOutput, setOpenMatrixOutput] = useState(false);
      const handleClickAddMatrixOutput = (event: any) => {
        setMatrixOutputIndex(-1);

        setValue('currentOutputAlias','');        
        setValue('currentOutputExtensionTypeId',0);
        setValue('currentOutputExtensionTypeName','');
        setValue('currentOutputDescription','');
        
        setOpenMatrixOutput(true);
      }

      const handleClickEditMatrixOutput = (index: number) => {
        const matrixOutput = getValues().matrixOutputs.at(index);
        if(isFalsy(matrixOutput)) return;

        setMatrixOutputIndex(index);
        const {alias, description, extensionTypeId, extensionTypeName} = matrixOutput!;

        setValue('currentOutputAlias',alias);        
        setValue('currentOutputExtensionTypeId',extensionTypeId);
        setValue('currentOutputExtensionTypeName',extensionTypeName);
        setValue('currentOutputDescription',description);
        
        setOpenMatrixOutput(true);
      }


      const handleOkMatrixOutput =  () => {        
        const { currentOutputAlias, currentOutputDescription,
          currentOutputExtensionTypeId, currentOutputExtensionTypeName,
          currentOutputType, currentOutputBaseType,  } = getValues();
      
        if(isFalsy(currentOutputAlias) || isFalsy(currentOutputExtensionTypeId)) return;

        if(matrixOutputIndex<0 && getValues().matrixOutputs.some( x => x.alias.trim() === currentOutputAlias.trim())) 
          return;
      
        if(matrixOutputIndex>=0 && getValues().matrixOutputs.findIndex( o => o.alias === currentOutputAlias) !== matrixOutputIndex)
          return;

        if(matrixOutputIndex<0)
          appendMatrixOutputs( {
            id: 0, matrixId: _id,
            extensionTypeId: currentOutputExtensionTypeId, extensionTypeName: currentOutputExtensionTypeName,
            alias: currentOutputAlias, description: currentOutputDescription, 
            type: currentOutputType, baseType: currentOutputBaseType, });
        else {
          const matrixOutput = getValues().matrixOutputs.at(matrixOutputIndex)!;
          updateMatrixOutput(matrixOutputIndex,{...matrixOutput,
            extensionTypeId: currentOutputExtensionTypeId, extensionTypeName: currentOutputExtensionTypeName,
            alias: currentOutputAlias, description: currentOutputDescription,
            type: currentOutputType, baseType: currentOutputBaseType, });
        }
        setOpenMatrixOutput(false);
      }

    const handleClickSearchExtensionType = (event: any) => {
      setOpenExtensionTypeFilter(true);
    }

    const [openEntityExpression, setOpenEntityExpression] = useState(false);
    const [currentExpression, setCurrentExpression] = useState(''); 
    const [currentEntityName, setCurrentEntityName] = useState(''); 
    const [currentReturnType, setCurrentReturnType] = useState(''); 

    const handleClickOpenExpression = (event: any) => {
      const { usage, currentInputValueExpression } = getValues();

      setCurrentExpression(currentInputValueExpression);
      setCurrentEntityName( ['coverage', 'coverage-tax"' ].includes(usage) ? 'PolicyRiskCoverage' :
                            [ 'accessories', 'accessories-tax' ].includes(usage)? 'Policy' : '' );      

      setOpenEntityExpression(true);
    }

    const handleClickOkExpression = async () => {

      const checkExpression = await checkEntityExpressionSyntax(currentEntityName, currentExpression);
        if(!checkExpression){
          enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        const {syntaxOk, syntaxError, returnType} = checkExpression;
        if(!syntaxOk) {
          enqueueSnackbar( syntaxError , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        if(returnType !== getValues().currentInputBaseType) {
          enqueueSnackbar( `${t('The result of expression must be')} ${currentReturnType}` , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
                return;
        }

        setValue('currentInputValueExpression', currentExpression);
        setOpenEntityExpression(false);
    }

    const [matrixVectorIndex, setMatrixVectorIndex] = useState<number>(-1);
    const [openMatrixVector, setOpenMatrixVector] = useState(false);

    const handleClickAddVector = (event: any) => {

      console.log(getValues());

      const {matrixInputs, matrixOutputs} = getValues();
      
      setValue('currentMatrixVectorInputs', matrixInputs.map( 
          (matInput) => ({id: 0, matrixVectorId: 0, matrixInputId: matInput.id, 
              matrixInputAlias: matInput.alias, matrixInputOptions: getAsOptions(matInput.extensionEnumerationItems, ''),
              minExtensionValueId: 0, maxExtensionValueId: 0,
              type: matInput.type, baseType: matInput.baseType , minValue: defaultValue(matInput.type), maxValue: defaultValue(matInput.type)}) ));

      setValue('currentMatrixVectorOutputs', matrixOutputs.map(
          (matOutput) => ({id: 0, matrixVectorId:0, matrixOutputId: matOutput.id,
            matrixOutputAlias: matOutput.alias, matrixOutputOptions: getAsOptions(matOutput.extensionEnumerationItems, ''),
            extensionValueId: 0, type: matOutput.type, value: defaultValue(matOutput.type) }) ));

      setMatrixVectorIndex(-1);
      setOpenMatrixVector(true);      
    }

    const handleClickEditMatrixVector = (index: number) => {
      const matrixVector = getValues().matrixVectors.at(index);
      if(isFalsy(matrixVector)) return;

      setMatrixVectorIndex(index);
      const { matrixVectorInputs, matrixVectorOutputs } = matrixVector!;

      setValue('currentMatrixVectorInputs', [...matrixVectorInputs] );
      setValue('currentMatrixVectorOutputs', [...matrixVectorOutputs] );

      setOpenMatrixVector(true); 
    }

    const handleOkMatrixVector = () => {
      //console.log(getValues());
      const { currentMatrixVectorInputs, currentMatrixVectorOutputs, matrixVectors } = getValues();

      if(matrixVectorIndex<0) {
          const idx = matrixVectors.findIndex( v => isInputVectorEqual(v.matrixVectorInputs,currentMatrixVectorInputs) );
          if(idx < 0)
            appendMatrixVectors( { id: 0, matrixId: _id,
              matrixVectorInputs: [...currentMatrixVectorInputs], matrixVectorOutputs: [...currentMatrixVectorOutputs] });
          else {

          }
        } else {
          const matrixVector = getValues().matrixVectors.at(matrixVectorIndex)!;
          updateMatrixVector(matrixVectorIndex,{...matrixVector,
            matrixVectorInputs: [...currentMatrixVectorInputs], matrixVectorOutputs: [...currentMatrixVectorOutputs] });
        }

      setOpenMatrixVector(false);
    }

    function openFileDialog() {
      (document as any).getElementById("file-upload").click();
    }
  
    const setFile = (_event: any) => {
      let f = _event.target.files![0];
      var reader = new FileReader();
  
      reader.onload = function () {
  
        const file = reader.result as string;
        const lines = file.split(/\r?\n/);

        const {matrixInputs, matrixOutputs} = getValues();

        const inAlias = matrixInputs.map(x => x.alias);
        const outAlias = matrixOuputs.map(x => x.alias);



        for(var idx=0; idx<lines.length; idx++){
          const line = lines[idx];
          const inOuts = line.split(':');

          if(inOuts.some( x => x.split('@').length != 3 ))
            continue;
                   
          var ins = inOuts.filter(x => x.startsWith('in@'));
          var outs = inOuts.filter(x => x.startsWith('out@'));

          if(ins.length !== matrixInputs.length || outs.length !== matrixOutputs.length)
            continue;

          if(ins.some(x => !inAlias.includes(x.split('@')[1]) ))
            continue;

          if(outs.some(x => !outAlias.includes(x.split('@')[1]) ))
            continue;

          const matrixVectorInputs = matrixInputs.map( (matInput) => {
            const {id, alias, type, baseType, } = matInput;

            const _in = ins.find( x => x.startsWith(`in@${alias}@`))!;
            const arr = _in.split('@');
            
            const minMax = arr[2].split('*');
            const min = minMax[0];
            const max = minMax.length > 1 ? minMax[1] : '';
            
            return {id:0, matrixVectorId: 0, matrixInputId: id, matrixInputAlias: alias, 
               matrixInputOptions: [], minExtensionValueId:0, maxExtensionValueId: 0, type, baseType, 
               minValue: valueType(min, type), maxValue: valueType(max, type)  };
          });

          const matrixVectorOutputs = matrixOutputs.map( (matOutput) => {
            const {id, alias, type, baseType, } = matOutput;

            const _out = outs.find( x => x.startsWith(`out@${alias}@`))!;
            const arr = _out.split('@');
            
            const val = arr[2];
                        
            return {id:0, matrixVectorId: 0, matrixOutputId: id, matrixOutputAlias: alias, 
               matrixOutputOptions: [], extensionValueId:0, type, baseType, 
               value: valueType(val, type)  };
          });
          
          appendMatrixVectors( { id: 0, matrixId: _id, matrixVectorInputs, matrixVectorOutputs });          
        }
              
        };
        
        reader.onerror = function () {
          console.log("File load failed");
        };
    
        reader.readAsText(f);
      };

    const handleOkBasicTextFilterEnumerationForm = () => {
      setOpenEnumerationFilter(false);
    }

      useEffect( () => {        
        setCurrentFormNameAtom(t('Matrix'));
        setCurrentBasicTextFilterProps(basicFilterMatrix);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Matrix',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            console.log(_data);
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultMatrix);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {    
        if(!checkEntitySaveAuthorization('Matrix', _id))
        return;

          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Matrix', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={1} columnSpacing={0.5}>
                    <Grid item xs={12} md={4}  component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                
                                <TextField sx={{width:'calc(90% - 8px)'}} id="name" label={t('Name')} {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')} />                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='usage' control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="type"
                                          label={t('Type')} inputProps={ {readOnly: false}} >                                        
                                            <MenuItem value="coverage">{t('Coverage')}</MenuItem>      
                                            <MenuItem value="accessories">{t('Accessories')}</MenuItem>       
                                            <MenuItem value="coverage-tax">{`${t('Coverage')} - ${t('Tax')}`}</MenuItem>      
                                            <MenuItem value="accessories-tax">{`${t('Accessories')} - ${t('Tax')}`}</MenuItem>                                  
                                        </TextField>
                                      )}
                                  />                               
                            </Box>                            
                        </Stack>                        
                    </Grid>                    
                    <Grid item xs={12} md={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'  >
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Button>
                            {`${t('Add matrix input')}`}
                            <Box sx={{ ...justifyCenter, ml: 1 }}>
                              <MdOutlineAdd size={24} onClick={handleClickAddMatrixInput} />
                            </Box>
                          </Button>
                          { openMatrixInput && <FormDialog open={openMatrixInput} maxWidth='sm'
                                okText={t('OK')} cancelText='' title={t('Matrix input')} onCancel={()=> {}} 
                                onClose={()=> {setOpenMatrixInput(false);}} onOk={handleOkMatrixInput}  >
                                  <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }}>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="currentInputAlias" label={t('Alias')} 
                                          {...register('currentInputAlias')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="currentInputDescription" label={t('Description')} 
                                        {...register('currentInputDescription')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="currentInputExtensionTypeName" label={t('Extension type')} 
                                          {...register('currentInputExtensionTypeName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment position="end">                                            
                                                <IconButton color="primary" onClick={handleClickSearchExtensionType}>
                                                  <ArrowDropDownCircleIcon />
                                                </IconButton>                                                                                               
                                            </InputAdornment>
                                          )
                                        }} />                                                                           
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="currentInputValueExpression" label={`${t('Expression')} : ${t('Value')}`} multiline rows={3}
                                          {...register('currentInputValueExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment position="end">                                            
                                                <IconButton color="primary" onClick={handleClickOpenExpression}>
                                                  <DeveloperModeIcon />
                                                </IconButton>                                                                                               
                                            </InputAdornment>
                                          )
                                        }} />                                                                           
                                    </Box>
                                  </Stack>
                            </FormDialog> } 
                            { openExtensionTypeFilter && <FormDialog open={openExtensionTypeFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Extension type')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenExtensionTypeFilter(false);}} onOk={()=> {setOpenExtensionTypeFilter(false);}}  >
                                      <BasicTextFilterForm<IExtensionType> {...basicFilterExtensionType } />
                              </FormDialog> }
                            { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                  okText={t('OK')} cancelText='' title={`${t('Expression')} ...`} onCancel={()=> {}} 
                                  onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                  <EntityExpression entityName={currentEntityName} properties={[]} 
                                    expression={currentExpression} setExpression={setCurrentExpression} />
                                </FormDialog>  }
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                            {                              
                              getValues().matrixInputs.map( ( {alias, description} ,idx) => 
                                ( <Chip sx={{ ml: 1, mr:1, mb: 1 }}
                                    key={`${idx} - ${alias}`} label={`${alias} : ${description} `} color="primary" variant="outlined"
                                    onDelete={() => {removeMatrixInput(idx)}} 
                                    onClick={() =>handleClickEditMatrixInput(idx)}/>
                                    ) )
                            }
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'  >
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Button>
                            {`${t('Add matrix output')}`}
                            <Box sx={{ ...justifyCenter, ml: 1 }}>
                              <MdOutlineAdd size={24} onClick={handleClickAddMatrixOutput} />
                            </Box>
                          </Button>
                          { openMatrixOutput && <FormDialog open={openMatrixOutput} maxWidth='sm'
                                okText={t('OK')} cancelText='' title={t('Matrix output')} onCancel={()=> {}} 
                                onClose={()=> {setOpenMatrixOutput(false);}} onOk={handleOkMatrixOutput}  >
                                  <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }}>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="currentOutputAlias" label={t('Alias')} 
                                          {...register('currentOutputAlias')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="currentOutputDescription" label={t('Description')} 
                                        {...register('currentOutputDescription')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="currentOutputExtensionTypeName" label={t('Extension type')} 
                                          {...register('currentOutputExtensionTypeName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment position="end">                                            
                                                <IconButton color="primary" onClick={handleClickSearchExtensionType}>
                                                  <ArrowDropDownCircleIcon />
                                                </IconButton>                                                                                               
                                            </InputAdornment>
                                          )
                                        }} />                                                                           
                                    </Box>                                    
                                  </Stack>
                            </FormDialog> } 
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          {                              
                              getValues().matrixOutputs.map( ( {alias, description} ,idx) => 
                                ( <Chip sx={{ ml: 1, mr:1, mb: 1 }}
                                    key={`${idx} - ${alias}`} label={`${alias} : ${description} `} color="primary" variant="outlined"
                                    onDelete={() => {removeMatrixOutput(idx)}} 
                                    onClick={() => handleClickEditMatrixOutput(idx)}/>
                                    ))
                            }
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }}>
                      <Stack flexDirection='column'  >
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Button>
                            {`${t('Add vector')}`}
                            <Box sx={{ ...justifyCenter, ml: 1 }}>
                              <MdOutlineAdd size={24} onClick={handleClickAddVector} />
                            </Box>
                          </Button>
                          <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}/>
                          <Button>
                            {`${t('Load text file')}`}
                            <Box sx={{ ...justifyCenter, ml: 1 }}>
                              <FiFileText size={24} onClick={openFileDialog} />
                            </Box>
                          </Button>
                          { openMatrixVector && <FormDialog open={openMatrixVector} maxWidth='sm'
                                okText={t('OK')} cancelText='' title={t('Values of vector in matrix')} onCancel={()=> {}} 
                                onClose={()=> {setOpenMatrixVector(false);}} onOk={handleOkMatrixVector}  >
                                  <Grid container rowSpacing={1} columnSpacing={0.5}>
                                    <Grid item xs={8} component={Paper} sx={{ borderRadius: 2, ml: 0, }}>
                                      <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }}>
                                        { getValues().currentMatrixVectorInputs.map( (matrixVectorInput, index) => {
                                          const matInput = getValues().matrixInputs.find( m => m.alias === matrixVectorInput.matrixInputAlias )!;
                                          return (
                                            <Box sx={{ mt: 1, width: '100%' }} key={`key-${matrixVectorInput.id} - ${index}`} >
                                              <ExtensionValueField 
                                                  name={`currentMatrixVectorInputs.${index}.minValue`}
                                                  label={`${matrixVectorInput.baseType === 'numeric'?'Min - ':''}${matrixVectorInput.matrixInputAlias}`}
                                                  type={matrixVectorInput.type}
                                                  options={getAsOptions(matInput.extensionEnumerationItems, '')}
                                                  itemsPerRow={matrixVectorInput.baseType === 'numeric'?2:1}
                                              />    
                                              {matrixVectorInput.baseType === 'numeric' && <ExtensionValueField 
                                                  name={`currentMatrixVectorInputs.${index}.maxValue`}
                                                  label={`Max - ${matrixVectorInput.matrixInputAlias}`}
                                                  type={matrixVectorInput.type}
                                                  options={getAsOptions(matInput.extensionEnumerationItems, '')}
                                                  itemsPerRow={2}
                                              />}                                                           
                                            </Box>)
                                          })
                                        }                                                                                                               
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }}>
                                      <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }}>
                                        { getValues().currentMatrixVectorOutputs.map( (matrixVectorOutput, index) => {
                                          const matOutput = getValues().matrixOutputs.find( m => m.alias === matrixVectorOutput.matrixOutputAlias )!;
                                          return (
                                            <Box sx={{ mt: 1, width: '100%' }} key={`key-${matrixVectorOutput.id} - ${index}`} >
                                              <ExtensionValueField 
                                                  name={`currentMatrixVectorOutputs.${index}.value`}
                                                  label={matrixVectorOutput.matrixOutputAlias}
                                                  type={matrixVectorOutput.type}
                                                  options={getAsOptions(matOutput.extensionEnumerationItems, '')}
                                                  itemsPerRow={1}
                                              />                                                              
                                            </Box>)
                                          })
                                        }                                                                                                              
                                      </Stack>
                                    </Grid>
                                  </Grid>
                            </FormDialog> } 
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          {                              
                              getValues().matrixVectors.map( ( matrixVector ,idx) => 
                                ( <Chip sx={{ ml: 1, mr:1, mb: 1 }}
                                    key={`${idx} - ${'lll'}`} label={`${getMatrixVectorText(matrixVector)}`} color="primary" variant="outlined"
                                    onDelete={() => {removeMatrixVector(idx)}} 
                                    onClick={() => handleClickEditMatrixVector(idx)}/>
                                    ))
                            }
                        </Box>
                      </Stack>
                    </Grid>
                </Grid>
            </Box>
        </FormProvider> 
  )
}



