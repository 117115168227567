
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, 
      isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { IconButton, InputAdornment, MenuItem, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MoreIcon from '@mui/icons-material/More';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useTellerOperationService, { useBasicFilterTellerOperation } from './services/TellerOperation';
import {ITellerOperation , defaultTellerOperation, ITellerOperationBilling, ITellerOperationMode} from './models/TellerOperation';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import ExtensionValueField from 'components/ui/ExtensionValueField';

import useBillingService, {useBasicFilterBilling} from './services/Billing';

//import useRegistrationService, { useBasicFilterRegistration } from 'features/production/services/Registration';
import { IRegistration } from 'features/production/models/Registration';
import { isFalsy } from 'utility-types';
import { DatePicker } from '@mui/x-date-pickers';
import { debounce, sum } from 'lodash';
import { IBilling } from './models/Billing';

export const TellerOperationForm: FC<ITellerOperation> = (props: ITellerOperation = defaultTellerOperation) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  const {language: lg, userPaymentSources} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer, checkEntitySaveAuthorization } = entityService();

  const { createTellerOperation, updateTellerOperation } = useTellerOperationService();

  const { getBillingsByRegistration } = useBillingService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterTellerOperation = useBasicFilterTellerOperation( 
    (event: React.MouseEvent<unknown>, row: ITellerOperation) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openBillingFilter, setOpenBillingFilter] = useState(false);
  const basicFilterBilling = useBasicFilterBilling( 
      async (event: React.MouseEvent<unknown>, row: IBilling) => {
          const {id, customerFirstName, customerLastName} = row;

          // setValue('firstName', firstName);
          // setValue('lastName', lastName);
          // setValue('schoolYearClassName', schoolYearClassName);
          // setValue('schoolYearName', schoolYearName);

          // const arrBillings = await getBillingsByRegistration(id);

          // reset({...getValues(), registrationId: id, 
          //   //firstName, lastName, schoolYearName, schoolYearClassName,
          //   tellerOperationBillings: [] }) // arrBillings.filter(b => !b.isCancelled).map( (bil) => ({...bil, billingId: bil.id, amount: 0 }))  })
                                              
          // setOpenBillingFilter(false);
      }
  );

  const emptyFunc = (obj: any) => {}
    
  const methods = useForm<ITellerOperation>({defaultValues:props}); // here props is default or defaultEx
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  
  //const watchType = watch('type');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ITellerOperation>,Error,ITellerOperation>(
      _id>0?updateTellerOperation:createTellerOperation, {   
        onSuccess: (data: IResult<ITellerOperation>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['TellerOperation',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<ITellerOperation>(['TellerOperation', _id], () => retrieveEntity('TellerOperation',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      
      const tellerOperationModeRowActionIcon = ( tellerOperationMode: ITellerOperationMode) : ActionIconTableRow<ITellerOperation,ITellerOperationMode> => {  
        const res: ActionIconTableRow<ITellerOperation,ITellerOperationMode> = {
          toolTip: 'remove',
          icon: MoreIcon,
          hasAction: (index: number,row: ITellerOperationMode) => true, 
          isActionExecuting: true,
          onRowClickIcon: (event : any, index: number, row: ITellerOperationMode) => {           
            
            //(refRemoveTellerOperationEx.current??emptyFunc)(index);            
          }
        }
        return res;
      }

      const cellEditableMode = (row: ITellerOperationMode, cellId: keyof ITellerOperationMode) => {
        return true;
     }

     const cellAllowedModeAmount = (row: ITellerOperationMode, valueAmount: number) => {
     
      debouncedAmountMode();
      return true;
    }

    const [totalAmountMode, setTotalAmountMode] = useState<number>(0);
    const debouncedAmountMode = useRef(
      debounce( () => {
        const sumAmount = sum( getValues().tellerOperationModes.map( ({amount}) => amount ) );
        setTotalAmountMode(sumAmount);
      }, 300)
    ).current;
     
     const getOperationModeOptions = (row: ITellerOperationMode, cellId: keyof ITellerOperationMode,
       opts: {value: string|number,name: string,}[]) : {value: string|number, name: string}[] => {
      return [{value: 'E', name: t('Cash')},{ value: 'C', name: t('Check')},
              {value: 'M', name: t('Mobile')},{value: 'T', name: t('Cart')},
              {value: 'B', name: t('Bank transfert')},{value: 'P', name: `${t('Compensation')} - ${t('Payment')} `},{value: 'O', name: t('Other')}];
    }

    const [headTellerOperationModeCells, setHeadTellerOperationModeCells]  = useState<HeadCell<ITellerOperationMode>[]>([]);
    useEffect(() => {
      setHeadTellerOperationModeCells([            
        {id:'type', label : t('Mode'),  display: true, type: 'string', width: 50 , isEditable: cellEditableMode,
        getOptions: getOperationModeOptions},
        {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 50 , isEditable: cellEditableMode,
           isAllowed: cellAllowedModeAmount },
        
      ]  )
    }, [t,i18n])

    const refAppendTellerOperationModes = useRef<(value: Partial<FieldArray<ITellerOperation>> | Partial<FieldArray<ITellerOperation>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateTellerOperationMode = useRef<(index: number,value: Partial<FieldArray<ITellerOperation>> ) => void>(null);
    const refRemoveTellerOperationMode = useRef<(index: number ) => void>(null);

    const handleAddTellerOperationModes = (event: any) => {
      (refAppendTellerOperationModes.current??emptyFunc)( {

      } );
    }

    const [openOperationModeDetails, setOpenOperationModeDetails] = useState<boolean>(false);

    const tellerOperationBillingRowActionIcon = ( tellerOperationBilling: ITellerOperationBilling) : ActionIconTableRow<ITellerOperation,ITellerOperationBilling> => {  
      const res: ActionIconTableRow<ITellerOperation,ITellerOperationBilling> = {
        toolTip: 'remove',
        icon: MoreIcon,
        hasAction: (index: number,row: ITellerOperationBilling) => true, 
        isActionExecuting: true,
        onRowClickIcon: (event : any, index: number, row: ITellerOperationBilling) => {           
          
          //(refRemoveTellerOperationItem.current??emptyFunc)(index);            
        }
      }
      return res;
    }

    const cellEditableBilling = (row: ITellerOperationBilling, cellId: keyof ITellerOperationBilling) => {
      return true;
    }

    const cellAllowedAmount = (row: ITellerOperationBilling, valueAmount: number) => {
      const {billingId, billingUnpaidAmount} = row;

      // const parentBilling = getValues().tellerOperationBillings.find(x => 
      //     x.billingTypeId === parentBillingTypeId && x.unpaidAmount-x.amount> 0);

      // if(!isFalsy(parentBilling)) {
      //   enqueueSnackbar( `${t('You have to pay the dependency billing first !!!')} : ${parentBilling.billingTypeName}` ,
      //      { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
      //   return false;
      // }

      const allowed = valueAmount>=0 && valueAmount<=billingUnpaidAmount;
      debouncedAmount();

      return allowed;
    }

    const debouncedAmount = useRef(
      debounce( () => {
        const sumAmount = sum( getValues().tellerOperationBillings.map( ({amount}) => amount ) );
        setValue('amount', sumAmount);
      }, 300)
    ).current;

    const [headTellerOperationBillingCells, setHeadTellerOperationBillingCells]  = useState<HeadCell<ITellerOperationBilling>[]>([]);
    useEffect(() => {
      setHeadTellerOperationBillingCells([            
        {id:'billingId', label : t('Id'),  display: true, type: 'string', width: 5 },
        {id:'billingReference', label : t('Reference'),  display: true, type: 'string', width: 35 },
        {id:'billingIssueDate', label : t('Date'),  display: true, type: 'date', width: 12 },
        {id:'billingAmount', label : t('Total amount'),  display: true, type: 'numeric', width: 12 ,},
        {id:'billingAmountPaid', label : t('Amount paid'),  display: true, width: 12 ,type: 'numeric', },
        
        {id:'billingUnpaidAmount', label : t('Unpaid amnt'),  display: true, width: 12 ,type: 'numeric', },
        {id:'amount', label : t('Amount'),  display: true, width: 12 ,type: 'numeric', 
          isEditable: cellEditableBilling, isAllowed: cellAllowedAmount},

      ]  )
    }, [t,i18n])
    const refAppendTellerOperationBillings = useRef<(value: Partial<FieldArray<ITellerOperation>> | Partial<FieldArray<ITellerOperation>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateTellerOperationBilling = useRef<(index: number,value: Partial<FieldArray<ITellerOperation>> ) => void>(null);
    const refRemoveTellerOperationBilling = useRef<(index: number ) => void>(null);

    const handleAddTellerOperationBilling = (event: any) => {

    }
    // useEffect(() => {
    
    //   const subscription = watch( (value, { name, type }) => {
        
    //     if(name?.endsWith('amount') ) {
            
    //       const sp = name.split('.');
    //       const billingIndex = Number(sp[1]);

    //       const tellerOperationBilling = getValues().tellerOperationBillings.at(billingIndex);
    //       if(isFalsy(tellerOperationBilling)) return;
  
    //       const {amount, unpaidAmount} = tellerOperationBilling;

    //       const amount2Use = Math.max(0, Math.min(amount, unpaidAmount));

    //       (refUpdateTellerOperationBilling.current??emptyFunc)(billingIndex, 
    //         {...tellerOperationBilling!, amount: amount2Use }); 
                
    //     }
    //   });  
  
    //   return () => subscription.unsubscribe();
    // }, [watch]);


    const handleClickSearchRegistration = (event: any) => {
      setOpenBillingFilter(true);
    }

    
    // const handleTellerOperationItemSelected = (event: React.MouseEvent<unknown>,index: number,row: ITellerOperationItem) => {      
    //   enumerationItemSelected(index);
    // }

    // const enumerationItemSelected = (index: number) => {
    //   setTellerOperationItemIndex(index);

    //   const enumerationExs = getValues().enumerationExs;
    //   const enumerationItem = getValues().enumerationItems[index];

    //   setValue("currentParentTellerOperationItemCode", enumerationItem.parentTellerOperationItemCode);
      
    //   (refUpdateTellerOperationItem.current ??emptyFunc)(index, {
    //     ...enumerationItem, 
    //     enumerationItemExs: enumerationExs.map( ({id, name, extensionTypeType, extensionTellerOperationItems}, idx) => {

    //       const enumItemEx = enumerationItem.enumerationItemExs.find(x => x.enumerationExId === id);

    //       return {
    //         id: enumItemEx?.id ??0 , 
    //         enumerationItemId: enumItemEx?.enumerationItemId ?? 0, 
    //         enumerationExId: id,
    //         enumerationExName: name, enumerationExType: extensionTypeType,
    //         enumerationExOptions: getAsOptions(extensionTellerOperationItems, ''), 
    //         extensionValueId: enumItemEx?.extensionValueId ?? 0,
    //         value: enumItemEx?.value ?? ''
    //       }
    //     })
    //   } );
      
    // }

          

//       const handleClickSearchEntity = (event: any) => {
//         setOpenEntityFilter(true);
//         }

//         const handleOkBasicTextFilterEntityForm = () => {
//           setOpenEntityFilter(false);
//       }

//     const handleClickSearchTellerOperation = (event: any) => {
//       setOpenTellerOperationFilter(true);
//         }

// const handleOkBasicTextFilterTellerOperationForm = () => {
//   setOpenTellerOperationFilter(false);
//       }

      useEffect( () => {        
        setCurrentFormNameAtom(t('Teller operation'));
        setCurrentBasicTextFilterProps(basicFilterTellerOperation);
      }, []);    

    
      

     /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('TellerOperation',_id, refetch);  
          }, [_id] );
    
    
      useEffect( () => {
            
        if(_data && _data.id > 0) {
          setCurrentFormNameAtom(`${t('Teller operation')} - # ${_data.id} # ${_data.isCancelled? `(${t('Cancelled')})`: '' }` );
            reset(_data);
                                         
          }
          //setTellerOperationItemIndex( ( (_data?.enumerationItems || []).length > 0)?0 : -1 );
        }, [_data, reset]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {   
        _setId(0);      
        setCurrentFormNameAtom(t('Teller operation'));     
        reset(props);    
        setTotalAmountMode(0);
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('TellerOperation', _id)) {
          setIsSaveLoading(false);         
          return;
        }
        
          const data = getValues(); 
          if(data.id > 0) {
            enqueueSnackbar( t('You can not update teller operation'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }

          if(totalAmountMode !== data.amount) {
            enqueueSnackbar( t('The total amount by payment mode is different of total amount paid'), { variant: 'warning',
             anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
             setIsSaveLoading(false);
             return;
          }

          if(data.amount <= 0) {
            enqueueSnackbar( t('There is no billing to pay'), { variant: 'warning',
             anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
             setIsSaveLoading(false);
             return;
          }

          if(data.tellerOperationModes.filter(x => x.type === 'E').length > 1 ) {
            enqueueSnackbar( t('It is impossible to have cash payment more than once'), { variant: 'warning',
             anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
             setIsSaveLoading(false);
             return;
          }

          if(data.bearer.trim() === '' && data.bearer.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              setIsSaveLoading(false);
              return;
            }
          
          mutate({...data, });
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('TellerOperation', _id);
      }

      const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
        openEntityPrintDrawer('TellerOperation', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={0.1}>
                    <Grid item xs={12} md={8} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(20% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                                  {...register(lg.startsWith('fr')?'lastName':'firstName')}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickSearchRegistration}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }}  />   
                                <TextField sx={{width:'calc(20% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                                  {...register(lg.startsWith('fr')?'firstName':'lastName')}  />
                                <TextField sx={{width:'calc(20% - 8px)'}} id="reference" label={t('Reference')} {...register('reference')} />
                                <TextField sx={{width:'calc(20% - 8px)'}} id="bearer" label={t('Bearer')} {...register('bearer')} />
                                <Controller name='paymentSourceCode' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="showroomId"
                                    label={t('Payment source')} inputProps={ {readOnly: false}} focused >
                                    {userPaymentSources && userPaymentSources.map( 
                                        (x,idx) => <MenuItem key={x.paymentSourceCode} value={x.paymentSourceCode}>{x.paymentSourceName}</MenuItem> )
                                      }
                                  </TextField>
                                )} />
                                { openBillingFilter && <FormDialog open={openBillingFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Billing filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenBillingFilter(false);}} onOk={()=> {setOpenBillingFilter(false);}}  >
                                    <BasicTextFilterForm<IBilling> {...basicFilterBilling} />
                                  </FormDialog>  }
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                
                            <Controller control={control}
                                name='operationDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Operation date')} 
                                    onChange={onChange}                     
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(33% - 8px)'}  }} }
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(33% - 8px)'}} />}
                                  /> )}
                              /> 
                          <Controller control={control}
                            name='issueDate' 
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DatePicker label={t('Issue date')} 
                                onChange={onChange}                     
                                value={new Date(value)}
                                slotProps={{ textField: { sx: {width:'calc(33% - 8px)'}  }} }
                                //renderInput={(params) => <TextField {...params} sx={{width:'calc(33% - 8px)'}} />}
                              /> )}
                          /> 
                          <Controller
                            render={({ field: {onChange, onBlur, name, value, ref} }) => {
                              return (
                                <NumberFormat    
                                  label={t('Amount')} sx={{width:'calc(34% - 8px)'}} //disabled={true}
                                  allowEmptyFormatting={false}
                                  control={control}    
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  onValueChange={ (v) => onChange(v.floatValue) }
                                  defaultValue={value}
                                  value={value}
                                  customInput={TextFieldRight}                            
                                />
                              );
                            }}
                            name={`amount`}
                            control={control}
                          />
                           </Box>
                        </Stack>                        
                    </Grid>     
                     
                    <Grid item xs={12} md={12} lg={8} component={Paper} >
                      <Stack flexDirection='column'>                        
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <ArrayFieldTableEx<ITellerOperation,ITellerOperationBilling,'id'> 
                                key={`TellerOperationBilling -`}
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headTellerOperationBillingCells} rowsPathName='tellerOperationBillings' 
                                title={`${t('Billing')}(s)`} rowActionIcon={tellerOperationBillingRowActionIcon}  
                                //onRowSelected={handleRoleEntitySelected}
                                                    
                                refAppend={refAppendTellerOperationBillings as MutableRefObject<(value: Partial<FieldArray<ITellerOperation>> | Partial<FieldArray<ITellerOperation>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateTellerOperationBilling as MutableRefObject<(index: number,value: Partial<FieldArray<ITellerOperation>>) => void>}
                                refRemove={refRemoveTellerOperationBilling as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}
                                
                                toolbarActions={
                                  //getValues().isArticle?
                                  [
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddTellerOperationBilling ,icon: AddCircleIcon, },
                                  ]//: []
                                }
                            />                            
                        </Box>
                      </Stack>   
                    </Grid>      
                    <Grid item xs={12} md={12} lg={4} component={Paper} >
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <ArrayFieldTableEx<ITellerOperation,ITellerOperationMode,'id'> 
                                key={`TellerOperationItem - ${getValues().tellerOperationBillings.length} ${getValues().id}`}
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headTellerOperationModeCells} rowsPathName='tellerOperationModes' 
                                title={`${t('Operation mode')} - Total: ${totalAmountMode}`} rowActionIcon={tellerOperationModeRowActionIcon}  
                                //onRowSelected={handleTellerOperationItemSelected}
                                                    
                                refAppend={refAppendTellerOperationModes as MutableRefObject<(value: Partial<FieldArray<ITellerOperation>> | Partial<FieldArray<ITellerOperation>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateTellerOperationMode as MutableRefObject<(index: number,value: Partial<FieldArray<ITellerOperation>>) => void>}
                                refRemove={refRemoveTellerOperationMode as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}
                                
                                toolbarActions={[
                                    { toolTip: `${t('Print')}...`, onClickIcon: handleAddTellerOperationModes ,icon: AddCircleIcon, },  
                                                          
                                ]}
                            /> 
                            { openOperationModeDetails && <FormDialog open={openOperationModeDetails} maxWidth='sm'
                                  okText='' cancelText='' title={t('Details')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenOperationModeDetails(false);}} onOk={()=> {setOpenOperationModeDetails(false);}}  >
                                      
                              </FormDialog> }                           
                        </Box>
                      </Stack>   
                    </Grid> 
                          
                </Grid>
            </Box>
        </FormProvider> 
  )
}

