
import { type } from 'os'
import { IEnumerationItem } from './Enumeration'
import {BaseType, DataType} from './ExtensionType'

export interface IMatrix {
  id: number,
  
  name: string,
  description: string,
  usage: string,  

  matrixInputs: IMatrixInput[],
  matrixOutputs: IMatrixOutput[],

  currentInputAlias: string,
  currentInputValueExpression: string,
  currentInputExtensionTypeId: number,
  currentInputExtensionTypeName: string,
  currentInputDescription: string,

  currentInputType: DataType,
  currentInputBaseType: BaseType,

  currentOutputAlias: string,
  currentOutputExtensionTypeId: number,
  currentOutputExtensionTypeName: string,
  currentOutputDescription: string,

  currentOutputType: DataType,
  currentOutputBaseType: BaseType,

  matrixVectors: IMatrixVector[]
  
  currentMatrixVectorInputs: IMatrixVectorInput[],
  currentMatrixVectorOutputs: IMatrixVectorOutput[],
}

export interface IMatrixInput {
  id: number,
  matrixId: number,

  extensionTypeId: number,
  extensionTypeName: string,

  extensionEnumerationItems: IEnumerationItem[],

  alias: string,
  valueExpression: string,  
  description: string,

  type: DataType,
  baseType: BaseType
}

export interface IMatrixOutput {
  id: number,
  matrixId: number,

  extensionTypeId: number,
  extensionTypeName: string,

  extensionEnumerationItems: IEnumerationItem[],

  alias: string,
  description: string,
  
  type: DataType,
  baseType: BaseType
}

export interface IMatrixVector {
  id: number,
  matrixId: number,

  matrixVectorInputs: IMatrixVectorInput[],
  matrixVectorOutputs: IMatrixVectorOutput[]
}

export interface IMatrixVectorInput {
  id: number,
  matrixVectorId: number,

  matrixInputId: number,
  matrixInputAlias: string,

  matrixInputOptions: {value: string, name: string} [],
  
  minExtensionValueId: number,
  maxExtensionValueId: number,

  type: DataType,
  baseType: BaseType,

  minValue: any,
  maxValue: any
}

export interface IMatrixVectorOutput {
  id: number,
  matrixVectorId: number,

  matrixOutputId: number,
  matrixOutputAlias: string,

  matrixOutputOptions: {value: string, name: string} [],

  extensionValueId: number,
  type: DataType,
  
  value: any
}


export const defaultMatrix : IMatrix = {
  id: 0,
  
  name: '',
  description: '',
  usage: 'coverage',

  matrixInputs: [],
  matrixOutputs: [],
  
  currentInputAlias: '',
  currentInputValueExpression: '',
  currentInputExtensionTypeId: 0,
  currentInputExtensionTypeName: '',
  currentInputDescription: '',

  currentInputType: 'text',
  currentInputBaseType: 'string',
  
  currentOutputAlias: '',  
  currentOutputExtensionTypeId: 0,
  currentOutputExtensionTypeName: '',
  currentOutputDescription: '',

  currentOutputType: 'text',
  currentOutputBaseType: 'string',

  matrixVectors: [],

  currentMatrixVectorInputs: [],
  currentMatrixVectorOutputs: []
}

export interface IMatrixSearch {
    
  name: string,
  description: string
}