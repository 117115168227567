
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useHookDataService, { useBasicFilterHookData } from './services/HookData';
import useHookDataTemplateService, { useBasicFilterHookDataTemplate } from './services/HookDataTemplate';

import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IHookData, defaultHookData } from './models/HookData';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import useReportService  from 'features/configuration/services/Report';

import IEnumeration from 'features/configuration/models/Enumeration';
import { IReport } from 'features/configuration/models/Report';
import { IHookDataTemplate } from './models/HookDataTemplate';

export const HookDataForm: FC<IHookData> = (props: IHookData = defaultHookData) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const { getReportsByEntity  } = useReportService();

  const { createHookData, updateHookData } = useHookDataService();
  const {getHookDataTemplates} = useHookDataTemplateService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterHookData = useBasicFilterHookData( 
    (event: React.MouseEvent<unknown>, row: IHookData) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const methods = useForm<IHookData>({defaultValues:defaultHookData});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IHookData>,Error,IHookData>(
      _id>0?updateHookData:createHookData, {   
        onSuccess: (data: IResult<IHookData>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['HookData',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const [hookDataTemplates, setHookDataTemplates] = useState<IHookDataTemplate[]>([]);

    const {data: _data, refetch} = useQuery<IHookData>(['HookData', _id], () => retrieveEntity('HookData',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );
      
      useEffect( () => {        
        async function loadHookDataTemplates() {
          const arr = await getHookDataTemplates( { name: '' , description:''});         
          setHookDataTemplates([...arr]);
        }
  
        loadHookDataTemplates();
      }, []);

      useEffect( () => {        
        setCurrentFormNameAtom(t('Certificate Template'));
        setCurrentBasicTextFilterProps(basicFilterHookData);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('HookData',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultHookData);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
          const data = getValues(); 
          // if(data.name.trim() === '' || data.description.trim() === '') {
          //     enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
          //           anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          //     return;
          //   }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('HookData', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label={t('Id')} {...register('id')} 
                                  inputProps={ {readOnly: true}} /> 
                                
                                <Controller name='hookDataTemplateId' control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="hookDataTemplateId"
                                      label={t('Report')} inputProps={ {readOnly: false}}>
                                      {hookDataTemplates.map( 
                                            (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                          }
                                    </TextField>
                                    )}
                                  /> 
                                <TextField sx={{width:'calc(35% - 8px)'}} id="from" label={t('Recipient')} {...register('from')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <FormControlLabel sx={{width:'calc(15% - 8px)'}}
                                    label={t('Treated ?')}
                                    control={
                                    <Controller
                                        name='isTreated'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                    />} 
                                />                              
                            </Box>                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="body" label={t('Body')} {...register('body')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                            </Box>
                            
                        </Stack>                        
                    </Grid>                    
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

