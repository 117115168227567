
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IResource, IResourceSearch } from "../models/Resource";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { DurationInput, EventInput } from '@fullcalendar/core';


import { currentUserSessionAtom } from 'library/store';
//import { IPolicyRisk, IPolicyRiskCoverage } from 'features/resourceion/models/Policy';


const _ = () => {

    const axios = useAxios(); 

    const createResource = async (resource: IResource)  =>       
        await (await axios.post('/api/production/resource/create', resource)).data;       
        
    const updateResource = async (resource: IResource)  =>       
        await (await axios.post('/api/production/resource/update', resource)).data; 
    
    const getResource = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/resource/get-resource/${id}`));
      return await data;
    }
   

    const getResources = async (criteria: IResourceSearch, pagination?: IPagination) : Promise<IResource[]> => {

      const { description, resourceTypeName} = criteria;
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/production/resource/get-resources?description=${description}&resourceTypeName=${resourceTypeName}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    
    const getResourceAllocationsByYear = async (resourceId: number, status: string, year: number) : Promise<
      {weekStart: Date, weekEnd: Date, slotMinTime: DurationInput, slotMaxTime: DurationInput, timetableEvents: EventInput[]}> => {

      const {data} = (await axios.get(`/api/production/resource/get-resource-allocations-by-year?resourceId=${resourceId}&status=${status}&year=${year}`));
      return await data;
    }

    const getResourceAllocationsByMonth = async (resourceId: number, status: string, year: number, month: number) : Promise<
      {weekStart: Date, weekEnd: Date, slotMinTime: DurationInput, slotMaxTime: DurationInput, timetableEvents: EventInput[]}> => {

      const {data} = (await axios.get(`/api/production/resource/get-resource-allocations-by-month?resourceId=${resourceId}&status=${status}&year=${year}&month=${month}`));
      return await data;
    }

    const getResourceAllocationsByWeek = async (resourceIds: number[], status: string, startDate: Date, endDate: Date) : Promise<
      {weekStart: Date, weekEnd: Date, slotMinTime: DurationInput, slotMaxTime: DurationInput, timetableEvents: EventInput[]}> => {

      const ids = resourceIds.join(':-:');
      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());
        
      const {data} = (await axios.get(`/api/production/resource/get-resource-allocations-by-week?resourceIds=${ids}&status=${status}&sStartDate=${sStartDate}&sEndDate=${sEndDate}`));
      return await data;
    }

    const getResourceAllocationsByDay = async (resourceIds: number[], status: string, date: Date) : Promise<
      {weekStart: Date, weekEnd: Date, slotMinTime: DurationInput, slotMaxTime: DurationInput, timetableEvents: EventInput[]}> => {

      const ids = resourceIds.join(':-:');
      const sDate = encodeURIComponent(date.toISOString());
      
      const {data} = (await axios.get(`/api/production/resource/get-resource-allocations-by-day?resourceIds=${ids}&status=${status}&sDate=${sDate}`));
      return await data;
    }
   
    return {    
      createResource,
      updateResource,
      getResource,
      getResources,

      getResourceAllocationsByYear,
      getResourceAllocationsByMonth,

      getResourceAllocationsByWeek,
      getResourceAllocationsByDay
      
    } 
}

export default _;

export interface IFilterResourceOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IResource[], React.Dispatch<React.SetStateAction<IResource[]>>],
}

const defaultFilterResourceOption: IFilterResourceOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterResource = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IResource) => void,
                                            filterOption?: IFilterResourceOption  ) => {

  const { getResources } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterResourceOption;



  const [headResourceCells, setHeadResourceCells]  = useState<HeadCell<IResource>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'permanentResourceName', label : t('Resource type'),  display: true, type: 'string', },    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
          
      {name: 'description', text: t('Description'), value: ''},
      {name: 'resourceTypeName', text: t('Resource type'), value: ''},
    ]);    

  const [filteredResources, ] = useState<IResource[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IResource[]> => {
   
    
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const resourceTypeName = filterElements.find( elt => elt.name === 'resourceTypeName')?.value || '';

    const arr = await getResources( { description, resourceTypeName}, pagination );
    
    return arr;
  }

  const objKey: keyof IResource = 'id';

  return {
    title: t('Resource'), headCells: headResourceCells, objKey,
    filterElements, rows: filteredResources, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
