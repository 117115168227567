

export interface ITarification {
  id: number,
  
  name: string,
  description: string,
  usage: string,

  tarificationMatrixs: ITarificationMatrix[]
}

export interface ITarificationMatrix {
  id: number,
  
  tarificationId: number,
  matrixId: number,

  alias: string,

  tarificationName: string,
  matrixName: string,

  tarificationDescription: string,
  matrixDescription: string,

  matrixUsage: string,
}


export const defaultTarification : ITarification = {
  id: 0,
 
  name: '',
  description: '',
  usage: 'coverage',

  tarificationMatrixs: []
}

export interface ITarificationSearch {
  
  
  name: string,
  description: string
}