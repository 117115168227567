
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IHookData, IHookDataSearch } from "../models/HookData";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createHookData = async (hookdata: IHookData)  =>       
        await (await axios.post('/api/misc/hookData/create-', hookdata)).data;       
        
    const updateHookData = async (hookdata: IHookData)  =>       
        await (await axios.post('/api/misc/hookData/update-', hookdata)).data; 
    
    const getHookData = async (id  : number )  => {
      const {data} = (await axios.get(`/api/misc/hookData/get-hookData/${id}`));
      return await data;
    }
   

    const getHookDatas = async (criteria: IHookDataSearch) : Promise<IHookData[]> => {

      const { from, body} = criteria;

      const {data} = (await axios.get(`/api/misc/hookData/get-hookDatas?from=${from}&body=${body}`));
      return await data;
    }
    
      
    return {    
      createHookData,
      updateHookData,
      getHookData,
      getHookDatas
      
    } 
}

export default _;

export interface IFilterHookDataOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IHookData[], React.Dispatch<React.SetStateAction<IHookData[]>>],
}

const defaultFilterHookDataOption: IFilterHookDataOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterHookData = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IHookData) => void,
                                            filterOption?: IFilterHookDataOption  ) => {

  const { getHookDatas } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterHookDataOption;



  const [headHookDataCells, setHeadHookDataCells]  = useState<HeadCell<IHookData>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'from', label : t('Recipient'),  display: true, type: 'string', },
    {id:'body', label : t('Body'),  display: true, type: 'string', },
    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      {name: 'from', text: t('Recipient'), value: ''},
      {name: 'body', text: t('Body'), value: ''},
      
    ]);    

  const [filteredHookDatas, ] = useState<IHookData[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IHookData[]> => {
    
    
    const from = filterElements.find( elt => elt.name === 'from')?.value || '';
    const body = filterElements.find( elt => elt.name === 'body')?.value || '';
       

    const arr = await getHookDatas( {from,body} );
   
    return arr;
  }

  const objKey: keyof IHookData = 'id';

  return {
    title: t('Certificate Template'), headCells: headHookDataCells, objKey,
    filterElements, rows: filteredHookDatas, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
