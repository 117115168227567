
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import { IExtensionType, defaultExtensionType } from './models/ExtensionType';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useExtensionTypeService, { useBasicFilterExtensionType } from './services/ExtensionType';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { IconButton, InputAdornment, MenuItem } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from './services/Enumeration';
import IEnumeration from './models/Enumeration';

export const ExtensionTypeForm: FC<IExtensionType> = (props: IExtensionType = defaultExtensionType) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const { createExtensionType, updateExtensionType } = useExtensionTypeService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterExtensionType = useBasicFilterExtensionType( 
    (event: React.MouseEvent<unknown>, row: IExtensionType) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          setValue('entityName', name || '');
          setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          setValue('enumerationId', id);
          setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const methods = useForm<IExtensionType>({defaultValues:defaultExtensionType});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchType = watch('type');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IExtensionType>,Error,IExtensionType>(
      _id>0?updateExtensionType:createExtensionType, {   
        onSuccess: (data: IResult<IExtensionType>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['ExtensionType',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IExtensionType>(['ExtensionType', _id], () => retrieveEntity('ExtensionType',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const [dataType,] = useState( [       
        {name: 'text', text: t('Text'), value: ''}, 
        {name: 'integer', text: t('Interger number'), value: ''},     
        {name: 'decimal', text: t('Decimal number'), value: ''},     
        {name: 'float', text: t('Float number'), value: ''},     
        {name: 'date', text: t('Date'), value: ''},     
        {name: 'boolean', text: t('Boolean'), value: ''},     
        {name: 'time', text: t('Time'), value: ''},     
        {name: 'enumeration', text: t('Enumeration'), value: ''},     
        {name: 'entity', text: t('Entity'), value: ''},     
      ]);    

    const handleClickSearchEntity = (event: any) => {
      setOpenEntityFilter(true);
    }

      const handleOkBasicTextFilterEntityForm = () => {
        setOpenEntityFilter(false);
    }

    const handleClickSearchEnumeration = (event: any) => {
      setOpenEnumerationFilter(true);
    }

    const handleOkBasicTextFilterEnumerationForm = () => {
      setOpenEnumerationFilter(false);
    }

      useEffect( () => {        
        setCurrentFormNameAtom(t('Extension type'));
        setCurrentBasicTextFilterProps(basicFilterExtensionType);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('ExtensionType',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultExtensionType);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        if(!checkEntitySaveAuthorization('ExtensionType', _id)) {
          setIsSaveLoading(false);         
          return;
        }

          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('ExtensionType', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(5% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(20% - 8px)'}} id="name" label={t('Name')} {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <TextField sx={{width:'calc(35% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                                <Controller name='type' control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="dbmsType"
                                      label={t('Data type')} inputProps={ {readOnly: false}}>
                                      {dataType.map( 
                                        (x,idx) => <MenuItem key={x.name} value={x.name}>{x.text}</MenuItem> )
                                      }
                                    </TextField>
                                    )}
                                  />
                                {watchType === 'text' && <Controller                                    
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat sx={{width:'calc(10% - 8px)'}}     
                                        label={t('Length')}
                                        //decimalScale={2}
                                        allowEmptyFormatting={false}
                                        control={control}             
                                        
                                        thousandSeparator={true}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        //{...field}
                                        customInput={TextFieldRight}
                                        defaultValue={value}
                                        value={value}
                                      />
                                    );
                                  }}
                                  name='textLength'                                  
                                  control={control}
                                /> }
                                      
                                {watchType === 'enumeration' && <TextField sx={{width:'calc(20% - 8px)'}} id="enumeration" label={t('Enumeration')} inputProps={ {readOnly: true}}
                                  {...register('enumerationName')} 
                                  InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={handleClickSearchEnumeration}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    )
                                  }}
                                />                                 
                                }
                                { openEnumerationFilter && <FormDialog open={openEnumerationFilter} maxWidth='sm'
                                    okText={t('OK')} cancelText='' title={t('Enumeration filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEnumerationFilter(false);}} onOk={handleOkBasicTextFilterEnumerationForm}  >
                                        <BasicTextFilterForm<IEnumeration> {...basicFilterEnumeration } />
                                </FormDialog> }   
                                {watchType === 'entity' && <TextField sx={{width:'calc(20% - 8px)'}} id="entity" label={t('Entity')} inputProps={ {readOnly: true}}
                                  {...register('entityDescription')} 
                                  InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={handleClickSearchEntity}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    )
                                  }}
                                />                                 
                                } 
                                { openEntityFilter && <FormDialog open={openEntityFilter} maxWidth='sm'
                                    okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityFilter(false);}} onOk={handleOkBasicTextFilterEntityForm}  >
                                        <BasicTextFilterForm<IEntity> {...basicFilterEntity } />
                                </FormDialog> } 
                            </Box>
                        </Stack>                        
                    </Grid>                    
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

