
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IReportProcessing, IReportProcessingSearch } from "../models/ReportProcessing";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createReportProcessing = async (hookdata: IReportProcessing)  =>       
        await (await axios.post('/api/misc/reportProcessing/create', hookdata)).data;       
        
    const updateReportProcessing = async (hookdata: IReportProcessing)  =>       
        await (await axios.post('/api/misc/reportProcessing/update', hookdata)).data; 
    
    const getReportProcessing = async (id  : number )  => {
      const {data} = (await axios.get(`/api/misc/reportProcessing/get-reportProcessing/${id}`));
      return await data;
    }
    
   

    const getReportProcessings = async (criteria: IReportProcessingSearch, pagination?: IPagination) : Promise<IReportProcessing[]> => {

      const { messageBody, reportRecipient} = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/misc/reportProcessing/get-reportProcessings?messageBody=${messageBody}&reportRecipient=${reportRecipient}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getSmsReportProcessings = async (criteria: IReportProcessingSearch, pagination?: IPagination) : Promise<IReportProcessing[]> => {

      const { messageBody, reportRecipient} = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/misc/reportProcessing/get-sms-reportProcessings?messageBody=${messageBody}&reportRecipient=${reportRecipient}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }
    
      
    return {    
      createReportProcessing,
      updateReportProcessing,
      getReportProcessing,
      getReportProcessings,

      getSmsReportProcessings
      
    } 
}

export default _;

export interface IFilterReportProcessingOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IReportProcessing[], React.Dispatch<React.SetStateAction<IReportProcessing[]>>],
}

const defaultFilterReportProcessingOption: IFilterReportProcessingOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterReportProcessing = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IReportProcessing) => void,
                                            filterOption?: IFilterReportProcessingOption  ) => {

  const { getReportProcessings } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterReportProcessingOption;



  const [headReportProcessingCells, setHeadReportProcessingCells]  = useState<HeadCell<IReportProcessing>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'reportRecipient', label : t('Recipient'),  display: true, type: 'string', },
    {id:'messageBody', label : t('Body'),  display: true, type: 'string', },
    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      {name: 'reportRecipient', text: t('Recipient'), value: ''},
      {name: 'messageBody', text: t('Body'), value: ''},
      
    ]);    

  const [filteredReportProcessings, ] = useState<IReportProcessing[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IReportProcessing[]> => {
    
    
    const reportRecipient = filterElements.find( elt => elt.name === 'reportRecipient')?.value || '';
    const messageBody = filterElements.find( elt => elt.name === 'messageBody')?.value || '';
       

    const arr = await getReportProcessings( {reportRecipient,messageBody} );
   
    return arr;
  }

  const objKey: keyof IReportProcessing = 'id';

  return {
    title: t('Report processing'), headCells: headReportProcessingCells, objKey,
    filterElements, rows: filteredReportProcessings, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}

export const useBasicFilterSmsReportProcessing = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IReportProcessing) => void,
                                            filterOption?: IFilterReportProcessingOption  ) => {

  const { getSmsReportProcessings } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterReportProcessingOption;



  const [headReportProcessingCells, setHeadReportProcessingCells]  = useState<HeadCell<IReportProcessing>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'reportRecipient', label : t('Recipient'),  display: true, type: 'string', },
    {id:'messageBody', label : t('Body'),  display: true, type: 'string', },
    {id:'isProcessed', label : t('Is Processed ?'),  display: true, type: 'boolean', },

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      {name: 'reportRecipient', text: t('Recipient'), value: ''},
      {name: 'messageBody', text: t('Body'), value: ''},
      
    ]);    

  const [filteredReportProcessings, ] = useState<IReportProcessing[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IReportProcessing[]> => {
    
    
    const reportRecipient = filterElements.find( elt => elt.name === 'reportRecipient')?.value || '';
    const messageBody = filterElements.find( elt => elt.name === 'messageBody')?.value || '';
       

    const arr = await getSmsReportProcessings( {reportRecipient,messageBody}, pagination );
   
    return arr;
  }

  const objKey: keyof IReportProcessing = 'id';

  return {
    title: t('Report processing'), headCells: headReportProcessingCells, objKey,
    filterElements, rows: filteredReportProcessings, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
