export type ReportProcessingType = 'sms' | 'whatsApp'; 

export interface IReportProcessing {
    id: number,

    reportDocumentId: number,
    entityId: number,
    jobScheduleId: number,
    validityStartDate: Date,
    validityEndDate: Date,

    type: ReportProcessingType,

    messageBody: string,
    messageObject: string,
    reportRecipient: string,
    
    base64Report: string,
    reportFileName: string,
    isProcessed: boolean,
    lastProcessingError: string,
    processingDate: Date,

    reportRecipients: string[],
    currentRecipient: string
}


export const defaultReportProcessing : IReportProcessing = {
    id: 0,

    reportDocumentId: 0,
    entityId: 0,
    jobScheduleId: 0,
    validityStartDate: new Date(),
    validityEndDate: new Date(),

    type: 'sms',

    messageBody: '',
    messageObject: '',
    reportRecipient: '',
    
    base64Report: '',
    reportFileName: '',
    isProcessed: false,
    lastProcessingError: '',
    processingDate: new Date(),

    reportRecipients: [],
    currentRecipient: ''
}

export interface IReportProcessingSearch {
    messageBody: string,
    reportRecipient: string

}