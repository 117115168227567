
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IHookDataTemplate, IHookDataTemplateSearch } from "../models/HookDataTemplate";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createHookDataTemplate = async (hookdatatemplate: IHookDataTemplate)  =>       
        await (await axios.post('/api/misc/hookDataTemplate/create', hookdatatemplate)).data;       
        
    const updateHookDataTemplate = async (hookdatatemplate: IHookDataTemplate)  =>       
        await (await axios.post('/api/misc/hookDataTemplate/update', hookdatatemplate)).data; 
    
    const getHookDataTemplate = async (id  : number )  => {
      const {data} = (await axios.get(`/api/misc/hookDataTemplate/get-hookDataTemplate/${id}`));
      return await data;
    }
   

    const getHookDataTemplates = async (criteria: IHookDataTemplateSearch) : Promise<IHookDataTemplate[]> => {

      const { name, description} = criteria;

      const {data} = (await axios.get(`/api/misc/hookDataTemplate/get-hookDataTemplates?name=${name}&description=${description}`));
      return await data;
    }
    
      
    return {    
      createHookDataTemplate,
      updateHookDataTemplate,
      getHookDataTemplate,
      getHookDataTemplates
      
    } 
}

export default _;

export interface IFilterHookDataTemplateOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IHookDataTemplate[], React.Dispatch<React.SetStateAction<IHookDataTemplate[]>>],
}

const defaultFilterHookDataTemplateOption: IFilterHookDataTemplateOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterHookDataTemplate = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IHookDataTemplate) => void,
                                            filterOption?: IFilterHookDataTemplateOption  ) => {

  const { getHookDataTemplates } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterHookDataTemplateOption;



  const [headHookDataTemplateCells, setHeadHookDataTemplateCells]  = useState<HeadCell<IHookDataTemplate>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      
    ]);    

  const [filteredHookDataTemplates, ] = useState<IHookDataTemplate[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IHookDataTemplate[]> => {
    
    
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
       

    const arr = await getHookDataTemplates( {name,description} );
   
    return arr;
  }

  const objKey: keyof IHookDataTemplate = 'id';

  return {
    title: t('Certificate Template'), headCells: headHookDataTemplateCells, objKey,
    filterElements, rows: filteredHookDataTemplates, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
