
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IResourceType, IResourceTypeSearch } from "../models/ResourceType";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';
//import { IPolicyRisk, IPolicyRiskCoverage } from 'features/resourceTypeion/models/Policy';


const _ = () => {

    const axios = useAxios(); 

    const createResourceType = async (resourceType: IResourceType)  =>       
        await (await axios.post('/api/setup/resourceType/create', resourceType)).data;       
        
    const updateResourceType = async (resourceType: IResourceType)  =>       
        await (await axios.post('/api/setup/resourceType/update', resourceType)).data; 
    
    const getResourceType = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/resourceType/get-resourceType/${id}`));
      return await data;
    }
   

    const getResourceTypes = async (type: number, criteria: IResourceTypeSearch, pagination?: IPagination) : Promise<IResourceType[]> => {

      const {name, description, categoryCode} = criteria;
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/setup/resourceType/get-resourceTypes?type=${type}&name=${name}&description=${description}&categoryCode=${categoryCode}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }
    
   
    return {    
      createResourceType,
      updateResourceType,
      getResourceType,
      getResourceTypes,

      
    } 
}

export default _;

export interface IFilterResourceTypeOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IResourceType[], React.Dispatch<React.SetStateAction<IResourceType[]>>],
}

const defaultFilterResourceTypeOption: IFilterResourceTypeOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterResourceType = (  type: number, onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IResourceType) => void,
                                            filterOption?: IFilterResourceTypeOption  ) => {

  const { getResourceTypes } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterResourceTypeOption;



  const [headResourceTypeCells, setHeadResourceTypeCells]  = useState<HeadCell<IResourceType>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', },
    {id:'categoryCode', label : t('Category'),  display: true, type: 'string', },

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      {name: 'categoryCode', text: t('Category'), value: ''},
    ]);    

  const [filteredResourceTypes, ] = useState<IResourceType[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IResourceType[]> => {
   
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const categoryCode = filterElements.find( elt => elt.name === 'categoryCode')?.value || '';

    const arr = await getResourceTypes( type, { name,description, categoryCode}, pagination );
    
    return arr;
  }

  const objKey: keyof IResourceType = 'id';

  return {
    title: t('ResourceType'), headCells: headResourceTypeCells, objKey,
    filterElements, rows: filteredResourceTypes, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
