
import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IMenu } from "components/models/Menu";

import { IoSearchCircleSharp } from 'react-icons/io5';


const _ = () => {

    const { t, i18n } = useTranslation();

    const [applicationMenus,setApplicationMenus] = useState<IMenu[]>([]);

    const menu1 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu2 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu3 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }

    const menu4 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu5 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu6 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }
    const menu7 : IMenu = { name: '', text: '', anchorRef: useRef(null), isOpen: false, iconName: '',  menuItems: [] }

    //const [applicationMenus,setApplicationMenus] = useState<IMenu[]>();

        useEffect( () => {
          setApplicationMenus(
            [
              {
                ...menu1, name: 'param', text: t('Setting'), iconName:'FiSettings', isOpen: false,
                menuItems: [
                  {name: 'applicationSetup', text: t('Application Setup'), iconName: 'MdSettingsApplications', entityName: 'ApplicationSetup', routeToGo: '/applicationSetup',   order: 1, group: 1},
                  {name: 'applicationQuery', text: t('Application Query'), iconName: 'AiOutlineConsoleSql', entityName: 'ApplicationQuery', routeToGo: '/applicationQuery',   order: 2, group: 1},
                  {name: 'entityActionReport', text: t('Entity Action Report'), iconName: 'AiOutlineConsoleSql', entityName: 'EntityActionReport', routeToGo: '/entityActionReport',   order: 3, group: 1},
                  
                  
                  {name: 'sharing', text: `${t('Sharing')} - ${t('Commission')}`, iconName: 'TbLayoutBoardSplit', entityName: 'Sharing', routeToGo: '/sharing',  order: 1, group: 2},                   
                  {name: 'resourceType', text: t('Resource type'), iconName: 'BiDirections', entityName: 'ResourceType', routeToGo: '/resourceType',  order: 2, group: 2},
                  
                  {name: 'familyProduct', text: t('Family product'), iconName: 'MdCategory', entityName: 'FamilyProduct', routeToGo: '/familyProduct',  order: 1, group: 3},
                  {name: 'product', text: t('Product'), iconName: 'TbBrandProducthunt', entityName: 'Product', routeToGo: '/product',  order: 2, group: 3},

                  {name: 'storeProduct', text: t('Product of store'), iconName: 'TbBrandProducthunt', entityName: 'StoreProduct', routeToGo: '/storeProduct',  order: 3, group: 3},
                  
                  
                  //{name: 'packaging', text: t('Packaging'), iconName: 'TbPackage', entityName: 'Packaging', routeToGo: '/packaging',  order: 2, group: 3},

                  //{name: 'requestType', text: t('Request type'), iconName: 'GrDocumentConfig', entityName: 'RequestType', routeToGo: '/requestType',  order: 2, group: 3},           

                  //{name: 'coverage', text: `${t('Coverage')} - ${t('Extra')}`, iconName: 'GiProtectionGlasses', entityName: 'Coverage', routeToGo: '/coverage',  order: 1, group: 4},           
                  //{name: 'productType', text: t('Product type'), iconName: 'FiType', entityName: 'ProductType', routeToGo: '/productType',  order: 2, group: 4},           
                  //{name: 'product', text: t('Product'), iconName: 'TbBrandProducthunt', entityName: 'Product', routeToGo: '/product',  order: 3, group: 4},           
                ]
              }, 
              
              { 
                ...menu2, name: 'config', text: t('Configuration'), iconName:'MdPermDataSetting', isOpen: false,
                menuItems: [
                  {name: 'extensionType', text: t('Extension type'), iconName: 'VscExtensions' , entityName: 'ExtensionType',routeToGo: '/extensionType', order: 0, group: 1},
                  {name: 'enumeration', text: t('Codification'), iconName: 'RiQrCodeFill' , entityName: 'Enumeration',routeToGo: '/enumeration', order: 1, group: 1},
                  // {name: 'matrix', text: 'Matrix', entityName: 'Matrix',routeToGo: '/', order: 2, group: 1},
                  // {name: 'vector', text: 'Vector', entityName: 'Vector',routeToGo: '/', order: 2, group: 1},
                  {name: 'report', text: t('Report'), iconName: 'TbFileReport', entityName: 'Report',routeToGo: '/report', order: 0, group: 2},

                  {name: 'externalLibrary', text: t('Api file'), iconName: 'VscFileBinary', entityName: 'ExternalLibrary',routeToGo: '/externalLibrary', order: 0, group: 4},
      
                ]
              },
              { 
                ...menu3, name: 'security', text: t('Security'), iconName:'GrArticle', isOpen: false,
                menuItems: [
                  {name: 'role', text: t('Role'), iconName: 'TbLockAccess', entityName: 'Role', routeToGo: '/role',  order: 0, group: 3},
                  //{name: 'group', text: 'Group', entityName: 'Role', routeToGo: '/',  order: 1, group: 1},
                  //{name: 'profile', text: 'Profile', entityName: 'Profile', routeToGo: '/',  order: 1, group: 1},
                  {name: 'user', text: t('User'), iconName: 'TbUserCheck', entityName: 'User', routeToGo: '/user',order: 1, group: 3},
                  {name: 'group', text: t('Group'), iconName: 'MdGroup', entityName: 'Group', routeToGo: '/group',order: 2, group: 3},
      
                ]
              },
              { 
                ...menu4, name: 'operation', text: t('Operation'), iconName:'FaUniversity', isOpen: false,
                menuItems: [

                  {name: 'deliveryDashboard', text: t('Deliveries table'), iconName: 'TbMathSymbols' , entityName: 'DeliveryDashboard',routeToGo: '/deliveryDashboard', order: 1, group: 1},       
                  {name: 'expenseDashboard', text: t('Expenses table'), iconName: 'TbMathSymbols' , entityName: 'ExpenseDashboard',routeToGo: '/expenseDashboard', order: 1, group: 1},   
                  
                  {name: 'store', text: `${t('Store')} - ${t('Showroom')}`, iconName: 'FaStore' , entityName: 'Store',routeToGo: '/store', order: 1, group: 2},       
                                    
                  {name: 'delivery', text: t('Receipt of items'), iconName: 'FaTruck' , entityName: 'Delivery',routeToGo: '/delivery', order: 0, group: 3}, 
                  {name: 'stockTransfer', text: `${t('StockTransfer')}`, iconName: 'MdCompareArrows' , entityName: 'StockTransfer',routeToGo: '/stockTransfer', order: 1, group: 3},
                  {name: 'stockMovement', text: `${t('StockMovement')}`, iconName: 'FaArrowDown' , entityName: 'StockMovement',routeToGo: '/stockMovement', order: 2, group: 3},

                  {name: 'physicalInventory', text: `${t('Physical inventory')}`, iconName: 'FaBoxes', entityName: 'PhysicalInventory', routeToGo: '/physicalInventory',  order: 1, group: 4},

                  {name: 'expense', text: `${t('Expense')} - ${t('Pur. Order')}`, iconName: 'IoIosCash', entityName: 'Expense', routeToGo: '/expense',  order: 1, group: 5},

                  {name: 'resource', text: t('Resource'), iconName: 'IoIosConstruct', entityName: 'Resource', routeToGo: '/resource',  order: 1, group: 6},                               
                  {name: 'resourceAllocation', text: `${t('Resource')} - ${t('Allocation')}`, iconName: 'MdOutlineCalendarViewMonth', entityName: 'ResourceAllocationTimeTable', routeToGo: '/resourceAllocation',  order: 1, group: 6},                               
                                    
                ]
              },
              // 
              {
                ...menu5, name: 'center', text: t('Center'), iconName:'GiSaloon', isOpen: false,
                menuItems: [                  
                  {name: 'billingDashboard', text: t('Billings table'), iconName: 'TbMathSymbols' , entityName: 'BillingDashboard',routeToGo: '/billingDashboard', order: 1, group: 1},       

                  {name: 'employee', text: `${t('Employee')}`, iconName: 'TbUser', entityName: 'Employee',routeToGo: '/employee', order: 1, group: 2}, 

                  {name: 'appointment', text: t('Appointment'), iconName: 'MdOutlineSchedule', entityName: 'Appointment', routeToGo: '/appointment',  order: 1, group: 3},
                  {name: 'appointmentTimeTable', text: `${t('Scheduling')} - ${t('Appointment')}`, iconName: 'MdOutlineCalendarViewMonth', entityName: 'AppointmentTimeTable',routeToGo: '/appointmentTimeTable', order: 2, group: 3}, 

                  //{name: 'employee', text: t('Employee'), iconName: 'MdOutlineCancel', entityName: 'Employee',routeToGo: '/employee', order: 2, group: 1}, 
                  
                  {name: 'billing', text: t('Facturation'), iconName: 'FaMoneyBill', entityName: 'Billing', routeToGo: '/billing',  order: 1, group: 4},
                  {name: 'delivery', text: `${t('Delivery')} - ${t('Client')} `, iconName: 'FaHandshake' , entityName: 'BillingDelivery',routeToGo: '/billingDelivery', order: 2, group: 4},                  
                  //{name: 'paymentGenerating', text: `${t('Payment')} - ${t('Generating')}`, iconName: 'MdGeneratingTokens', entityName: 'PointPaymentGenerating', routeToGo: '/paymentGenerating',  order: 2, group: 3},
                  
                  {name: 'ticket', text: t('Ticket'), iconName: 'FaTicketAlt', entityName: 'Ticket',routeToGo: '/ticket', isSessionMenu: true, order: 1, group: 5}, 
                  {name: 'problem', text: t('Problem'), iconName: 'FaExclamationTriangle', entityName: 'Problem',routeToGo: '/problem', order: 2, group: 5}, 

                  {name: 'person', text: t('Person'), iconName: 'MdPeopleOutline', entityName: 'Person',routeToGo: '/person', order: 1, group: 6}, 
                ]
              },
              {
                ...menu6, name: 'finance', text: t('Finance'), iconName:'SiWebmoney', isOpen: false,
                menuItems: [
                  {name: 'financeDashbord', text: t('Dashboard'), iconName: 'TbMathSymbols' , entityName: 'FinanceDashboard',routeToGo: '/financeDashboard', order: 1, group: 1},       

                  {name: 'groupBilling', text: t('Group billing'), iconName: 'BiCollection', entityName: 'GroupBilling', routeToGo: '/groupBilling',  order: 1, group: 2},
                  {name: 'billing', text: t('Billing'), iconName: 'FaMoneyBill',entityName: 'Billing',routeToGo: '/billing', order: 2, group: 2},       
                  
                  {name: 'tellerOperation', text: t('Teller operation'), iconName: 'GiReceiveMoney', entityName: 'TellerOperation',routeToGo: '/tellerOperation', order: 3, group: 2},   
                  //{name: 'tellerOperationEx', text: `${t('Teller operation')} (${t('articles')})`, iconName: 'GiReceiveMoney', entityName: 'TellerOperationEx',routeToGo: '/tellerOperationEx', order: 2, group: 1},   

                  {name: 'tellerDayCloging', text: t('Day closing'), iconName: 'TbReportMoney', entityName: 'TellerDayClosing',routeToGo: '/tellerDayClosing', order: 4, group: 2},   
                  //{name: 'paymentOperation', text: 'Payment Operation', entityName: 'Payment Operation',routeToGo: '/', order: 1, group: 2},

                  {name: 'cashOut', text: t('Cash out'), iconName: 'BiMoney', entityName: 'CashOut',routeToGo: '/cashOut', order: 1, group: 3},  
                  {name: 'cashControl', text: t('Cash control'), iconName: 'FaChartPie', entityName: 'CashControl',routeToGo: '/cashControl', order: 2, group: 3},  

                  {name: 'payment', text: t('Payment'), iconName: 'MdMoneyOff', entityName: 'Payment',routeToGo: '/payment', order: 1, group: 4},   
                  //{name: 'paymentOperation', text: t('Payment operation'), iconName: 'GiPayMoney', entityName: 'PaymentOperation',routeToGo: '/paymentOperation', order: 2, group: 3},   

                ]
              },
              {
                ...menu7, name: 'more', text: t('More'), iconName:'CgMoreO', isOpen: false,
                menuItems: [
                  // {name: 'editionConfig', text: 'EditionConfig', entityName: 'EditionConfig',routeToGo: '/', order: 1, group: 1},       
                  // {name: 'objectEdition', text: 'ObjectEdition', entityName: 'ObjectEdition',routeToGo: '/', order: 1, group: 1}, 
                  // {name: 'routineObjectData', text: 'RoutineObjectData', entityName: 'RoutineObjectData',routeToGo: '/', order: 1, group: 1},
                  // {name: 'routineFilter', text: 'RoutineFilter', entityName: 'RoutineFilter',routeToGo: '/', order: 1, group: 1},
                  {name: 'smsApi', text: t('SmsApi'), iconName: 'TbMessages', entityName: 'SmsApi',routeToGo: '/smsApi', order: 1, group: 1},
                  {name: 'whatsAppApi', text: t('WhatsAppApi'), iconName: 'TbBrandWhatsapp', entityName: 'ApplicationSetupWhatsApp',routeToGo: '/applicationSetupWhatsApp', order: 2, group: 1},
                  {name: 'mailApi', text: t('MailApi'), iconName: 'TbBrandGmail', entityName: 'ApplicationSetupEmail',routeToGo: '/applicationSetupEmail', order: 3, group: 1},
                  
                  {name: 'job', text: t('Job schedule'), iconName: 'MdScheduleSend', entityName: 'Job',routeToGo: '/job', order: 1, group: 2},

                  //{name: 'certificateTemplate', text: t('Certificate Templ.'), iconName: 'VscNotebookTemplate', entityName: 'CertificateTemplate',routeToGo: '/certificateTemplate', order: 1, group: 3},
                  //{name: 'certificateAllocation', text: t('Certificate Alloc.'), iconName: 'TbFileCertificate', entityName: 'CertificateAllocation',routeToGo: '/certificateAllocation', order: 2, group: 3},

                  //{name: 'hookDataTemplate', text: t('HookData Templ.'), iconName: 'VscNotebookTemplate', entityName: 'HookDataTemplate',routeToGo: '/hookDataTemplate', order: 1, group: 4},
                  //{name: 'hookData', text: t('HookData'), iconName: 'TbFileCertificate', entityName: 'HookData',routeToGo: '/hookData', order: 2, group: 4},

                  {name: 'edition', text: t('Desktop publishing'), iconName: 'TbReportAnalytics', entityName: 'Edition',routeToGo: '/edition', order: 2, group: 5},

                  {name: 'instantSms', text: t('Instant SMS'), iconName: 'FaComments', entityName: 'InstantSms',routeToGo: '/instantSms', order: 0, group: 4},
                  // {name: 'postingRule', text: 'PostingRule', entityName: 'PostingRule',routeToGo: '/', order: 1, group: 3},
                ]
              },
              ]
          );
        },[t, i18n]);

        return {
            applicationMenus, setApplicationMenus
        }
  }

  export default _;