

export interface IGroup {
  id: number,
  
  name: string,
  description: string,
  
  groupUsers: IGroupUser[]
}

export interface IGroupUser {
  id: number,
  
  groupId: number,
  userId: number,

  type: string,

  userName: string,
  userDescription: string,  

  groupName: string,
  groupDescription: string,  
}


export const defaultGroup : IGroup = {
  id: 0,
 
  name: '',
  description: '',
  
  groupUsers: []
}

export interface IGroupSearch {
    
  name: string,
  description: string
}