
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import { ILinkedStore, IStore, IStoreCurrency, defaultStore } from './models/Store';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useStoreService, { useBasicFilterStore } from './services/Store';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration, { Enum_CURRENCY, IEnumerationItem } from './models/Enumeration';


import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { sum } from 'lodash';

import { carouselImage } from 'themes/commonStyles';
import { isFalsy } from 'utility-types';

import EnhancedTable, { HeadCell } from 'components/ui/EnhancedTable';
import { IArticleStock } from 'features/configuration/models/Delivery';
import useDeliveryService from 'features/configuration/services/Delivery';
import ArrayFieldTableEx, { ActionIconTableRow } from 'components/ui/ArrayFieldTableEx';

export const StoreForm: FC<IStore> = (props: IStore = defaultStore) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const { createStore, updateStore } = useStoreService();
  const {getArticlePerStores } = useDeliveryService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterStore = useBasicFilterStore( 
    (event: React.MouseEvent<unknown>, row: IStore) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openLinkedStoreFilter, setOpenLinkedStoreFilter] = useState(false);
  const basicFilterLinkedStore = useBasicFilterStore( 
      (event: React.MouseEvent<unknown>, row: IStore) => {
          const {id, name, description} = row;

          const {linkedStores} = getValues();
          if(id === getValues().id) return;

          if(linkedStores.some(s => s.otherStoreId === id)) {
            setOpenLinkedStoreFilter(false);
            return;
          }

          (refAppendLinkedStores.current??emptyFunc)({id: 0,  storeId: _id, 
              otherStoreId: id, otherStoreName: name, allowDelivery: true, allowTransfer: true});
          setOpenLinkedStoreFilter(false);
      }
  );

  const emptyFunc = (obj: any) => {}  

  const methods = useForm<IStore>({defaultValues:defaultStore});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchBase64Picture = watch('base64Picture');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IStore>,Error,IStore>(
      _id>0?updateStore:createStore, {   
        onSuccess: (data: IResult<IStore>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Store')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Store',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IStore>(['Store', _id], () => retrieveEntity('Store',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Store'], () => 
          getEnumerationItemsByEnumerationCodes( [Enum_CURRENCY ] ));

      function openFileDialog() {
        (document as any).getElementById("file-upload").click();
      }
      
      const setFile = (_event: any) => {
        let f = _event.target.files![0];
      
        const fileSizeInKB = f.size / 1024;
        // Check if the file size is within your limit (e.g., 200 KB)
        if (fileSizeInKB > 200) {
          alert(t('File size should be less than 200 KB'));
          return;
        }
      
        var reader = new FileReader();
      
        reader.onload = function () {
      
            var binaryString = reader.result as string;
        
            const base64String = binaryString
                                        .replace('data:', '')
                                        .replace(/^.+,/, '');
      
            setValue("base64Picture", base64String);
            setValue("fileName", f.name);
          };
      
          reader.onerror = function () {
            console.log("File load failed");
          };    
          reader.readAsDataURL(f);    
      };


      const getCurrencyCodeOptions = (row: IStoreCurrency, cellId: keyof IStoreCurrency, 
        opts: {value: string|number, name: string}[]) : {value: string|number, name: string}[] => {        
        
          return getAsOptions(refEnumItems.current ?? [],Enum_CURRENCY);
      }

      const cellEditableStoreCurrency = (row: IStoreCurrency, cellId: keyof IStoreCurrency) => {
        return true;
      }

      const [headStoreCurrencyCells, setHeadStoreCurrencyCells]  = useState<HeadCell<IStoreCurrency>[]>([      
        //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
        {id:'currencyCode', label : t('Code'),  display: true, type: 'string', width: 100, 
            isEditable: cellEditableStoreCurrency, getOptions: getCurrencyCodeOptions},
        //{id:'currencyCode', label : `${t('Name')}`,  display: true, type: 'string', width: 70},       
        
      ]);
  
      const refAppendStoreCurrencies = useRef<(value: Partial<FieldArray<IStore>> | Partial<FieldArray<IStore>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateStoreCurrency = useRef<(index: number,value: Partial<FieldArray<IStore>> ) => void>(null);
      const refRemoveStoreCurrency = useRef<(index: number ) => void>(null);
        
      const handleAddStoreCurrencies = (event: any) => {
        (refAppendStoreCurrencies.current??emptyFunc)({id: 0,  storeId: _id, currencyCode: ''});
      }
  
      const storeCurrencyRowActionIcon = ( storeCurrency: IStoreCurrency) : ActionIconTableRow<IStore,IStoreCurrency> => {
    
        const res: ActionIconTableRow<IStore,IStoreCurrency> = {
          toolTip: 'remove',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: IStoreCurrency) => {
            
             (refRemoveStoreCurrency.current??emptyFunc)(index);            
          }
        }
        return res;
    }

    const cellEditableLinkedStore = (row: ILinkedStore, cellId: keyof ILinkedStore) => {
      return true;
    }

    const [headLinkedStoreCells, setHeadLinkedStoreCells]  = useState<HeadCell<ILinkedStore>[]>([      
      //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
      {id:'otherStoreName', label : t('Code'),  display: true, type: 'string', width: 70},
      {id:'allowDelivery', label : `${t('Delivery')} ?`,  display: true, type: 'boolean', width: 15, 
           isEditable: cellEditableLinkedStore},
      {id:'allowTransfer', label : `${t('Transfer')} ?`,  display: true, type: 'boolean', width: 15, 
          isEditable: cellEditableLinkedStore},
      //{id:'currencyCode', label : `${t('Name')}`,  display: true, type: 'string', width: 70},  
    ]);

    const refAppendLinkedStores = useRef<(value: Partial<FieldArray<IStore>> | Partial<FieldArray<IStore>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateLinkedStore = useRef<(index: number,value: Partial<FieldArray<IStore>> ) => void>(null);
    const refRemoveLinkedStore = useRef<(index: number ) => void>(null);
      
    const handleAddLinkedStores = (event: any) => {
      setOpenLinkedStoreFilter(true);
    }

    const linkedStoreRowActionIcon = ( storeCurrency: ILinkedStore) : ActionIconTableRow<IStore,ILinkedStore> => {
  
      const res: ActionIconTableRow<IStore,ILinkedStore> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: ILinkedStore) => {
          
           (refRemoveStoreCurrency.current??emptyFunc)(index);            
        }
      }
      return res;
  }
    

    
    const refEnumItems = useRef<IEnumerationItem[]>([]);    
    useEffect( () => {   
        refEnumItems.current = (enumItems || []);
      
    }, [enumItems]);

    const [articleStocks, setArticleStocks] = useState<IArticleStock[]>([]);
    useEffect( () => {
      
      async function _() {   
                
        const stocks = _id>0 ? await getArticlePerStores(_id, 0) : [];
        setArticleStocks(stocks);
      }
      _();  
    }, [_id] );



      useEffect( () => {        
        setCurrentFormNameAtom(t('Store'));
        setCurrentBasicTextFilterProps(basicFilterStore);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('Store')} - # ${_id} # -`: t('Store') );
            if(_id > 0)
              retrieveData('Store',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultStore);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        if(!checkEntitySaveAuthorization('Store', _id)) {
          setIsSaveLoading(false);         
          return;
        }

          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Store', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                
                                <TextField sx={{width:'calc(60% - 8px)'}} id="name" label={t('Name')} 
                                  {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <TextField sx={{width:'calc(40% - 8px)'}} id="location" label={t('Location')}
                                  {...register('location')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(75% - 8px)'}} id="description" 
                                  label={t('Description')} {...register('description')} />
                              <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                label={`${t('Showroom')} ?`}
                                control={
                                <Controller
                                    name='isShowroom'
                                    control={control}
                                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} /> 
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              
                              <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                label={`${t('Explicit product definition')} ?`}
                                control={
                                <Controller
                                    name='explicitProductDefinition'
                                    control={control}
                                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} /> 
                            </Box>
                            <Box sx={{ mt: 3, width: '100%' }} >
                              <ArrayFieldTableEx<IStore,IStoreCurrency,'id'> 
                                  mainObject={getValues()} fieldKey='id' 
                                  headCells={headStoreCurrencyCells} rowsPathName='storeCurrencies' 
                                  title={t('Currencies allowed for this store')} rowActionIcon={storeCurrencyRowActionIcon}  
                                  //onRowSelected={handleRoleEntitySelected}
                                                      
                                  refAppend={refAppendStoreCurrencies as MutableRefObject<(value: Partial<FieldArray<IStore>> | Partial<FieldArray<IStore>>[], options?: FieldArrayMethodProps) => void>}
                                  refUpdate={refUpdateStoreCurrency as MutableRefObject<(index: number,value: Partial<FieldArray<IStore>>) => void>}
                                  refRemove={refRemoveStoreCurrency as MutableRefObject<(index: number) => void>}

                                  //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                  //displayMore={undefined}
                                  toolbarActions={[
                                  { toolTip: `${t('Add')}...`, onClickIcon: handleAddStoreCurrencies ,icon: AddCircleIcon,  },
                                  
                                  ]}
                                  canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                              />                        
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                label={`${t('Linked to all store for delivery')} ?`}
                                control={
                                <Controller
                                    name='linkedToAllStoreDelivery'
                                    control={control}
                                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} /> 
                              <FormControlLabel sx={{width:'calc(50% - 8px)'}}
                                label={`${t('Linked to all store for transfer')} ?`}
                                control={
                                <Controller
                                    name='linkedToAllStoreTransfer'
                                    control={control}
                                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} /> 
                            </Box>
                            <Box sx={{ mt: 3, width: '100%' }} >
                              <ArrayFieldTableEx<IStore,ILinkedStore,'id'> 
                                  mainObject={getValues()} fieldKey='id' 
                                  headCells={headLinkedStoreCells} rowsPathName='linkedStores' 
                                  title={t('Linked stores of this store')} rowActionIcon={linkedStoreRowActionIcon}  
                                  //onRowSelected={handleRoleEntitySelected}
                                                      
                                  refAppend={refAppendLinkedStores as MutableRefObject<(value: Partial<FieldArray<IStore>> | Partial<FieldArray<IStore>>[], options?: FieldArrayMethodProps) => void>}
                                  refUpdate={refUpdateLinkedStore as MutableRefObject<(index: number,value: Partial<FieldArray<IStore>>) => void>}
                                  refRemove={refRemoveLinkedStore as MutableRefObject<(index: number) => void>}

                                  //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                  //displayMore={undefined}
                                  toolbarActions={[
                                  { toolTip: `${t('Add')}...`, onClickIcon: handleAddLinkedStores ,icon: AddCircleIcon,  },
                                  
                                  ]}
                                  canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                              />   
                              { openLinkedStoreFilter && <FormDialog open={openLinkedStoreFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Store')} onCancel={()=> {}} 
                              onClose={()=> {setOpenLinkedStoreFilter(false);}} onOk={()=> {setOpenLinkedStoreFilter(false);}}  >
                                  <BasicTextFilterForm<IStore> {...basicFilterLinkedStore } />
                          </FormDialog> }                     
                            </Box>
                            <Box sx={{ mt: 5, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                              <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}
                                accept="image/*" />
                              <div key={'step.id'}>
                                <Box
                                  component="img"
                                  sx={carouselImage}
                                  //src={`data:image/png;base64,${watchBase64Picture}`}
                                  src={isFalsy(watchBase64Picture)?`/images/product.jpg`:`data:image/png;base64,${watchBase64Picture}`}
                                  onClick={openFileDialog}
                                  //alt={step.id}
                                ></Box>
                              </div>
                              {/* <img  width='100%'
                                onClick={openFileDialog} height='100%' maxHeight='250px' /> */}
                            </Box>
                        </Stack>                        
                    </Grid> 
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'  >
                        <Box sx={{ mt: 1, width: '100%' }} >                        
                          <EnhancedTable<IArticleStock> rows={articleStocks} 
                            headCells={[            
                              //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                              {id:'articleName', label : t('Article'),  display: true, type: 'string', width: 33},
                              {id:'option', label : t('Option'),  display: true, type: 'string', width: 12},

                              {id:'quantityDelivery', label : `${t('_Deli')}(+)`,  display: true, type: 'numeric', width: 10},   
                              {id:'quantityBilling', label : `${t('_Bil')}(-)`,  display: true, type: 'numeric', width: 9},     
                              {id:'quantityTransfertVariation', label : `${t('_Trsf')}`,  display: true, type: 'numeric', width: 9},  
                              {id:'quantityMovementVariation', label : `${t('_Mvt')}`,  display: true, type: 'numeric', width: 8}, 
                                           
                              {id:'quantityInventoryVariation', label : `${t('_Inv')}`,  display: true, type: 'numeric', width: 9},                  
                              {id:'quantity', label : t('Qty'),  display: true, type: 'numeric', width: 10}, 

                            ]} 
                            title={`${t(`Articles in store`)} ...`} objKey='storeName' 
                            stateSelected={undefined} 
                            onRowSelected={undefined} rowCheckedMode='single'
                            onRowCheckedSelectChange={undefined} order='asc' orderBy='storeName'
                            onRowDoubleClick={undefined} 
                            rowActionIcon={undefined}
                            toolbarActions={[
                              // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                            ]}
                          />
                        </Box>                   
                      </Stack>
                    </Grid>
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

