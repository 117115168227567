import React, {FC, PropsWithChildren, useRef, useState, useEffect} from 'react';

import * as XLSX from 'xlsx';

// import library.
import { useFieldArray, useForm, useFormContext, Controller, FormProvider, FieldArrayWithId, ArrayPath } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import { useSnackbar } from 'notistack';

import NumberFormat from 'react-number-format';
// mui ...
import {Avatar, Button, Grid, Typography, Link, Box, Paper, TextField, 
    MenuItem, IconButton, Checkbox, FormControlLabel, Stack } from '@mui/material';

    import InputAdornment from '@mui/material/InputAdornment';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import BoltIcon from '@mui/icons-material/Bolt';

import { ThreeDots, ThreeCircles  } from 'react-loader-spinner';

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import entityService  from 'features/services/Entity';

import EnhancedTable, {_DataType, HeadCell, RowCheckedMode} from './EnhancedTable';
import { FaSearch } from 'react-icons/fa';
import {SiMicrosoftexcel} from 'react-icons/si';
import { MdPictureAsPdf } from 'react-icons/md';

import { pink } from '@mui/material/colors';

import { flexBetweenCenter, fullWidthFlex, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import useEnumerationService from 'features/configuration/services/Enumeration';
import useBusinessApplicationService, { useBasicFilterApplicationQuery } from 'features/setup/services/ApplicationQuery';

import { IBusinessApplication, IApplicationQuery, 
    IApplicationQueryParameter, ParameterDataType, toBaseType, toDataType, defaultValue, IApplicationQueryReport } from 'features/setup/models/ApplicationQuery';
import ArrayField, { BaseType, } from './ArrayField';
import { IFeatureParameter } from 'library/interface';
import { isFalsy } from 'utility-types';
import { GrDocumentDownload, GrDocumentPdf } from 'react-icons/gr';

import BasicButtonList from './BasicButtonList';
import { hubConnectionIdAtom, colorsAtom } from 'library/store';


export interface BasicImportFormProps<T extends {}> {

    stateItems : [T[], React.Dispatch<React.SetStateAction<T[]>>],
    columns: (keyof T)[],
    defaultItem: T
}


export default function BasicImportForm<T extends {}>(props: BasicImportFormProps<T>)  {
    
    const { stateItems, columns, defaultItem } = props;

    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    //const [filterElements, setFilterElements] = filterElementsState;

    const [hubConnectionId, setHubConnectionId] = useRecoilState(hubConnectionIdAtom);
   
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { getApplicationQueryParameters, generateApplicationQueryXlsx, generateApplicationQueryPdf } = useBusinessApplicationService();
    const {getAsOptions } = useEnumerationService();

    const {retrieveEntity} = entityService();

    const [Colors, setColors] = useRecoilState(colorsAtom);

    const [openColumsList, setOpenColumsList] = useState<boolean>(false);
    const handleDisplayExcelColumns = (event: any) => {
        setOpenColumsList(true);
    }
        
    const getType = (col: keyof T) : _DataType => {
        if(defaultItem[col] instanceof Date) 
            return 'date';
        else if(typeof defaultItem[col] === 'number')
            return 'numeric';
        else if(typeof defaultItem[col] === 'boolean')
            return 'boolean';

        return 'string';
    }

    const [fileName, setFileName] = useState<string>('');
    const [openReportChoice, setOpenReportChoice] = useState<boolean>(false);
    const handleOpenReports = () => {
        setOpenReportChoice(true);
    }

    function openFileDialog(  ) {
        (document as any).getElementById("file-upload-import").click();        
      }
   
      const setFile = (_event: any) => {
        let f = _event.target.files![0];

        var reader = new FileReader();
        
        reader.onload = function (event: ProgressEvent<FileReader> ) {
            try {
                var binaryString = event.target?.result as string; //  reader.result as string; // event
                const workbook = XLSX.read(binaryString, {type: 'binary'});

                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                setFileName(f.name);

                const jsonData: T[] = XLSX.utils.sheet_to_json(worksheet);   
                stateItems[1](jsonData as unknown as T[]);             
            }catch (err) {
                console.error(err);
            }
          
        };
  
        reader.onerror = function () {
          console.log("File load failed");
        };
    
        reader.readAsArrayBuffer(f);
      }
        
    return ( 
    <>
        
        <Grid container component="main" sx={{  width: '100%' }} alignItems="flex-start">
            <Grid item xs={12} sm={12} md={12} component={Paper} square>
                <Stack flexDirection='column' sx={{ width: '100%' }} > 
                    <Box sx={{ mt: 1, width: '100%', pt: 0 }} >
                        <Stack flexDirection='row'>
                            <Box sx={{ mt: 1, width: '50%', pt: 0 }} >                                                                
                                <Button onClick={ () => openFileDialog()} key={`lg - ${fileName} `} id={`lg - ${fileName}`}>
                                    <input type="file" id="file-upload-import" style={{ display: "none" }} onChange={ (_event) => setFile(_event) }/>
                                    {isFalsy(fileName) ? `${t('Click to toad file')} ...`: `${fileName} -> ${t('Click to change')}`}
                                </Button>                             
                            </Box>
                            <Box sx={{ ...justifyCenter, mt: 1, width: '50%', pt: 0 }} >
                                <Button>
                                  <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                    {`${t('Columns of Excel file')}`}
                                  </Typography>
                                  <Box sx={{ ...justifyCenter, ml: 1 }} >                                                     
                                    <GrDocumentDownload color={Colors.menuIcon} size={24} onClick={handleDisplayExcelColumns} />
                                  </Box>  
                                </Button>                                
                              </Box>
                        </Stack>  
                    </Box>                     
                    
                    { openColumsList && <FormDialog open={openColumsList} maxWidth='md' height='30vh'
                        okText={t('OK')} cancelText='' title={'... ==> '} onCancel={()=> {}} 
                        onClose={()=> {setOpenColumsList(false);}} onOk={()=> {setOpenColumsList(false);}}  >
                            <EnhancedTable<T> rows={[{...defaultItem}]} 
                                headCells={ columns.map(x => ({id: x, label: x, display: true, type: getType(x)} as HeadCell<T>)) } 
                                title={t(`Template`)} objKey={columns[0]}
                                //stateSelected={[filteredSchoolYearTemplateSubjects, setFilteredSchoolYearTemplateSubjects]} 
                                onRowSelected={undefined} rowCheckedMode='single'
                                onRowCheckedSelectChange={undefined} order='desc' orderBy={columns[0]}
                                onRowDoubleClick={undefined} 
                                rowActionIcon={undefined}
                                toolbarActions={[]}
                                />
                        </FormDialog>  }
                </Stack>  
            </Grid>
            <Grid item xs={12} component={Paper} square>                
                <Box sx={{ mt: 1, width: '100%' }} >                      
                        
                </Box>
            </Grid>  
        </Grid>
        
    </>
    ); 
}


