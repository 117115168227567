
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import TokenIcon from '@mui/icons-material/Token';

import { IApplicationSetup, defaultApplicationSetup } from './models/ApplicationSetup';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useApplicationSetupService, { useBasicFilterApplicationSetup } from './services/ApplicationSetup';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import {  IconButton, InputAdornment, MenuItem, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration from 'features/configuration/models/Enumeration';

import EntityExpression, {IEntityExpressionProps, defaultEntityExpressionProps} from 'components/ui/EntityExpression';


import { defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';

import md5 from 'md5';
import { typographyGroupBoxStyling } from 'themes/commonStyles';

export const ApplicationSetupWhatsAppForm: FC<IApplicationSetup> = (props: IApplicationSetup = defaultApplicationSetup) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 999 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const { createApplicationSetup, updateApplicationSetup } = useApplicationSetupService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterApplicationSetup = useBasicFilterApplicationSetup( 
    (event: React.MouseEvent<unknown>, row: IApplicationSetup) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openEntityExpression, setOpenEntityExpression] = useState(false);
  const [currentExpression, setCurrentExpression] = useState(''); 
  const [currentEntityName, setCurrentEntityName] = useState(''); 
  const [expressionField, setExpressionField] = useState<keyof IApplicationSetup>('certificateDocumentExpression');      

  
  const methods = useForm<IApplicationSetup>({defaultValues:defaultApplicationSetup});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchWebhookToken = watch('webhookToken');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IApplicationSetup>,Error,IApplicationSetup>(
      _id>0?updateApplicationSetup:createApplicationSetup, {   
        onSuccess: (data: IResult<IApplicationSetup>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['ApplicationSetup',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IApplicationSetup>(['ApplicationSetup', _id], () => retrieveEntity('ApplicationSetup',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const handleClickOpenCertificateSmsExpression = (event: any) => {
        const {certificateSmsExpression} = getValues();
        
        setCurrentEntityName('RequestDataItem');
        setExpressionField('certificateSmsExpression');    
        setCurrentExpression(certificateSmsExpression);    
        setOpenEntityExpression(true);
      }

      const handleClickOpenCertificateDocumentExpression = (event: any) => {
        const {certificateDocumentExpression} = getValues();
        
        setCurrentEntityName('RequestDataItem');
        setExpressionField('certificateDocumentExpression');    
        setCurrentExpression(certificateDocumentExpression);    
        setOpenEntityExpression(true);
      }

      const handleClickOpenCertificateImageExpression = (event: any) => {  
        const {certificateImageExpression} = getValues();
        
        setCurrentEntityName('RequestDataItem');
        setExpressionField('certificateImageExpression');  
        setCurrentExpression(certificateImageExpression);           
        setOpenEntityExpression(true);
      }

      const handleClickOpenCertificateQrCodeExpression = (event: any) => {  
        const {certificateQrCodeExpression} = getValues();
        
        setCurrentEntityName('RequestDataItem');
        setExpressionField('certificateQrCodeExpression');  
        setCurrentExpression(certificateQrCodeExpression);           
        setOpenEntityExpression(true);
      }

      const handleClickOkExpression = async () => {

        const checkExpression = await checkEntityExpressionSyntax('RequestDataItem', currentExpression);
        if(!checkExpression){
          enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        const {syntaxOk, syntaxError, returnType} = checkExpression;
        if(!syntaxOk) {
          enqueueSnackbar( syntaxError , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        if( returnType !== 'string' ) {
          enqueueSnackbar( t('The result of expression must be string') , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        setValue(expressionField, currentExpression);
        setOpenEntityExpression(false);
      }

      const rand = () => {
        return Math.random().toString(36).substring(2);
      };
  
      const generateToken = (len: number) : string => {
        return (rand() + rand() + rand() + rand()).substring(0,len);
      };
  
  
      const handleClickGenerateWebhookToken = (event: any) => {
        setValue('webhookToken', generateToken(26));
      }

      useEffect( () => {        
        const {webhookToken} = getValues();
        setValue('webhookUrl', `${'_APP_'}/api/misc/hookData/v1/${webhookToken}/receive-hookData` )
      }, [watchWebhookToken]);  
      

      useEffect( () => {        
        setCurrentFormNameAtom(`${t('Application Setup')} - WhatsApp`);
        setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
               
          // if(_id > 0)
          //   retrieveData('ApplicationSetup',_id, refetch);

            retrieveData('ApplicationSetup',999, refetch);  
          }, [/*_id*/] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            console.log(_data);
            _setId(_data.id);
            reset(_data);
        }
        }, [_data]);
    
      
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        
        if(!checkEntitySaveAuthorization('ApplicationSetup', _id)){
          setIsSaveLoading(false);
             return;
        }
         

          const data = getValues(); 

          const {whatsAppPassword} = data;

          if(data.companyName.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
          mutate({...data });          
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('ApplicationSetup', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                                                 
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />                                
                                
                            </Box>
                            <Box sx={{ mt: 2.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('WhatsApp parameters')}, (${t('Send/Receive')} )`}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} > 
                                <TextField sx={{width:'calc(100% - 8px)'}} id="whatsAppBaseUrl" label={('WhatsApp: Base url')} {...register('whatsAppBaseUrl')} inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'}}} />                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >                                  
                                <TextField sx={{width:'calc(50% - 8px)'}} id="whatsAppIdentityToken" label={t('WhatsApp: Identity token')} {...register('whatsAppIdentityToken')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                                            
                                <TextField sx={{width:'calc(25% - 8px)'}} id="whatsAppLogin" label={t('WhatsApp: Login')} {...register('whatsAppLogin')} inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'}}} />
                                <TextField sx={{width:'calc(25% - 8px)'}} id="whatsAppPasswordMd5" label={t('WhatsApp: Password')} {...register('whatsAppPassword')} type="password" inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'}}} />                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} > 
                                <TextField sx={{width:'calc(100% - 8px)'}} id="webhookUrl" label={('WhatsApp: Webhook')} 
                                  {...register('webhookUrl')} inputProps={ {autoComplete: 'new-password', style: {textTransform: 'none'}}}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickGenerateWebhookToken}>
                                          <TokenIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />                                
                            </Box>
                            
                        </Stack>                        
                    </Grid>  
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                               
                                { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                    <EntityExpression entityName={currentEntityName} properties={[]} 
                                      expression={currentExpression} setExpression={setCurrentExpression} />
                                  </FormDialog>  }                               
                            </Box>
                            
                        </Stack>                        
                    </Grid>                  
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

