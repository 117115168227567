

export interface ICertificateTemplate {
    id: number,
    
    name: string,
    description: string,

    reportId: number,    
  }


  export const defaultCertificateTemplate : ICertificateTemplate = {
    id: 0,
    
    name: '',
    description: '',

    reportId: 0,    
  }

  export interface ICertificateTemplateSearch {
    
    name: string,
    description: string
  }