

export interface IHookData {
    id: number,

    hookDataTemplateId: number,
    from: string,
    body: string,

    isTreated: boolean,   
     
  }


  export const defaultHookData : IHookData = {
    id: 0,
    
    hookDataTemplateId: 0,
    from: '',
    body: '',

    isTreated: false,    
  }

  export interface IHookDataSearch {
    
    from: string,
    body: string
  }