
export interface IEntityActionReport {

    id: number,
    
    name: string,
    description: string,
    isActive: boolean,
    entityName: string,
    entityStatus: string,
    reportId: number,
    transmissionMode: string,
    transmissionConditionExpression: string,
    hasTransmissionExpiration: boolean,
    transmissionExpirationInMinute: number;
    transmissionDelayInMinute: number,
    recipientExpression: string,
    recipientExpressionCC: string,
    recipientExpressionBcc: string,
    reportLanguageExpression: string,
    retentionMode: string,
    retentionUnit: string,
    retentionValue: number;

    startDate: Date,
    hasEndDate: boolean,
    endDate?: Date,

    entityDescription: string,
    reportName: string,
  }

  
  export const defaultEntityActionReport : IEntityActionReport = {
    id: 0,
    
    name: '',
    description: '',
    isActive: false,
    entityName: '',
    entityStatus: '',
    reportId: 0,
    transmissionMode: '',
    transmissionConditionExpression: '',
    hasTransmissionExpiration: false,
    transmissionExpirationInMinute: 0,
    transmissionDelayInMinute: 0,
    recipientExpression: '',
    recipientExpressionCC: '',
    recipientExpressionBcc: '',
    reportLanguageExpression: '',
    retentionMode: '',
    retentionUnit: '',
    retentionValue: 0,

    startDate: new Date(),
    hasEndDate: false,
    endDate: new Date(),

    entityDescription: '',
    reportName: '',
  }

  export interface IEntityActionReportSearch {
    
    name: string,
    description: string,       
    entityName: string,    
  }
