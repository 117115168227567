
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom } from 'library/store';

import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { IconButton, InputAdornment, MenuItem } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';


import { IRegistrationSheet, defaultRegistrationSheet } from '../models/Registration';
import { IField, ArrayFieldProps } from 'components/ui/ArrayField';

export default function ArrayFieldRegistrationSheet<T extends IField> (props: {params: T[]} )  {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer } = entityService();

  //const { createRegistrationSheet, updateRegistrationSheet } = useRegistrationService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  // const basicFilterRegistrationSheet = useBasicFilterRegistrationSheet( 
  //   (event: React.MouseEvent<unknown>, row: IRegistrationSheet) => {
  //       setIsSearchBoxShow(false);
  //       _setId(row.id);
  //     }
  // );

  const {params} = props;
  console.log(params);

  const {  control  } = useFormContext();

  //const watchType = watch('type');

  const queryClient = useQueryClient();
  
      const [dataType,] = useState( [       
        {name: 'text', text: t('Text'), value: ''}, 
        {name: 'integer', text: t('Interger number'), value: ''},     
        {name: 'decimal', text: t('Decimal number'), value: ''},     
        {name: 'float', text: t('Float number'), value: ''},     
        {name: 'date', text: t('Date'), value: ''},     
        {name: 'boolean', text: t('Boolean'), value: ''},     
        {name: 'time', text: t('Time'), value: ''},     
        {name: 'enumeration', text: t('Enumeration'), value: ''},     
        {name: 'entity', text: t('Entity'), value: ''},     
      ]);    

    
      useEffect( () => {        
        //setCurrentFormNameAtom(t('Extension type'));
        //setCurrentBasicTextFilterProps(basicFilterRegistrationSheet);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        // useEffect( () => {
        //     // setCurrentFormName(t('Billing'));        
            
        //     if(_id > 0)
        //       retrieveData('RegistrationSheet',_id, refetch);  
        //   }, [_id] );
    
    
        // useEffect( () => {
            
        // if(_data && _data.id > 0) {
        //     reset(_data);
        // }
        // }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        // _setId(0);      
        // reset(defaultRegistrationSheet);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
          
      
          
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('RegistrationSheet', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);       
    
    
    }

  return (
    
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' name='btnSaveRegistrationSheet' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                {/* <Controller
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:`calc(${w}% - 8px)`}}
                                    label={String(param[labelKey])} >
                                  {param.options && param.options.map( 
                                    (ei,ix) => <MenuItem key={ei.value} value={ei.value}>{ei.name}</MenuItem> )
                                  }
                                  </TextField>
                                )}
                                name={`${paramsName}.${idx}.${String(valueKey)}`} //defaultValue={param[valueKey]}
                                control={control}
                              /> */}
                                <Controller name='params.0.value' control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="type"
                                      label={t('Type')} inputProps={ {readOnly: false}}>
                                      {dataType.map( 
                                        (x,idx) => <MenuItem key={x.name} value={x.name}>{x.text}</MenuItem> )
                                      }
                                    </TextField>
                                    )}
                                  />
                                
                                      
                                
                            </Box>
                        </Stack>                        
                    </Grid>                    
                    
                </Grid>
            </Box>
         
  )
}

