export interface IFamilyProduct {
    id: number,
    
    code: string,
    name: string,    
    description: string,
    tags: string,

    familyTypeCode: string,
    defaultProductId: number,
    
    publishOnWebsite: boolean,

    fileName: string,
    base64Picture: string,
  }


  
  export const defaultFamilyProduct : IFamilyProduct = {
    id: 0,
    
    code: '',
    name: '',    
    description: '',
    tags: '',

    familyTypeCode: '',
    defaultProductId: 0,
    
    publishOnWebsite: false,
    
    fileName: '',
    base64Picture: '',
  }

  

  export interface IFamilyProductSearch {
 
    code: string,
    name: string,
    description: string,    
  }