

export interface IHookDataTemplate {
    id: number,
    
    name: string,
    description: string,

    reportId: number,   

    recipientFilterQueryId: number,
    singleRecipient: boolean,
    
    entityFilterQueryId: number,
    singleEntity: boolean,

    type: string,

    reportName: string,   

    recipientEntityControlExpression: string,
    entityIdExpression: string,
    reportLanguageExpression: string,

    recipientBusinessApplicationId: number,
    entityBusinessApplicationId: number,

    recipientFilterQueryName: string,
    entityFilterQueryName: string,

    retentionMode: string,
    retentionUnit: string,
    retentionValue: number,

    hookDataTemplateModels: IHookDataTemplateModel[],

    //entityBusinessApplicationQueryParameters: {id: number, parameterName: string, parameterDataType: string}[]
  }

  export interface IHookDataTemplateModel {
    id: number,
    hookDataTemplateId: number,
    name: string,
    bodyExpressionCheck: string,
    priority: number,

    hookDataTemplateModelEntityParameters: IHookDataTemplateModelEntityParameter[]
  }

  export interface IHookDataTemplateModelEntityParameter {
    id: number,
    hookDataTemplateModelId: number,
    businessApplicationQueryParameterId: number,
    parameterValueExpression: string,

    parameterName: string,
    parameterDataType: string
  }

  export const defaultHookDataTemplate : IHookDataTemplate = {
    id: 0,
    
    name: '',
    description: '',

    reportId: 0,    

    recipientFilterQueryId: 0,
    singleRecipient: true,
    
    entityFilterQueryId: 0,
    singleEntity: true,

    type: 'customer',

    reportName: '',   

    recipientEntityControlExpression: '',
    entityIdExpression: '',
    reportLanguageExpression: '',

    recipientBusinessApplicationId: 0,
    entityBusinessApplicationId: 0,

    recipientFilterQueryName: '',
    entityFilterQueryName: '',

    retentionMode: 'NONE',
    retentionUnit: '',
    retentionValue: 0,

    hookDataTemplateModels: [],

    //entityBusinessApplicationQueryParameters: []
  }

  export interface IHookDataTemplateSearch {
    
    name: string,
    description: string
  }