

import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useEffect, useState } from 'react';
import {IFamilyProduct, IFamilyProductSearch } from "../models/FamilyProduct";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';
import { isNumber, toNumber } from 'lodash';
import { useQuery } from 'react-query';
import { ISchoolYear } from 'features/setup/models/SchoolYear';
import { EventInput } from '@fullcalendar/core';


const _ = () => {

    const axios = useAxios(); 

    //const {schoolYears} = useRecoilValue(currentUserSessionAtom);    

    const createFamilyProduct = async (familyProduct: IFamilyProduct)  =>       
        await (await axios.post('/api/setup/familyProduct/create', familyProduct)).data;       
        
    const updateFamilyProduct = async (familyProduct: IFamilyProduct)  =>       
        await (await axios.post('/api/setup/familyProduct/update', familyProduct)).data; 
    
    const getFamilyProduct = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/familyProduct/get-familyProduct/${id}`));
      return await data;
    }
   
    const getFamilyProducts = async (criteria: IFamilyProductSearch, pagination?: IPagination) : Promise<IFamilyProduct[]> => {

      const {code, name, description } = criteria;const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;
      
      const {data} = (await axios.get(`/api/setup/familyProduct/get-familyProducts?code=${code}&name=${name}&description=${description}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

          
      
    return {    
      createFamilyProduct,
      updateFamilyProduct,
      getFamilyProduct,
      getFamilyProducts,

    } 
}

export default _;

export interface IFilterFamilyProductOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IFamilyProduct[], React.Dispatch<React.SetStateAction<IFamilyProduct[]>>],
}

const defaultFilterFamilyProductOption: IFilterFamilyProductOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterFamilyProduct = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IFamilyProduct) => void,
                    filterOption?: IFilterFamilyProductOption  ) => {

  const { getFamilyProducts } = _();

  const { t, i18n } = useTranslation();   
  const {language: lg, applicationSetup } = useRecoilValue(currentUserSessionAtom);
  
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterFamilyProductOption;

  
  const [headFamilyProductCells, setHeadFamilyProductCells]  = useState<HeadCell<IFamilyProduct>[]>([
    {id:'id', label : t('Id'),  display: false, type: 'numeric', width: 5 },    
    {id:'code', label : t('Code'),  display: true, type: 'string', width: 15},
    
    {id:'name', label : t('Name'),  display: true, type: 'string', width: 40},
    {id:'description', label : t('Description'),  display: true, type: 'string', width: 45},
     
    
  ]); 
  
  const [filterElements,setFilterElements] = useState<ITextFilterElement[]>([         
        
    {name: 'code', text: t('Code'), value: ''},
    {name: 'name', text: t('Name'), value: ''},
    {name: 'description', text: t('Description'), value: ''},            
  ]);
  
  const [filteredFamilyProducts, ] = useState<IFamilyProduct[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IFamilyProduct[]> => {
        
    const code = filterElements.find( elt => elt.name === 'code')?.value || '';
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';  
    
    const arr = await getFamilyProducts( {code, name,description}, pagination );
   
    return arr;
  }

  const objKey: keyof IFamilyProduct = 'id';

  return {
    title: t('Family product'), headCells: headFamilyProductCells, objKey,
    filterElements, rows: filteredFamilyProducts, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
