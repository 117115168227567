import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom } from 'library/store';
import useTarificationService, { useBasicFilterTarification } from './services/Tarification';
import useMatrixService, { useBasicFilterMatrix } from './services/Matrix';

import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { IconButton, InputAdornment, MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import NumberFormat from 'react-number-format';

import { ITarification, defaultTarification, ITarificationMatrix } from './models/Tarification';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration from 'features/configuration/models/Enumeration';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { IMatrix } from './models/Matrix';

export const TarificationForm: FC<ITarification> = (props: ITarification = defaultTarification) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const { createTarification, updateTarification } = useTarificationService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);

  

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterTarification = useBasicFilterTarification( 
    (event: React.MouseEvent<unknown>, row: ITarification) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}
  
  const [openMatrixFilter, setOpenMatrixFilter] = useState(false);
  const basicFilterMatrix = useBasicFilterMatrix( 
      (event: React.MouseEvent<unknown>, row: IMatrix) => {
          const {id ,name, description, usage} = row;

          if(getValues().tarificationMatrixs.some( x => x.matrixId === id)) return;       
            
                (refAppendTarificationMatrixs.current??emptyFunc)({ matrixId: id, tarificationId: _id,
                    matrixName: name, matrixDescription: description, matrixUsage: usage });

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenMatrixFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const methods = useForm<ITarification>({defaultValues:defaultTarification});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchUsage = watch('usage');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ITarification>,Error,ITarification>(
      _id>0?updateTarification:createTarification, {   
        onSuccess: (data: IResult<ITarification>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          //reset(data.data);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Tarification',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        }
      });

    const {data: _data, refetch} = useQuery<ITarification>(['Tarification', _id], () => retrieveEntity('Tarification',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const [headTarificationMatrixCells, setHeadTarificationMatrixCells]  = useState<HeadCell<ITarificationMatrix>[]>([      
        {id:'matrixId', label : t('Id'),  display: true, type: 'string', },
        {id:'matrixName', label : t('Name'),  display: true, type: 'string', },
        {id:'matrixDescription', label : t('Description'),  display: true, type: 'string', },
        {id:'alias', label : t('Alias'),  display: true, type: 'string', },
      ]);
    const refAppendTarificationMatrixs = useRef<(value: Partial<FieldArray<ITarification>> | Partial<FieldArray<ITarification>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateTarificationMatrix = useRef<(index: number,value: Partial<FieldArray<ITarification>> ) => void>(null);
    const refRemoveTarificationMatrix = useRef<(index: number ) => void>(null);
      
    const handleAddMatrixs = (event: any) => {
      setOpenMatrixFilter(true);
    }   

    const handleOkFilterMatrix = () => {
      setOpenMatrixFilter(false);
    }

    const tarificationMatrixRowActionIcon = ( tarificationMatrix: ITarificationMatrix) : ActionIconTableRow<ITarification,ITarificationMatrix> => {
  
      const res: ActionIconTableRow<ITarification,ITarificationMatrix> = {
        toolTip: 'viewDetails',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: ITarificationMatrix) => {
          
           (refRemoveTarificationMatrix.current??emptyFunc)(index);            
        }
      }
      return res;
  }

  const handleChangeUsage = ( event: React.MouseEvent<HTMLElement>, newUsage: string) => { 
    if (newUsage === null) 
      return;

    setValue("usage", newUsage);      
  }

      useEffect( () => {        
        setCurrentFormNameAtom(t('Tarification'));
        setCurrentBasicTextFilterProps(basicFilterTarification);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Tarification',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultTarification);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {    
        if(!checkEntitySaveAuthorization('Tarification', _id))
        return;

          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    //setIsSaveLoading(false);
                    return;
            }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Tarification', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(5% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                
                                <TextField sx={{width:'calc(40% - 8px)'}} id="name" label={t('Name')} {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <TextField sx={{width:'calc(55% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ToggleButtonGroup size="small"
                                  color="primary"
                                  value={watchUsage}
                                  exclusive
                                  onChange={ (event, newUsage) => handleChangeUsage(event, newUsage)}
                                  aria-label="Small sizes" fullWidth
                                >
                                  <ToggleButton value="coverage">{t('Coverage')}</ToggleButton>
                                  <ToggleButton value="accessories">{t('Accessories')} </ToggleButton>
                                  <ToggleButton value="coverage-tax">{`${t('Coverage')} - ${t('Tax')}`}</ToggleButton>
                                  <ToggleButton value="accessories-tax">{`${t('Accessories')} - ${t('Tax')}`}</ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </Stack>                        
                    </Grid>  
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ArrayFieldTableEx<ITarification,ITarificationMatrix,'id'> 
                                      mainObject={getValues()} fieldKey='id' 
                                      headCells={headTarificationMatrixCells} rowsPathName='tarificationMatrixs' 
                                      title={t('Matrixs')} rowActionIcon={tarificationMatrixRowActionIcon}  
                                      //onRowSelected={handleRoleEntitySelected}
                                                          
                                      refAppend={refAppendTarificationMatrixs as MutableRefObject<(value: Partial<FieldArray<ITarification>> | Partial<FieldArray<ITarification>>[], options?: FieldArrayMethodProps) => void>}
                                      refUpdate={refUpdateTarificationMatrix as MutableRefObject<(index: number,value: Partial<FieldArray<ITarification>>) => void>}
                                      refRemove={refRemoveTarificationMatrix as MutableRefObject<(index: number) => void>}

                                      //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                      //displayMore={undefined}
                                      toolbarActions={[
                                      { toolTip: `${t('Add')}...`, onClickIcon: handleAddMatrixs ,icon: AddCircleIcon,  },
                                      
                                      ]}
                                      canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                                  />
                                  { openMatrixFilter && <FormDialog open={openMatrixFilter} maxWidth='md'
                                      okText={t('OK')} cancelText='' title={t('Matrix filter')} onCancel={()=> {}} 
                                      onClose={()=> {setOpenMatrixFilter(false);}} onOk={handleOkFilterMatrix}  >
                                          <BasicTextFilterForm<IMatrix> {...basicFilterMatrix } />
                                  </FormDialog> }
                            </Box>                  
                        </Stack>
                    </Grid>                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

