
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useGroupBillingService, { useBasicFilterGroupBilling } from './services/GroupBilling';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, FormHelperText, Link } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IGroupBilling, IGroupBillingBilling, defaultGroupBilling } from './models/GroupBilling';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration, useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';


import IEnumeration,  { IEnumerationItem, Enum_GROUP_BILLING_PURPOSE, Enum_CURRENCY } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { useBasicFilterBilling } from './services/Billing';
import { IBilling } from './models/Billing';
import { DatePicker } from '@mui/x-date-pickers';
import { DialogEnumerationItemForm } from 'features/configuration/DialogEnumerationItemForm';

export const GroupBillingForm: FC<IGroupBilling> = (props: IGroupBilling = defaultGroupBilling) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();


  const { createGroupBilling, updateGroupBilling } = useGroupBillingService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterGroupBilling = useBasicFilterGroupBilling( 
    (event: React.MouseEvent<unknown>, row: IGroupBilling) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
    onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();

  const [openBillingFilter, setOpenBillingFilter] = useState(false);
  const basicFilterBilling = useBasicFilterBilling( 
      (event: React.MouseEvent<unknown>, row: IBilling) => {
          const {id, customerName, description, showroomName, amount, currencyCode } = row;

          (refAppendGroupBillingBillings.current??emptyFunc)({id: 0, billingId: id, 
              customerName, showroomName, amount, currencyCode  });
                           
          setOpenBillingFilter(false);
      }
  );
  

  
  const methods = useForm<IGroupBilling>({defaultValues:defaultGroupBilling});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchGroupingPurpose = watch('groupingPurpose');
  // const watchBase64Picture = watch('base64Picture');
   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IGroupBilling>,Error,IGroupBilling>(
      _id>0?updateGroupBilling:createGroupBilling, {   
        onSuccess: (data: IResult<IGroupBilling>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Group billing')} - # ${data.data.id} # -`);
          //queryClient.invalidateQueries(['GroupBilling',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IGroupBilling>(['GroupBilling', _id], () => retrieveEntity('GroupBilling',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems, refetch: refetchEnumerationItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'GroupBilling'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_GROUP_BILLING_PURPOSE, Enum_CURRENCY ] ));

    const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);

    const handleTabCoverageChange = (event: React.SyntheticEvent, newValue: number) => {  
       setValueTabCoverage(newValue);       
    };
      
    const getGroupBillingBillingCurrency = (row: IGroupBillingBilling, cellId: keyof IGroupBillingBilling, 
      opts: {value: string|number, name: string}[]) : {value: string|number, name: string}[] => {        
      
        return getAsOptions(refEnumItems.current ?? [],Enum_CURRENCY);
    }

    const cellEditableGroupBillingBilling = (row: IGroupBillingBilling, cellId: keyof IGroupBillingBilling) => {
      return true;
    }

    const [headGroupBillingBillingCells, setHeadGroupBillingBillingCells]  = useState<HeadCell<IGroupBillingBilling>[]>([      
      //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
      {id:'customerName', label : t('Customer'),  display: true, type: 'string', width: 35,    },
      {id:'showroomName', label : `${t('Showroom')}`,  display: true, type: 'string', width: 15,  },
      {id:'currencyCode', label : `${t('Currency')}`,  display: true, type: 'string', width: 25, getOptions: getGroupBillingBillingCurrency  },

      {id:'amount', label : `${t('Amount')}`,  display: true, type: 'numeric', width: 25, decimalScale: 3 },
      
    ]);

    const refAppendGroupBillingBillings = useRef<(value: Partial<FieldArray<IGroupBilling>> | Partial<FieldArray<IGroupBilling>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateGroupBillingBilling = useRef<(index: number,value: Partial<FieldArray<IGroupBilling>> ) => void>(null);
    const refRemoveGroupBillingBilling = useRef<(index: number ) => void>(null);
      
    const handleAddGroupBillingBillings = (event: any) => {
      setOpenBillingFilter(true);
    }

    const sharingItemRowActionIcon = ( tarificationMatrix: IGroupBillingBilling) : ActionIconTableRow<IGroupBilling,IGroupBillingBilling> => {
  
      const res: ActionIconTableRow<IGroupBilling,IGroupBillingBilling> = {
        toolTip: 'viewDetails',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: IGroupBillingBilling) => {
          
           (refRemoveGroupBillingBilling.current??emptyFunc)(index);            
        }
      }
      return res;
  }

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems])


      useEffect( () => {        
        setCurrentFormNameAtom(t('Group billing'));
        setCurrentBasicTextFilterProps(basicFilterGroupBilling);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('Group billing')} - # ${_id} # -`: t('Group billing') );
            if(_id > 0)
              retrieveData('GroupBilling',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultGroupBilling);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        
        if(!checkEntitySaveAuthorization('GroupBilling', _id)) {
          setIsSaveLoading(false);
             return;
        }
        const data = getValues(); 

        if(data.groupingPurpose.trim() === '' || data.reference.trim() === '') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
        }
        
        const billings = data.groupBillingBillings.map(x => x.billingId);
        if( Array.from(new Set(billings)).length !== data.groupBillingBillings.length) {
          enqueueSnackbar( ` ${t('Invalid data !!!')} : ${t('Billing')}`, { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }
          
        
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('GroupBilling', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                                
                                                              
                                <Controller name='groupingPurpose' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="status"
                                      label={t('Purpose')} inputProps={ {readOnly: false}} 
                                      helperText={ 
                                        <FormHelperText>                                    
                                          { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_GROUP_BILLING_PURPOSE, 
                                                                t('Group billing purpose'), t('Group billing purpose'))}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                            {t('Add')}
                                          </Link> }
                                          { (canUpdateEnumerationItem && !isFalsy(watchGroupingPurpose) ) && <Link href="#" onClick={(event) => {
                                                 onUpdateItemClick( Enum_GROUP_BILLING_PURPOSE, t('Group billing purpose'), t('Group billing purpose'),
                                                        refEnumItems.current?.find(x => x.enumerationCode === Enum_GROUP_BILLING_PURPOSE && x.code === watchGroupingPurpose)
                                                      ); }}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                            {t('Update')}
                                          </Link> }
                                        </FormHelperText>
                                      }>
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_GROUP_BILLING_PURPOSE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                                />

                                <Controller control={control}
                                  name='issueDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Issue date')} 
                                      onChange={onChange} disableOpenPicker readOnly                    
                                      value={new Date(value)}
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                      slotProps={{ textField: { sx: {width:'calc(15% - 8px)'}  }} }
                                    /> )}
                                />                                 
                            </Box>   
                            
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box key={`${'watchFileName'} - key`} sx={{ mt: 1, width: '100%' }} >  
                          <TextField sx={{width:'calc(100% - 8px)'}} id="id" label={t('Reference')} 
                                    {...register('reference')} inputProps={ {readOnly: false}} />
                        </Box>                        
                      </Stack>
                    </Grid>    
                    <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box key={`${'watchFileName'} - key`} sx={{ mt: 1, width: '100%' }} >  
                          <ArrayFieldTableEx<IGroupBilling,IGroupBillingBilling,'id'> 
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headGroupBillingBillingCells} rowsPathName='groupBillingBillings' 
                              title={t('Billings')} rowActionIcon={sharingItemRowActionIcon}  
                              //onRowSelected={handleRoleEntitySelected}
                                                  
                              refAppend={refAppendGroupBillingBillings as MutableRefObject<(value: Partial<FieldArray<IGroupBilling>> | Partial<FieldArray<IGroupBilling>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdateGroupBillingBilling as MutableRefObject<(index: number,value: Partial<FieldArray<IGroupBilling>>) => void>}
                              refRemove={refRemoveGroupBillingBilling as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={[
                              { toolTip: `${t('Add')}...`, onClickIcon: handleAddGroupBillingBillings ,icon: AddCircleIcon,  },
                              
                              ]}
                              canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                          />

                          { openBillingFilter && <FormDialog open={openBillingFilter} maxWidth='md'
                                okText='' cancelText='' title={t('Billing')} onCancel={()=> {}} 
                                onClose={()=> {setOpenBillingFilter(false);}} onOk={()=> {setOpenBillingFilter(false);}}  >
                                    <BasicTextFilterForm<IBilling> {...basicFilterBilling } />
                            </FormDialog> }  
                        </Box>   

                        { displayEnumerationItemDialog && <DialogEnumerationItemForm 
                              {...{open: displayEnumerationItemDialog, 
                                    title: '', 
                                    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
                                    afterSave: async () => {await refetchEnumerationItems()}}} 
                              />
                          }                       
                      </Stack>
                    </Grid> 
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

