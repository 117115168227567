

export interface IStore {
    id: number,
    name: string,
    description: string,
    location: string,
   
    isShowroom: boolean,

    explicitProductDefinition: boolean,
    linkedToAllStoreDelivery: boolean,
    linkedToAllStoreTransfer: boolean,

    fileName: string,
    base64Picture: string,

    storeCurrencies: IStoreCurrency[],
    linkedStores: ILinkedStore[]
  }

  export interface ILinkedStore {
    id: number,
    storeId: number,
    otherStoreId: number,

    allowDelivery: boolean,
    allowTransfer: boolean,

    otherStoreName: string
  }

  export interface IStoreCurrency {
    id: number,

    storeId: number,
    currencyCode: string,
  }

  export const defaultStore : IStore = {
    id: 0,
    name: '',
    description: '',
    location: '',

    isShowroom: false,

    explicitProductDefinition: false,
    linkedToAllStoreDelivery: true,
    linkedToAllStoreTransfer: true,


    fileName: '',
    base64Picture: '',

    storeCurrencies: [],
    linkedStores: []
  }


  export interface IStoreSearch {
    name: string,
    description: string,
    location: string
  }