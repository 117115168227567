
export interface IPaymentOperation {
    id: number,
    paymentId: number,

    amount: number,
    reference: string,

    isCancelled: boolean,
    cancellationDate: Date,
    cancellationPurpose: string,

    type: string,

    checkBankCode: string,
    checkNumber: string,
    checkDate: Date,
    operatorCode: string,
    phoneNumber: string,
    cartType: string,
    cartNumber: string,
    transferFromBankCode: string,
    transferFromToCode: string,
    transferFromAccountNumber: string,
    transferToAccountNumber: string,
    otherReference: string,

    tellerOperationModeId: number,


    paymentDescription: string,
    ownerName: string,

  }
  

  export const defaultPaymentOperation : IPaymentOperation = {
    id: 0,
    paymentId: 0,

    amount: 0,
    reference: '',

    isCancelled: false,
    cancellationDate: new Date(),
    cancellationPurpose: '',

    type: '',

    checkBankCode: '',
    checkNumber: '',
    checkDate: new Date(),
    operatorCode: '',
    phoneNumber: '',
    cartType: '',
    cartNumber: '',
    transferFromBankCode: '',
    transferFromToCode: '',
    transferFromAccountNumber: '',
    transferToAccountNumber: '',
    otherReference: '',

    tellerOperationModeId: 0,

    paymentDescription: '',
    ownerName: '',
  }

  export interface IPaymentOperationSearch {
    schoolYearId: number,
        
    reference: string,

    ownerName: string,
    bearerName: string,
    paymentDescription: string,    
  }