import { IExpenseDetail } from "./Expense"

export interface IResource {
  id: number,
  
  permanentResourceId: number,
  permanentResourceName: string,
  capacity: number,

  description: string,

  allocationColor: string,
    
  maintenanceExpenses: IExpenseDetail[],

  resourceEmployees: IResourceEmployee[],
  resourceServices: IResourceService[]
}

export interface IResourceEmployee {
  id: number,
  resourceId: number,
  employeeId: number,

  employeeFirstName: string,
  employeeLastName: string
}

export interface IResourceService {
  id: number,
  resourceId: number,
  serviceId: number,

  serviceName: string,
  serviceDescription: string
}

  
export const defaultResource : IResource = {
  id: 0,
  
  permanentResourceId: 0,
  permanentResourceName: '',
  capacity: 1,

  description: '',

  allocationColor: '#FF20AA',

  maintenanceExpenses: [],
  resourceEmployees: [],
  resourceServices: []
}


export interface IResourceSearch {

  description: string,
  resourceTypeName: string,

}