
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MoreIcon from '@mui/icons-material/More';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useHookDataTemplateService, { useBasicFilterHookDataTemplate } from './services/HookDataTemplate';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IHookDataTemplate, IHookDataTemplateModel, IHookDataTemplateModelEntityParameter, defaultHookDataTemplate } from './models/HookDataTemplate';

import useApplicationQueryService, {useBasicFilterApplicationQuery} from 'features/setup/services/ApplicationQuery';
import useReportService , { useBasicFilterReport } from 'features/configuration/services/Report';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

import useExtensionTypeService from 'features/configuration/services/ExtensionType';


import IEnumeration, {IEnumerationItem, Enum_JOB_RETENTION_MODE, Enum_DURATION_UNIT} from 'features/configuration/models/Enumeration';
import { IReport } from 'features/configuration/models/Report';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { IBusinessApplication, IApplicationQuery, IApplicationQueryParameter } from 'features/setup/models/ApplicationQuery';
import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';


type QueryType = 'recipient' | 'entity' ;

export const HookDataTemplateForm: FC<IHookDataTemplate> = (props: IHookDataTemplate = defaultHookDataTemplate) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const { getReportsByEntity  } = useReportService();

  const { getApplicationQueryParameters} = useApplicationQueryService();

  const { createHookDataTemplate, updateHookDataTemplate } = useHookDataTemplateService();

  const {getEnumerationItemsByEnumerationCodes  } = useEnumerationService();
  const {baseType  } = useExtensionTypeService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterHookDataTemplate = useBasicFilterHookDataTemplate( 
    (event: React.MouseEvent<unknown>, row: IHookDataTemplate) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const [openApplicationQueryFilter, setOpenApplicationQueryFilter] = useState(false);
    const basicFilterApplicationQuery = useBasicFilterApplicationQuery( 
      
      async (event: React.MouseEvent<unknown>, row: IApplicationQuery) => {

        const {id, name } = row;

       if(currentQueryType === 'recipient') {
        setValue('recipientFilterQueryId', id);
        setValue('recipientFilterQueryName', name);        

       } else if(currentQueryType === 'entity') {
        setValue('entityFilterQueryId', id);
        setValue('entityFilterQueryName', name);

        const parameters = await getApplicationQueryParameters(id);
        // setValue('entityApplicationQueryParameters', parameters.map( 
        //     ({id, parameterName, parameterDataType}) => ({id, parameterName, parameterDataType})));
       } 

        setOpenApplicationQueryFilter(false);
      }
    );

    const [openReportFilter, setOpenReportFilter] = useState(false);
    const basicFilterReport = useBasicFilterReport( 
        async (event: React.MouseEvent<unknown>, row: IReport) => {
            
            const {id, name, entityName ,description} = row;
            
            setValue('reportId', id);
            setValue('reportName', name);    
            setOpenReportFilter(false);
        }
    );

  const refCurrentBusinessApplicationId = useRef(0);

  const emptyFunc = (obj: any) => {}

  const methods = useForm<IHookDataTemplate>({defaultValues:defaultHookDataTemplate});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchEntityFilterQueryId = watch('entityFilterQueryId');
  const watchRetentionMode = watch('retentionMode');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IHookDataTemplate>,Error,IHookDataTemplate>(
      _id>0?updateHookDataTemplate:createHookDataTemplate, {   
        onSuccess: (data: IResult<IHookDataTemplate>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['HookDataTemplate',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const [businessApplications, setBusinessApplications] = useState<IBusinessApplication[]>([]);
    const [reports, setReports] = useState<IReport[]>([]);

    const {data: _data, refetch} = useQuery<IHookDataTemplate>(['HookDataTemplate', _id], () => retrieveEntity('HookDataTemplate',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );      

    const {data: entityApplicationQueryParameters } = useQuery<IApplicationQueryParameter[]>(['HookDataTemplate','EntityQueryParameters', watchEntityFilterQueryId], () =>
      getApplicationQueryParameters(watchEntityFilterQueryId) );

    const {data: enumItems} = useQuery<IEnumerationItem[]>(
      ['EnumerationItems', 'HookDataTemplate'], () => 
        getEnumerationItemsByEnumerationCodes( [Enum_JOB_RETENTION_MODE, Enum_DURATION_UNIT] ));

    const refAppendHookDataTemplateModels = useRef<(value: Partial<FieldArray<IHookDataTemplate>> | Partial<FieldArray<IHookDataTemplate>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateHookDataTemplateModel = useRef<(index: number,value: Partial<FieldArray<IHookDataTemplate>> ) => void>(null);
    const refRemoveHookDataTemplateModel = useRef<(index: number ) => void>(null);
    
    const handleAddHookDataTemplateModel = (event: any) => {
           
      (refAppendHookDataTemplateModels.current??emptyFunc)({ id:0, hookDataTemplateId:_id, 
          name: '', bodyExpressionCheck: '', priority: 0, 
          hookDataTemplateModelEntityParameters: (entityApplicationQueryParameters || [] ).map( (p) =>
            ({id: 0, hookDataTemplateModelId: 0, businessApplicationQueryParameterId: p.id, 
                parameterValueExpression: '', parameterName: p.parameterName, parameterDataType: p.parameterDataType}) ) });
      
    }

    const handleHookDataTemplateModel = (event: React.MouseEvent<unknown>,index: number,row: IHookDataTemplateModel) => {    
      hookDataTemplateModelSelected(index);            
  }

  const hookDataTemplateModelSelected = async (index: number) => {
    setHookDataTemplateModelIndex(index);

    const hookDataTemplateModel = getValues().hookDataTemplateModels.at(index)!;

      // update only if there is a parameter that does not exist in list of parameters in case of update.
    if( (entityApplicationQueryParameters || [] ).some( xx => 
      isFalsy(hookDataTemplateModel.hookDataTemplateModelEntityParameters.find(x => x.businessApplicationQueryParameterId === xx.id) ) ) )
        (refUpdateHookDataTemplateModel.current??emptyFunc)(index, 
          {...hookDataTemplateModel!, 
            hookDataTemplateModelEntityParameters: (entityApplicationQueryParameters || [] ).map( (param) => {

              const {parameterName, parameterDataType, id} = param;
              const tmpParameter = hookDataTemplateModel.hookDataTemplateModelEntityParameters.find( p => p.businessApplicationQueryParameterId === id);

              return { id: tmpParameter?.id ?? 0, hookDataTemplateModelId: tmpParameter?.hookDataTemplateModelId ?? 0, 
                businessApplicationQueryParameterId:  id, 
                parameterValueExpression: tmpParameter?.parameterValueExpression ?? '', parameterName, parameterDataType }
            }) 
          });
  }

    const [hookDataTemplateModelIndex,setHookDataTemplateModelIndex] = useState<number>(-1);
    const [openEntityParameters,setOpenEntityParameters] = useState<boolean>(false);
    const getInputAdornmentHookDataTemplateModelParameters = (row: IHookDataTemplateModel, cellId: keyof IHookDataTemplateModel)  => ({
 
      toolTip: 'Expression',
      icon: MoreIcon,
      onClickIcon: (event: any, index: number, row: IHookDataTemplateModel ) => {
        
        setHookDataTemplateModelIndex(index);
        setOpenEntityParameters(true);
      }  
   })

   const getInputAdornmentHookDataTemplateModelExpression = (row: IHookDataTemplateModel, cellId: keyof IHookDataTemplateModel)  => ({
 
    toolTip: 'Expression',
    icon: DeveloperModeIcon,
    onClickIcon: (event: any, index: number, row: IHookDataTemplateModel ) => {

      const { bodyExpressionCheck } = row;

      setHookDataTemplateModelIndex(index);
      handleClickOpenExpression(event, 'body', 'HookData' , 'boolean', bodyExpressionCheck);
      
    }  
 })

    const hookDataTemplateModelRowActionIcon = ( hookDataTemplateModel: IHookDataTemplateModel) : ActionIconTableRow<IHookDataTemplate,IHookDataTemplateModel> => {  
      const res: ActionIconTableRow<IHookDataTemplate,IHookDataTemplateModel> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: (index: number,row: IHookDataTemplateModel) => true, 
        isActionExecuting: true,
        onRowClickIcon: (event : any, index: number, row: IHookDataTemplateModel) => {           
          
          (refRemoveHookDataTemplateModel.current??emptyFunc)(index);            
        }
      }
      return res;
    }

    const cellEditableHookDataTemplateModel = (row: IHookDataTemplateModel, cellId: keyof IHookDataTemplateModel) => {
      return true;
    }

    const [headHookDataTemplateModelCells, setHeadHookDataTemplateModelCells]  = useState<HeadCell<IHookDataTemplateModel>[]>([]);
      useEffect(() => {
        setHeadHookDataTemplateModelCells([            
          //{id:'description', label : t('Description'),  display: true, type: 'string', width: 65, isEditable: cellEditableJobSchedule},
          //{id:'startDate', label : t('Start date'),  display: true, type: 'date', width: 25, isEditable: cellEditableJobSchedule},
          {id:'name', label : t('Name'),  display: true, type: 'string', width: 50, 
            isEditable: cellEditableHookDataTemplateModel, getInputAdornment: getInputAdornmentHookDataTemplateModelParameters},
          {id:'bodyExpressionCheck', label : t('Expression'),  display: true, type: 'string', width: 40, 
            isEditable: cellEditableHookDataTemplateModel, getInputAdornment: getInputAdornmentHookDataTemplateModelExpression},
          {id:'priority', label : t('Priority'),  display: true, type: 'numeric', width: 10, isEditable: cellEditableHookDataTemplateModel,}  
        ])
      }, [t,i18n]);


      const refAppendHookDataTemplateModelEntityParameters = useRef<(value: Partial<FieldArray<IHookDataTemplate>> | Partial<FieldArray<IHookDataTemplate>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateHookDataTemplateModelEntityParameter = useRef<(index: number,value: Partial<FieldArray<IHookDataTemplate>> ) => void>(null);
      const refRemoveHookDataTemplateModelEntityParameter = useRef<(index: number ) => void>(null);


      const [hookDataTemplateModelEntityParameterIndex,setHookDataTemplateModelEntityParameterIndex] = useState<number>(-1);

      const getInputAdornmentHookDataTemplateModelEntityParameterExpression = (row: IHookDataTemplateModelEntityParameter, cellId: keyof IHookDataTemplateModelEntityParameter)  => ({
 
        toolTip: 'Expression',
        icon: DeveloperModeIcon,
        onClickIcon: (event: any, index: number, row: IHookDataTemplateModelEntityParameter ) => {
                    
          const { parameterValueExpression, parameterDataType } = row;

          //alert(parameterDataType);
          setHookDataTemplateModelEntityParameterIndex(index);
          handleClickOpenExpression(event, 'parameter', 'HookData' , baseType(parameterDataType), parameterValueExpression);
      
        }  
     })

      const [headHookDataTemplateModelEntityParameterCells, setHeadHookDataTemplateModelEntityParameterCells]  = useState<HeadCell<IHookDataTemplateModelEntityParameter>[]>([]);
      useEffect(() => {
        setHeadHookDataTemplateModelEntityParameterCells([            
          //{id:'description', label : t('Description'),  display: true, type: 'string', width: 65, isEditable: cellEditableJobSchedule},
          //{id:'startDate', label : t('Start date'),  display: true, type: 'date', width: 25, isEditable: cellEditableJobSchedule},
          {id:'parameterName', label : t('Name'),  display: true, type: 'string', width: 50, },
          {id:'parameterValueExpression', label : t('Expression'),  display: true, type: 'string', width: 50, 
            getInputAdornment: getInputAdornmentHookDataTemplateModelEntityParameterExpression},
          
        ])
      }, [t,i18n]);

      const [openEntityExpression, setOpenEntityExpression] = useState(false);
      const [currentExpression, setCurrentExpression] = useState(''); 
      const [currentEntityName, setCurrentEntityName] = useState(''); 
      const [currentReturnType, setCurrentReturnType] = useState(''); 

      type ExpressionType = 'control' | 'entityId' | 'language' | 'body' | 'parameter';
      const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('body');

      const handleClickOpenExpression = (event: any, expressionType: ExpressionType, 
          entityName: string, returnType: string, expression: string) => {

        setCurrentExpressionType(expressionType);
        setCurrentEntityName(entityName);
        setCurrentReturnType(returnType);
        setCurrentExpression(expression);
        setOpenEntityExpression(true);
      }

      const handleClickOkExpression = async () => {

        const checkExpression = await checkEntityExpressionSyntax(currentEntityName, currentExpression);
        if(!checkExpression){
          enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        const {syntaxOk, syntaxError, returnType} = checkExpression;
        if(!syntaxOk) {
          enqueueSnackbar( syntaxError , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }
       
        if(returnType !== currentReturnType) {
          enqueueSnackbar( `${t('The result of expression must be')} ${currentReturnType}` , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
                return;
        }

        if(currentExpressionType === 'body') {
          const hookDataTemplateModel = getValues().hookDataTemplateModels.at(hookDataTemplateModelIndex);
          if(isFalsy(hookDataTemplateModel)) return;

          (refUpdateHookDataTemplateModel.current??emptyFunc)(hookDataTemplateModelIndex, 
            {...hookDataTemplateModel!, bodyExpressionCheck: currentExpression });
        } else if(currentExpressionType === 'parameter') {
          const hookDataTemplateModel = getValues().hookDataTemplateModels.at(hookDataTemplateModelIndex);
          if(isFalsy(hookDataTemplateModel)) return;

          const hookDataTemplateModelEntityParameter = hookDataTemplateModel!.
                  hookDataTemplateModelEntityParameters.at(hookDataTemplateModelEntityParameterIndex);
          if(isFalsy(hookDataTemplateModelEntityParameter)) return;
          
          (refUpdateHookDataTemplateModelEntityParameter.current??emptyFunc)(hookDataTemplateModelEntityParameterIndex, 
            {...hookDataTemplateModelEntityParameter!, parameterValueExpression: currentExpression });
        } else if (currentExpressionType === 'control') {
          setValue('recipientEntityControlExpression', currentExpression);
        } else if (currentExpressionType === 'entityId') {
          setValue('entityIdExpression', currentExpression);
        } else if (currentExpressionType === 'language') {
          setValue('reportLanguageExpression', currentExpression);
        }


        
        setOpenEntityExpression(false);
      }
      


    const [currentQueryType, setCurrentQueryType] = useState<QueryType>('recipient');
    const handleClickSearchApplicationQuery = (queryType: QueryType) => (event: any) => {
      setCurrentQueryType(queryType);

      const { recipientBusinessApplicationId,  entityBusinessApplicationId } = getValues();
      if(queryType === 'recipient')
        refCurrentBusinessApplicationId.current = recipientBusinessApplicationId;
      else if(queryType === 'entity')
        refCurrentBusinessApplicationId.current = entityBusinessApplicationId;

      setOpenApplicationQueryFilter(true);
    }

    const handleClickSearchReport = (event: any) => {  
      
      setOpenReportFilter(true);
    }

    // useEffect( () => {        
    //   async function loadBusinessApplications() {
    //     const arr = await getBusinessApplications( {name: '', dbmsType: ''} );         
    //     setBusinessApplications([...arr]);
    //   }
  
    //   loadBusinessApplications();
    // }, []); 

      useEffect( () => {        
        async function loadReports() {
          const arr = await getReportsByEntity('PolicyRisk');         
          setReports([...arr]);
        }
  
        loadReports();
      }, []);

      useEffect( () => {        
        setCurrentFormNameAtom(t('HookData Templ.'));
        setCurrentBasicTextFilterProps(basicFilterHookDataTemplate);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('HookDataTemplate',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultHookDataTemplate);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              return;
            }
      
            if(data.recipientEntityControlExpression.trim() === '' || 
                  data.entityIdExpression.trim() === '' || data.reportLanguageExpression.trim() === '' ) {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              return;
            }

            if(data.entityFilterQueryId <= 0 || data.recipientFilterQueryId <= 0 ) {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('HookDataTemplate', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(40% - 8px)'}} id="name" label={t('Name')} {...register('name')} 
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <TextField sx={{width:'calc(50% - 8px)'}} id="description" label={t('Description')} {...register('description')} /> 
                                                                                             
                            </Box> 
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(50% - 8px)'}} id="reportName" label={t('Report')} 
                                    {...register('reportName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={handleClickSearchReport}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    )
                                  }} />  
                                  { openReportFilter && <FormDialog open={openReportFilter} maxWidth='sm'
                                      okText={t('OK')} cancelText='' title={t('Report filter')} onCancel={()=> {}} 
                                      onClose={()=> {setOpenReportFilter(false);}} onOk={()=> {setOpenReportFilter(false);}}  >
                                          <BasicTextFilterForm<IReport> {...basicFilterReport } />
                                  </FormDialog> } 
                                <Controller name={`retentionMode`} 
                                      control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="retentionMode"
                                          label={t('Retention')} inputProps={ {readOnly: false}} >
                                          {enumItems &&  enumItems.filter(x => x.enumerationCode === Enum_JOB_RETENTION_MODE).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                        </TextField>
                                      )}
                                  />
                                { watchRetentionMode === 'PERSO' &&
                                <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={'#'} sx={{width:'calc(10% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`retentionValue`} 
                                control={control}
                              /> }
                              { watchRetentionMode === 'PERSO' && <Controller 
                                  name={`retentionUnit`} 
                                  control={control} 
                                  //defaultValue={ (businessApplications || []).length === 1 ? (businessApplications || [])[0].id : 0 }
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="retentionUnit"
                                      label={t('Dur. unit')} inputProps={ {readOnly: false}} >
                                      {enumItems && enumItems.filter(x => x.enumerationCode === Enum_DURATION_UNIT).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              /> }
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }}>
                              <Controller name='recipientBusinessApplicationId' control={control} 
                                    defaultValue={ (businessApplications || []).length === 1 ? (businessApplications || [])[0].id : 0 }
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="recipientBusinessApplicationId"
                                        label={`${t('Business application')} - ${t('Recipient')}`} inputProps={ {readOnly: false}} >
                                        {businessApplications.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              <TextField sx={{width:'calc(50% - 8px)'}} id="recipientFilterQueryName" label={t('Query: Recipient filter')} 
                                  {...register('recipientFilterQueryName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickSearchApplicationQuery('recipient')}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />  
                                { openApplicationQueryFilter && <FormDialog open={openApplicationQueryFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Query filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenApplicationQueryFilter(false);}} onOk={()=> {setOpenApplicationQueryFilter(false);}}  >
                                    <BasicTextFilterForm<IApplicationQuery> {...basicFilterApplicationQuery} />
                                  </FormDialog>  } 
                                <FormControlLabel sx={{width:'calc(15% - 8px)'}}
                                      label={t('Single ?')}
                                      control={
                                      <Controller
                                          name='singleRecipient'
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                      />} 
                                  />                                                                          
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }}>
                              <Controller name='entityBusinessApplicationId' control={control} 
                                    defaultValue={ (businessApplications || []).length === 1 ? (businessApplications || [])[0].id : 0 }
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="entityBusinessApplicationId"
                                        label={`${t('Business application')} - ${t('Entity')}`} inputProps={ {readOnly: false}} >
                                        {businessApplications.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              <TextField sx={{width:'calc(50% - 8px)'}} id="entityFilterQueryName" label={t('Query: Entity filter')} 
                                  {...register('entityFilterQueryName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickSearchApplicationQuery('entity')}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} /> 
                              <FormControlLabel sx={{width:'calc15% - 8px)'}}
                                      label={t('Single ?')}
                                      control={
                                      <Controller
                                          name='singleEntity'
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                      />} 
                                  /> 
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="recipientEntityControlExpression" label={`${t('Expression')} - ${t('Control')} ` }
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                                {...register('recipientEntityControlExpression')}
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">                                            
                                      <IconButton color="primary" onClick={ (event) => handleClickOpenExpression(event, 'control', 'HookData' , 'boolean', getValues().recipientEntityControlExpression) }>
                                        <DeveloperModeIcon />
                                      </IconButton>                                                                                               
                                  </InputAdornment>
                                )
                              }} /> 
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="entityIdExpression" label={`${t('Expression')} - ${t('Entity id')} ` }
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                                {...register('entityIdExpression')}
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">                                            
                                      <IconButton color="primary" onClick={ (event) => handleClickOpenExpression(event, 'entityId', 'HookData' , 'numeric', getValues().reportLanguageExpression) }>
                                        <DeveloperModeIcon />
                                      </IconButton>                                                                                               
                                  </InputAdornment>
                                )
                              }} /> 
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="reportLanguageExpression" label={`${t('Expression')} - ${t('Language')} ` }
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                                {...register('reportLanguageExpression')}
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">                                            
                                      <IconButton color="primary" onClick={ (event) => handleClickOpenExpression(event, 'language', 'HookData' , 'string', getValues().reportLanguageExpression) }>
                                        <DeveloperModeIcon />
                                      </IconButton>                                                                                               
                                  </InputAdornment>
                                )
                              }} /> 
                            </Box>
                        </Stack>                        
                    </Grid>                    
                    <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >  
                      <Stack flexDirection='column'  >
                        <Box sx={{ mt: 1, width: '100%' }}  >                                
                            <ArrayFieldTableEx<IHookDataTemplate,IHookDataTemplateModel,'id'> 
                              
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headHookDataTemplateModelCells} rowsPathName='hookDataTemplateModels' 
                              title={t('Models')} rowActionIcon={hookDataTemplateModelRowActionIcon}  
                              onRowSelected={handleHookDataTemplateModel}
                                                  
                              refAppend={refAppendHookDataTemplateModels as MutableRefObject<(value: Partial<FieldArray<IHookDataTemplate>> | Partial<FieldArray<IHookDataTemplate>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdateHookDataTemplateModel as MutableRefObject<(index: number,value: Partial<FieldArray<IHookDataTemplate>>) => void>}
                              refRemove={refRemoveHookDataTemplateModel as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={[
                              { toolTip: `${t('Add')}...`, onClickIcon: handleAddHookDataTemplateModel ,icon: AddCircleIcon,  },
                              
                              ]}
                            /> 
                            { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={`${t('Expression')} ...`} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                    <EntityExpression entityName={currentEntityName} properties={[]} 
                                      expression={currentExpression} setExpression={setCurrentExpression} />
                                  </FormDialog>  }
                            { openEntityParameters && <FormDialog open={openEntityParameters} maxWidth='sm'
                              okText={t('OK')} cancelText='' title={t('Parameters')} onCancel={()=> {}} 
                              onClose={()=> {setOpenEntityParameters(false);}} onOk={()=> {setOpenEntityParameters(false);}}  >
                                  <Stack flexDirection='column'>
                                    <Box sx={{ mt: 1, width: '100%' }} key={`jobScheduleStepReportTableColumns-${getValues().id}-`}>
                                      <ArrayFieldTableEx<IHookDataTemplate,IHookDataTemplateModelEntityParameter,'id'> 
                                
                                        mainObject={getValues()} fieldKey='id'   
                                        headCells={headHookDataTemplateModelEntityParameterCells} rowsPathName={`hookDataTemplateModels.${hookDataTemplateModelIndex}.hookDataTemplateModelEntityParameters`}
                                        title={t('Parameters')} //rowActionIcon={hookDataTemplateModelRowActionIcon}  
                                        //onRowSelected={handleJobScheduleSelected}
                                                            
                                        refAppend={refAppendHookDataTemplateModelEntityParameters as MutableRefObject<(value: Partial<FieldArray<IHookDataTemplate>> | Partial<FieldArray<IHookDataTemplate>>[], options?: FieldArrayMethodProps) => void>}
                                        refUpdate={refUpdateHookDataTemplateModelEntityParameter as MutableRefObject<(index: number,value: Partial<FieldArray<IHookDataTemplate>>) => void>}
                                        refRemove={refRemoveHookDataTemplateModelEntityParameter as MutableRefObject<(index: number) => void>}

                                        //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                        //displayMore={undefined}
                                        toolbarActions={[                                                                                
                                        ]}
                                      /> 
                                    </Box>
                                  </Stack>
                            </FormDialog> }
                          </Box>
                      </Stack>
                    </Grid>
                </Grid>
            </Box>
        </FormProvider> 
  )
}

