
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useCertificateAllocationService, { useBasicFilterCertificateAllocation } from './services/CertificateAllocation';

import useCertificatTemplateService from './services/CertificateTemplate';

import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { IconButton, InputAdornment, MenuItem } from '@mui/material';
import NumberFormat from 'react-number-format';

import { ICertificateAllocation, defaultCertificateAllocation } from './models/CertificateAllocation';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration, { Enum_CERTIFICATE_TYPE, Enum_CERTIFICATE_ALLOCATION_STATUS,
     IEnumerationItem } from 'features/configuration/models/Enumeration';
import { ICertificateTemplate } from './models/CertificateTemplate';

export const CertificateAllocationForm: FC<ICertificateAllocation> = (props: ICertificateAllocation = defaultCertificateAllocation) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes } = useEnumerationService();

  const { createCertificateAllocation, updateCertificateAllocation } = useCertificateAllocationService();

  const { getCertificateTemplates } = useCertificatTemplateService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterCertificateAllocation = useBasicFilterCertificateAllocation( 
    (event: React.MouseEvent<unknown>, row: ICertificateAllocation) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const methods = useForm<ICertificateAllocation>({defaultValues:defaultCertificateAllocation});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchEndNumber = watch('endNumber');
  const watchCertificatCount = watch('certificatCount');


  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ICertificateAllocation>,Error,ICertificateAllocation>(
      _id>0?updateCertificateAllocation:createCertificateAllocation, {   
        onSuccess: (data: IResult<ICertificateAllocation>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['CertificateAllocation',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const [certificateTemplates, setCertificateTemplates] = useState<ICertificateTemplate[]>([]);

    const {data: _data, refetch} = useQuery<ICertificateAllocation>(['CertificateAllocation', _id], () => retrieveEntity('CertificateAllocation',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const {data: enumItems} = useQuery<IEnumerationItem[]>(
        ['EnumerationItems', 'CertificateAllocation'], () => 
          getEnumerationItemsByEnumerationCodes( [Enum_CERTIFICATE_TYPE, Enum_CERTIFICATE_ALLOCATION_STATUS] ));

      
          useEffect( () => {        
            async function loadCertificateTemplates() {
              const arr = await getCertificateTemplates({ name:'', description:''});         
              setCertificateTemplates([...arr]);
            }
      
            loadCertificateTemplates();
          }, []);

      
      useEffect( () => {
        const {startNumber} = getValues();
        setValue("endNumber", startNumber +  watchCertificatCount );
      }, [watchCertificatCount] );


      useEffect( () => {        
        setCurrentFormNameAtom(t('Certificate allocation'));
        setCurrentBasicTextFilterProps(basicFilterCertificateAllocation);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('CertificateAllocation',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            const {startNumber, endNumber} = _data;
            reset( {..._data, certificatCount: endNumber - startNumber});
          }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultCertificateAllocation);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
          const data = getValues(); 
          if(data.intermediaryCode.trim() === '' || data.reference.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              return;
            }

          if(data.startNumber > data.endNumber) {
            enqueueSnackbar( t('The range of number is invalid'), { variant: 'warning',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            return;
          }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('CertificateAllocation', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(35% - 8px)'}} id="code" label={t('Inter. code')} {...register('intermediaryCode')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <Controller name='certificateTemplateId' control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(55% - 8px)'}} id="certificateTemplateId"
                                      label={t('Template')} inputProps={ {readOnly: false}}>
                                      {certificateTemplates.map( 
                                            (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                          }
                                    </TextField>
                                    )}
                                  />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='status' control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="certificateType"
                                      label={t('Status')} inputProps={ {readOnly: true}}>
                                      {enumItems && enumItems.filter( e => e.enumerationCode === Enum_CERTIFICATE_ALLOCATION_STATUS).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }  
                                    </TextField>
                                    )}
                                  />
                              <Controller control={control}
                                  name='statusDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Status date')}       
                                    readOnly disableOpenPicker                                
                                      onChange={onChange}                        
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <TextField sx={{width:'calc(100% - 8px)'}} id="name" label={t('Reference')} {...register('reference')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat                                                   
                                      //disabled={true}
                                      label={t('Start number')} sx={{width:'calc(40% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`startNumber`}
                                control={control}
                              />     
                              <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat                                                   
                                      //disabled={true}
                                      label={t('Certificat count')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`certificatCount`}
                                control={control}
                              />  
                              <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat                                                   
                                      //disabled={true}
                                      label={t('End number')} sx={{width:'calc(40% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`endNumber`}
                                control={control}
                              />                         
                            </Box>
                        </Stack>                        
                    </Grid>                    
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

