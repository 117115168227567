
export interface IGroupBilling {
    id: number,

    groupingPurpose: string,
    reference: string,
    issueDate: Date,

    groupBillingBillings: IGroupBillingBilling[]
}


export interface IGroupBillingBilling {
    id: number,

    groupBillingId: number,
    billingId: number,

    customerName: string,
    showroomName: string,
    currencyCode: string,

    amount: number,
}


export const defaultGroupBilling : IGroupBilling = {
    id: 0,

    groupingPurpose: '',
    reference: '',
    issueDate: new Date(),

    groupBillingBillings: []
}


export interface IDashboardGroupBilling {
    //operationsByCategory: {schoolYearName: string, category: string, amount: number} [],
    operationsByMode: {mode: string, netAmount: number, cancelledAmount: number}[]
  }
  

  export interface IGroupBillingSearch {
    groupingPurpose: string,
    reference: string,

    startDate: Date,
    endDate: Date,

  }


