

import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useEffect, useState } from 'react';
import {IEntityActionReport,  IEntityActionReportSearch } from "../models/EntityActionReport";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';

import { currentUserSessionAtom } from 'library/store';
import { isNumber, toNumber } from 'lodash';
import { useQuery } from 'react-query';
import { ISchoolYear } from 'features/setup/models/SchoolYear';
import { EventInput } from '@fullcalendar/core';


const _ = () => {

    const axios = useAxios(); 

    //const {schoolYears} = useRecoilValue(currentUserSessionAtom);    

    const createEntityActionReport = async (entityActionReport: IEntityActionReport)  =>       
        await (await axios.post('/api/setup/entityActionReport/create', entityActionReport)).data;       
        
    const updateEntityActionReport = async (entityActionReport: IEntityActionReport)  =>       
        await (await axios.post('/api/setup/entityActionReport/update', entityActionReport)).data; 
    
    const getEntityActionReport = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/entityActionReport/get-entityActionReport/${id}`));
      return await data;
    }
   
    const getEntityActionReports = async (criteria: IEntityActionReportSearch, pagination?: IPagination) : Promise<IEntityActionReport[]> => {

      const {name, description, entityName } = criteria;
      
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;
      
      const {data} = (await axios.get(`/api/setup/entityActionReport/get-entityActionReports?name=${name}&description=${description}&entityName=${entityName}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    
      
      
    return {    
      createEntityActionReport,
      updateEntityActionReport,
      getEntityActionReport,
      getEntityActionReports,

    } 
}

export default _;

export interface IFilterEntityActionReportOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IEntityActionReport[], React.Dispatch<React.SetStateAction<IEntityActionReport[]>>],
}

const defaultFilterEntityActionReportOption: IFilterEntityActionReportOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterEntityActionReport = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IEntityActionReport) => void,
                    filterOption?: IFilterEntityActionReportOption  ) => {

  const { getEntityActionReports } = _();

  const { t, i18n } = useTranslation();   

  
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterEntityActionReportOption;

  
  const [headEntityActionReportCells, setHeadEntityActionReportCells]  = useState<HeadCell<IEntityActionReport>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },    

    
    {id:'name', label : t('Name'),  display: true, type: 'string', width: 20 },
    {id:'description', label : t('Description'),  display: true, type: 'string', width: 20},
    {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', width: 20},
    {id:'entityName', label : t('Entity'),  display: true, type: 'string', width: 20},
  ]); 
  
  const [filterElements,setFilterElements] = useState<ITextFilterElement[]>([         
        
    {name: 'name', text: t('First name'), value: ''},
    {name: 'description', text: t('Last name'), value: ''},     
    {name: 'entityName', text: t('Entity'), value: ''},              
  ]);
  
  const [filteredEntityActionReports, ] = useState<IEntityActionReport[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IEntityActionReport[]> => {
        
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';  
    const entityName = filterElements.find( elt => elt.name === 'entityName')?.value || '';  
    
    const arr = await getEntityActionReports( {name,description,entityName}, pagination );
   
    return arr;
  }

  const objKey: keyof IEntityActionReport = 'id';

  return {
    title: `${t('Entity Action Report')}(s)`, headCells: headEntityActionReportCells, objKey,
    filterElements, rows: filteredEntityActionReports, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
