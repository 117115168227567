
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IDashboardExpense, IExpense, IExpenseSearch } from "../models/Expense";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';
//import { IPolicyRisk, IPolicyRiskCoverage } from 'features/maintenanceion/models/Policy';


const _ = () => {

    const axios = useAxios(); 

    const createExpense = async (expense: IExpense)  =>       
        await (await axios.post('/api/production/expense/create', expense)).data;       
        
    const updateExpense = async (expense: IExpense)  =>       
        await (await axios.post('/api/production/expense/update', expense)).data; 
    
    const getExpense = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/expense/get-expense/${id}`));
      return await data;
    }
   

    const getExpenses = async (criteria: IExpenseSearch, pagination?: IPagination) : Promise<IExpense[]> => {

      const {reference, description, supplierFullName} = criteria;
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/production/expense/get-expenses?reference=${reference}&description=${description}&supplierFullName=${supplierFullName}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getExpenseDashboard = async (year: number, month: number) : Promise<IDashboardExpense> => {
      const {data} = (await axios.get(`/api/production/expense/get-expense-dashboard?year=${year}&month=${month}`));
      return await data;
    }
    
   
    return {    
      createExpense,
      updateExpense,
      getExpense,
      getExpenses,

      getExpenseDashboard
      
    } 
}

export default _;

export interface IFilterExpenseOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IExpense[], React.Dispatch<React.SetStateAction<IExpense[]>>],
}

const defaultFilterExpenseOption: IFilterExpenseOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterExpense = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IExpense) => void,
                                            filterOption?: IFilterExpenseOption  ) => {

  const { getExpenses } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterExpenseOption;



  const [headExpenseCells, setHeadExpenseCells]  = useState<HeadCell<IExpense>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'supplierFullName', label : t('Full name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'status', label : t('Status'),  display: true, type: 'string', },
    {id:'issueDate', label : t('Date'),  display: true, type: 'date', },

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      {name: 'supplierFullName', text: t('Full name'), value: ''},
    ]);    

  const [filteredExpenses, ] = useState<IExpense[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IExpense[]> => {
   
    const reference = filterElements.find( elt => elt.name === 'reference')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const supplierFullName = filterElements.find( elt => elt.name === 'supplierFullName')?.value || '';

    const arr = await getExpenses( { reference,description, supplierFullName}, pagination );
    
    return arr;
  }

  const objKey: keyof IExpense = 'id';

  return {
    title: `${t('Expense')} / ${t('Charge')}`, headCells: headExpenseCells, objKey,
    filterElements, rows: filteredExpenses, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
