
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IDashboardGroupBilling, IGroupBilling, IGroupBillingSearch } from "../models/GroupBilling";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { isNumber, toNumber } from 'lodash';

import { currentUserSessionAtom } from 'library/store';
import { addMonths, addDays } from 'date-fns';

const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createGroupBilling = async (groupBilling: IGroupBilling)  =>       
        await (await axios.post('/api/finance/groupBilling/create', groupBilling)).data;       
        
    const updateGroupBilling = async (groupBilling: IGroupBilling)  =>       
        await (await axios.post('/api/finance/groupBilling/update', groupBilling)).data; 
    
    const getGroupBilling = async (id  : number )  => {
      const {data} = (await axios.get(`/api/finance/groupBilling/get-groupBilling/${id}`));
      return await data;
    }
   

    const getGroupBillings = async (criteria: IGroupBillingSearch, pagination?: IPagination) : Promise<IGroupBilling[]> => {
      
      const {startDate, endDate, groupingPurpose, reference} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/finance/groupBilling/get-groupBillings?groupingPurpose=${groupingPurpose}` +
        `&reference=${reference}&sStartDate=${sStartDate}&sEndDate=${sEndDate}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    
    const getGroupBillingDashboard = async (groupBillingId: number) : Promise<IDashboardGroupBilling> => {
      const {data} = (await axios.get(`/api/finance/groupBilling/get-groupBilling-dashboard?groupBillingId=${groupBillingId}`));
      return await data;
    }
      
    return {    
      createGroupBilling,
      updateGroupBilling,
      getGroupBilling,
      getGroupBillings,

      getGroupBillingDashboard
     
    } 
}

export default _;

export interface IFilterGroupBillingOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IGroupBilling[], React.Dispatch<React.SetStateAction<IGroupBilling[]>>],
}

const defaultFilterProductOption: IFilterGroupBillingOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterGroupBilling = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IGroupBilling) => void,
                                            filterOption?: IFilterGroupBillingOption  ) => {

  const { getGroupBillings } = _();

  const { t, i18n } = useTranslation();   

  const { applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;

  const [headGroupBillingCells, setHeadGroupBillingCells]  = useState<HeadCell<IGroupBilling>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'groupingPurpose', label : t('Purpose'),  display: true, type: 'string', },
    {id:'reference', label : t('Reference'),  display: true, type: 'string', },
    
    {id:'issueDate', label : t('Date'),  display: true, type: 'date', },

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
   
    {name: 'startDate', text: t('Start date'), dataType: 'date' ,  value: '', dateValue: addMonths(new Date(), -1)},
    {name: 'endDate', text: t('End date'), dataType: 'date'  , value: '', dateValue: new Date()},

    {name: 'groupingPurpose', text: t('Purpose'), value: ''},
    {name: 'reference', text: t('Reference'), value: ''},
  ]);    

  const [filteredGroupBillings, ] = useState<IGroupBilling[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IGroupBilling[]> => {
    
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();

    const groupingPurpose = filterElements.find( elt => elt.name === 'groupingPurpose')?.value || '';
    const reference = filterElements.find( elt => elt.name === 'reference')?.value || '';
           
    const arr = await getGroupBillings( {startDate, endDate, groupingPurpose, reference }, pagination );
    
    return arr;
  }

  const objKey: keyof IGroupBilling = 'id';

  return {
    title: t('Group billing'), headCells: headGroupBillingCells, objKey,
    filterElements, rows: filteredGroupBillings, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
