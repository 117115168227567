
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { DatePicker } from '@mui/x-date-pickers';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';


import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

import IEnumeration,  { IEnumerationItem, Enum_PAYMENT_SOURCE } from 'features/configuration/models/Enumeration';
  

import useUserService, { useBasicFilterUser } from './services/User';
import { IUser, defaultUser, IUserRole, IUserBusinessApplication, IUserPaymentSource, IUserStore } from './models/User';
import { useBasicFilterRole } from './services/Role';
import { useBasicFilterGroup } from './services/Group';

import {IRole} from './models/Role';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IResult } from 'library/interface';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import Button from '@mui/material/Button';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
//import { useBasicFilterBusinessApplication } from 'features/setup/services/ApplicationQuery';

import useIntermediaryService from 'features/production/services/Intermediary';

// import { IBusinessApplication } from 'features/setup/models/ApplicationQuery';
// import { useBasicFilterRequestType } from 'features/setup/services/RequestType';
// import { IRequestType } from 'features/setup/models/RequestType';
import md5 from 'md5';
import EntityExpression from 'components/ui/EntityExpression';
import { IconButton, InputAdornment, MenuItem } from '@mui/material';
import { IGroup, IGroupUser } from './models/Group';
import { isFalsy } from 'utility-types';
import { useBasicFilterPerson } from 'features/production/services/Person';
import { IPerson } from 'features/production/models/Person';
import { useBasicFilterStore } from 'features/configuration/services/Store';
import { IStore } from 'features/configuration/models/Store';
//import { IntermediaryForm } from 'features/production';


export const UserForm: FC<IUser> = (props: IUser = defaultUser) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { enqueueSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntityExpressionSyntax, checkEntitySaveAuthorization} = useEntityService();
  const { getMainApplicationIntermediaries } = useIntermediaryService();

  const { createUser, updateUser, getUser, getUsers } = useUserService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

    const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
    const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
    const basicFilterUser = useBasicFilterUser( 
        (event: React.MouseEvent<unknown>, row: IUser) => {
            setIsSearchBoxShow(false);
            _setId(row.id);
        }
    );

    const emptyFunc = (obj: any) => {}
    const [openRoleFilter, setOpenRoleFilter] = useState(false);
    const basicFilterRole = useBasicFilterRole( 
        (event: React.MouseEvent<unknown>, row: IRole) => {
            const {id, name, description} = row;

            if(getValues().userRoles.some( b => b.roleId === id)) return;
                       
            (refAppendUserRoles.current??emptyFunc)({ roleId: id,  
                    roleName: name, roleDescription: description }); 
            
            setOpenRoleFilter(false);
        }
    );

    const [openGroupFilter, setOpenGroupFilter] = useState(false);
    const basicFilterGroup = useBasicFilterGroup( 
        (event: React.MouseEvent<unknown>, row: IGroup) => {
            const {id, name, description} = row;

            if(getValues().groupUsers.some( b => b.groupId === id)) return;
                       
            (refAppendGroupUsers.current??emptyFunc)({ groupId: id,  
                    groupName: name, groupDescription: description }); 
            
            setOpenGroupFilter(false);
        }
    );

    const [openUserStoreFilter, setOpenUserStoreFilter] = useState(false);
    const basicFilterStore = useBasicFilterStore( 
        (event: React.MouseEvent<unknown>, row: IStore) => {
            const {id, name,isShowroom, description} = row;

            if(getValues().userStores.some( b => b.storeId === id)) return;
                       
            (refAppendUserStores.current??emptyFunc)({ id: 0, userId: _id,  storeId: id, storeName: name, isShowroom }); 
            
            setOpenUserStoreFilter(false);
        }
    );

    const [openPersonFilter, setOpenPersonFilter] = useState(false);
    const basicFilterPerson = useBasicFilterPerson( 
        (event: React.MouseEvent<unknown>, row: IPerson) => {
            const {id, firstName, lastName } = row;
            
            setValue('personId', id);
            setValue('personName', `${lastName} ${firstName}`.trim());
                                        
            setOpenPersonFilter(false);
        }
    );

    // const [openBusinessApplicationFilter, setOpenBusinessApplicationFilter] = useState(false);
    // const basicFilterBusinessApplication = useBasicFilterBusinessApplication( 
    //     (event: React.MouseEvent<unknown>, row: IBusinessApplication) => {
    //         const {id, name, description} = row;

    //         if(getValues().userBusinessApplications.some( b => b.businessApplicationId === id)) return;
            
    //         (refAppendUserBusinessApplications.current??emptyFunc)({ businessApplicationId: id,  
    //             businessApplicationName: name, businessApplicationDescription: description }); 
            
    //         setOpenBusinessApplicationFilter(false);
    //     }
    // );
  

  const [openEntityExpression, setOpenEntityExpression] = useState(false);
  const [currentExpression, setCurrentExpression] = useState(''); 
  const [currentEntityName, setCurrentEntityName] = useState('RequestData'); 

  const [userRequestTypeIndex, setUserRequestTypeIndex] = useState<number>(-1);

  const methods = useForm<IUser>({defaultValues:defaultUser});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;


  const watchPersonId = watch('personId');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IUser>,Error,IUser>(
    _id>0?updateUser:createUser, {   
        onSuccess: (data: IResult<IUser>) => {
            enqueueSnackbar( 'Operation done !!!', { variant: 'success',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                    
            setIsSaveLoading(false);
            _setId(data.data.id);
            //setCurrentEntityIdForAction(data.data.id);
            setCurrentFormNameAtom(`${t('User')} - # ${data.data.id} # ` );
           
            //queryClient.invalidateQueries(['User',data.data.id]);
        },
        onError: (err: Error) => {          
            enqueueSnackbar( error?.message, { variant: 'error',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            setIsSaveLoading(false);
        }
    });

    
    const {data: _data, refetch} = useQuery<IUser>(['User', _id], () => retrieveEntity('User',_id), 
        {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'User'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_PAYMENT_SOURCE  ] ));

    const [headUserRoleCells, setHeadUserRoleCells]  = useState<HeadCell<IUserRole>[]>([
        {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },
        {id:'roleName', label : t('Name'),  display: true, type: 'string',width: 45 },
        {id:'roleDescription', label : t('Description'),  display: true, type: 'string',width: 50, },    
      ]); 
    const refAppendUserRoles = useRef<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateUserRole = useRef<(index: number,value: Partial<FieldArray<IUser>> ) => void>(null);
    const refRemoveUserRole = useRef<(index: number ) => void>(null);

    const handleAddRoles = (event: any) => {
        setOpenRoleFilter(true);
    }

    const handleOkFilterRole = () => {
        setOpenRoleFilter(false);
    }
  
    const userRoleRowActionIcon = ( userRole: IUserRole) : ActionIconTableRow<IUser,IUserRole> => {
  
        const res: ActionIconTableRow<IUser,IUserRole> = {
          toolTip: 'viewDetails',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: IUserRole) => {
            
            (refRemoveUserRole.current??emptyFunc)(index);            
          }
        }
        return res;
    }

    // --- Group users
    const [headGroupUserCells, setHeadGroupUserCells]  = useState<HeadCell<IGroupUser>[]>([
        {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5, },
        {id:'groupName', label : t('Name'),  display: true, type: 'string', width: 45, },
        {id:'groupDescription', label : t('Description'),  display: true, type: 'string', width: 50, },    
      ]); 
    const refAppendGroupUsers = useRef<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateGroupUser = useRef<(index: number,value: Partial<FieldArray<IUser>> ) => void>(null);
    const refRemoveGroupUser = useRef<(index: number ) => void>(null);

    const handleAddGroups = (event: any) => {
        setOpenGroupFilter(true);
    }

    const handleOkFilterGroup = () => {
        setOpenGroupFilter(false);
    }
  
    const groupUserRowActionIcon = ( userRole: IGroupUser) : ActionIconTableRow<IUser,IGroupUser> => {
  
        const res: ActionIconTableRow<IUser,IGroupUser> = {
          toolTip: 'viewDetails',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: IGroupUser) => {
            
            (refRemoveGroupUser.current??emptyFunc)(index);            
          }
        }
        return res;
    }


    // --- User payment source
    const getUserPaymentSource = (row: IUserPaymentSource, cellId: keyof IUserPaymentSource, 
        opts: {value: string|number, name: string}[]) : {value: string|number, name: string}[]=> {        
                
        const enumsFilter = (refEnumItems.current ?? []); //.filter(x => x.enumerationCode === 'Payment_Source');
      
        return getAsOptions(enumsFilter ,Enum_PAYMENT_SOURCE);
      }
      
    const cellEditableUserPaymentSource = (row: IUserPaymentSource, cellId: keyof IUserPaymentSource) => {   
        return true;
    }

    const [headUserPaymentSourceCells, setHeadUserPaymentSourceCells]  = useState<HeadCell<IUserPaymentSource>[]>([
        {id:'id', label : t('Id'),  display: false, type: 'numeric', width: 5, },
        {id:'paymentSourceCode', label : t('Payment source'),  display: true, type: 'string', width: 60, 
            isEditable: cellEditableUserPaymentSource, getOptions: getUserPaymentSource },
        {id: 'inAllowed', label : t('In allowed'), display: true, type: 'boolean', width: 20, isEditable: cellEditableUserPaymentSource},
        {id: 'outAllowed', label : t('Out allowed'), display: true, type: 'boolean', width: 20, isEditable: cellEditableUserPaymentSource}
        //{id:'groupDescription', label : t('Description'),  display: true, type: 'string', width: 50, },    
      ]); 
    const refAppendUserPaymentSources = useRef<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateUserPaymentSource = useRef<(index: number,value: Partial<FieldArray<IUser>> ) => void>(null);
    const refRemoveUserPaymentSource = useRef<(index: number ) => void>(null);

    const handleAddUserPaymentSources = (event: any) => {
        (refAppendUserPaymentSources.current??emptyFunc)({id: _id, userId: 0, paymentSourceCode: ''});   
    }
  
    const userPaymentSourceRowActionIcon = ( userPaymentSource: IUserPaymentSource) : ActionIconTableRow<IUser,IUserPaymentSource> => {
  
        const res: ActionIconTableRow<IUser,IUserPaymentSource> = {
          toolTip: 'viewDetails',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: IUserPaymentSource) => {
            
            (refRemoveUserPaymentSource.current??emptyFunc)(index);            
          }
        }
        return res;
    }

    // --- User stores
    const [headUserStoreCells, setHeadUserStoreCells]  = useState<HeadCell<IUserStore>[]>([
        {id:'id', label : t('Id'),  display: false, type: 'numeric', width: 5, },
        {id:'storeId', label : t('Store'),  display: false, type: 'numeric', width: 100, },
        {id:'storeName', label : t('Store'),  display: true, type: 'string', width: 100, },
        //{id:'groupDescription', label : t('Description'),  display: true, type: 'string', width: 50, },    
      ]); 
    const refAppendUserStores = useRef<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateUserStore = useRef<(index: number,value: Partial<FieldArray<IUser>> ) => void>(null);
    const refRemoveUserStore = useRef<(index: number ) => void>(null);

    const handleAddUserStores = (event: any) => {
        setOpenUserStoreFilter(true);
    }

    const handleOkFilterUserStore = () => {
        setOpenUserStoreFilter(false);
    }
  
    const userStoreRowActionIcon = ( userStore: IUserStore) : ActionIconTableRow<IUser,IUserStore> => {
  
        const res: ActionIconTableRow<IUser,IUserStore> = {
          toolTip: 'viewDetails',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: IUserStore) => {
            
            (refRemoveUserStore.current??emptyFunc)(index);            
          }
        }
        return res;
    }   

    const handleClickOpenPerson = async (event: any) => {
        setOpenPersonFilter(true);
      }
    
      const handleClickRemovePerson = async (event: any) => {
        setValue('personId', 0);
        setValue('personName', '');        
      }

    const handleClickOkExpression = async () => {

        // if(getValues().userRequestTypes.length == 0 ||  getValues().userRequestTypes.length <= userRequestTypeIndex)
        //     return;

        // const userRequestType = getValues().userRequestTypes[userRequestTypeIndex];

        // const checkExpression = await checkEntityExpressionSyntax(currentEntityName, currentExpression);
        
        // if(!checkExpression){
        //   enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
        //         anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        //   return;
        // }

        // const {syntaxOk, syntaxError, returnType} = checkExpression;
        // if(!syntaxOk) {
        //   enqueueSnackbar( syntaxError , { variant: 'error',
        //         anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        //   return;
        // }        

        // if(returnType !== 'boolean')
        //     enqueueSnackbar( t('The result of expression must be boolean') , { variant: 'error',
        //         anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        //   else
        //     (refUpdateUserRequestType.current??emptyFunc)(userRequestTypeIndex, 
        //         {...userRequestType, accesCondition: currentExpression});

        // setOpenEntityExpression(false);
    }

    const refEnumItems = useRef<IEnumerationItem[]>();    
    useEffect( () => {   
        refEnumItems.current = enumItems;
        
    }, [enumItems]);


    useEffect( () => {                
        setCurrentFormNameAtom(t('User'));
        setCurrentBasicTextFilterProps(basicFilterUser);
      }, []);


      /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
    and the new useEffect will take place ********************/
    useEffect( () => {
        // setCurrentFormName(t('Billing'));        
        setCurrentFormNameAtom(_id>0?`${t('User')} - # ${_id} # -`: t('User') );
        if(_id > 0)
          retrieveData('User',_id, refetch);  
      }, [_id] );


    useEffect( () => {
      
    if(_data && _data.id > 0) {
        
        reset(_data);
    }
    }, [_data]);

  const newData = async (event: MouseEvent<HTMLButtonElement>) => {  
    setCurrentFormNameAtom(t('User'));   
    _setId(0);     
    reset(defaultUser);    
  }
  
  const saveData = async (event: MouseEvent<HTMLButtonElement>) => {   
    
    if(!checkEntitySaveAuthorization('User', _id)) {
        setIsSaveLoading(false);         
        return;
      }

      const data = getValues(); 

      const {password} = data;        

      if(data.userName.trim() === '' || data.userDescription.trim() === '') {
          enqueueSnackbar( t('User name/description is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
        }

    if(data.password.trim() === '') {
        enqueueSnackbar( t('Password is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
        }

    const paymentSourceCodes = data.userPaymentSources.map(x => x.paymentSourceCode);
    if( Array.from(new Set(paymentSourceCodes)).length !== data.userPaymentSources.length) {
        enqueueSnackbar( `${t('Payment source')} - ${t('Invalid data')}`, { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        setIsSaveLoading(false);
        return;
    }

    const storeIds = data.userStores.map(x => x.storeId);
    if( Array.from(new Set(storeIds)).length !== data.userStores.length) {
        enqueueSnackbar( `${t('Store')} - ${t('Invalid data')}`, { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        setIsSaveLoading(false);
        return;
    }


    mutate({...data, isPasswordChanged: password !== '@@@@@@@@' ,
        passwordMD5: md5(password),password: '', userPasswordHistories: [] });
  }

  const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
    openEntityActionDrawer('User', _id);
  }
  
  
  
  const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
  //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
  //    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
  //    reset(_data);        
   }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={1} columnSpacing={0.2}>
                    <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >

                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                {/* <TextField sx={{width:'calc(5% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} />  */}
                                <TextField sx={{width:'calc(12% - 8px)'}} id="userName" label={t('Login')} {...register('userName')} 
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <FormControlLabel sx={{width:'calc(15% - 8px)'}}
                                    label={t('Root user ?')}
                                    control={
                                    <Controller
                                        name='isRootUser'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                    />}                                
                                />
                                <TextField sx={{width:'calc(13% - 8px)'}} label={t('Password')} {...register('password')} 
                                    type="password" id="password" autoComplete="current-password"
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                
                                <TextField sx={{width:'calc(20% - 8px)'}} id="userDescription" label={t('User description')} {...register('userDescription')} />
                                <TextField sx={{width:'calc(10% - 8px)'}} id="alias" label={t('Alias')} {...register('alias')} />
                                <FormControlLabel sx={{width:'calc(10% - 8px)'}}
                                    label={t('Active ?')}
                                    control={
                                    <Controller
                                        name='isActive'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                    />}                                
                                />
                                <Controller control={control}
                                    name='activeFrom' 
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <DatePicker label={t('Start date')} 
                                        onChange={onChange}                     
                                        value={new Date(value)}
                                        slotProps={{ textField: { sx: {width:'calc(10% - 8px)'}  }} }
                                        //renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                      /> )}
                                  />
                                  <Controller control={control}
                                    name='activeTo' 
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <DatePicker label={t('End date')} 
                                        onChange={onChange}                     
                                        value={new Date(value)}
                                        slotProps={{ textField: { sx: {width:'calc(10% - 8px)'}  }} }
                                        //renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                      /> )}
                                  />
                                {/* <Controller name='intermediaryCode' control={control} 
                                    defaultValue={ (intermediaries || []).length === 1 ? (intermediaries || [])[0].code : '' }
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="intermediaryCode"
                                        label={t('Intermediary')} inputProps={ {readOnly: false}} >
                                        {intermediaries.map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />  */}
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <TextField sx={{width:'calc(20% - 8px)'}} id="personId" label={t('Identity')} 
                                    {...register('personName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">  
                                        { (isFalsy(watchPersonId) ||  watchPersonId<=0) ?
                                            <IconButton color="primary" onClick={handleClickOpenPerson}>
                                                <ArrowDropDownCircleIcon />
                                            </IconButton> : 
                                            <IconButton color="primary" onClick={handleClickRemovePerson}>
                                                <RemoveCircleIcon />
                                            </IconButton>
                                        }                                                                                                                                        
                                    </InputAdornment>
                                    ) 
                                }} />
                                <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                    label={`${t('Extract and export data')}?`}
                                    control={
                                    <Controller
                                        name='isExtractAndExportAllowed'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                    />}                                
                                />
                                <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                    label={`${t('Password never expired?')}`}
                                    control={
                                    <Controller
                                        name='passwordNeverExpired'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                    />}                                
                                />                                
                                <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Should reset password?')}`}
                                    control={
                                    <Controller
                                        name='shouldResetPassword'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                    />}                                
                                />
                                { openPersonFilter && <FormDialog open={openPersonFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonFilter(false);}} onOk={()=> {setOpenPersonFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPerson } />
                              </FormDialog> }
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={4} component={Paper} >
                        <Stack flexDirection='column'>
                            <Box sx={{ mt: 1, width: '100%' }} key={`userRequestTypes-${getValues().id}-${getValues().userRoles.length}`} >
                                <ArrayFieldTableEx<IUser,IUserRole,'id'> 
                                    mainObject={getValues()} fieldKey='id' 
                                    headCells={headUserRoleCells} rowsPathName='userRoles' 
                                    title={t('Roles')} rowActionIcon={userRoleRowActionIcon}  
                                    //onRowSelected={handleRoleEntitySelected}
                                                        
                                    refAppend={refAppendUserRoles as MutableRefObject<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateUserRole as MutableRefObject<(index: number,value: Partial<FieldArray<IUser>>) => void>}
                                    refRemove={refRemoveUserRole as MutableRefObject<(index: number) => void>}

                                    //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                    //displayMore={undefined}
                                    toolbarActions={[
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddRoles ,icon: AddCircleIcon,  },
                                    
                                    ]}
                                    canCheckRow={false} //canFilterColumn={false} canDisplayColumnHeader={false}
                                />
                                { openRoleFilter && <FormDialog open={openRoleFilter} maxWidth='sm'
                                    okText={t('OK')} cancelText='' title={t('Role filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenRoleFilter(false);}} onOk={handleOkFilterRole}  >
                                        <BasicTextFilterForm<IRole> {...basicFilterRole } />
                                </FormDialog> }
                            </Box>
                            <Box sx={{ mt: 4, width: '100%' }} >
                                
                            </Box>
                        </Stack> 
                    </Grid>
                    <Grid item xs={12} md={4} component={Paper} >
                        <Stack flexDirection='column'>
                            <Box sx={{ mt: 1, width: '100%' }} key={`userRequestTypes-${getValues().id}-${getValues().groupUsers.length}`}>
                                <ArrayFieldTableEx<IUser,IGroupUser,'id'> 
                                    mainObject={getValues()} fieldKey='id' 
                                    headCells={headGroupUserCells} rowsPathName='groupUsers' 
                                    title={t('Groups')} rowActionIcon={groupUserRowActionIcon}  
                                    //onRowSelected={handleRoleEntitySelected}
                                                        
                                    refAppend={refAppendGroupUsers as MutableRefObject<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateGroupUser as MutableRefObject<(index: number,value: Partial<FieldArray<IUser>>) => void>}
                                    refRemove={refRemoveGroupUser as MutableRefObject<(index: number) => void>}

                                    //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                    //displayMore={undefined}
                                    toolbarActions={[
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddGroups ,icon: AddCircleIcon,  },
                                    
                                    ]}
                                    canCheckRow={false} //canFilterColumn={false} canDisplayColumnHeader={false}
                                />
                                { openGroupFilter && <FormDialog open={openGroupFilter} maxWidth='sm'
                                    okText={t('OK')} cancelText='' title={t('Group filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenGroupFilter(false);}} onOk={handleOkFilterGroup}  >
                                        <BasicTextFilterForm<IGroup> {...basicFilterGroup } />
                                </FormDialog> }  
                                { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                okText={t('OK')} cancelText='' title={t('Request type access')} onCancel={()=> {}} 
                                onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                <EntityExpression entityName={currentEntityName} properties={[]} 
                                    expression={currentExpression} setExpression={setCurrentExpression} />
                                </FormDialog>  }                               
                            </Box>
                        </Stack> 
                    </Grid>
                    <Grid item xs={12} md={4} component={Paper} >
                        <Stack flexDirection='column'>
                            <Box sx={{ mt: 1, width: '100%' }} key={`userPaymentSources-${getValues().id}-${getValues().groupUsers.length}`}>
                                <ArrayFieldTableEx<IUser,IUserPaymentSource,'id'> 
                                    mainObject={getValues()} fieldKey='id' 
                                    headCells={headUserPaymentSourceCells} rowsPathName='userPaymentSources' 
                                    title={`${t('Payment source')}(s)`} rowActionIcon={userPaymentSourceRowActionIcon}  
                                    //onRowSelected={handleRoleEntitySelected}
                                                        
                                    refAppend={refAppendUserPaymentSources as MutableRefObject<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateUserPaymentSource as MutableRefObject<(index: number,value: Partial<FieldArray<IUser>>) => void>}
                                    refRemove={refRemoveUserPaymentSource as MutableRefObject<(index: number) => void>}

                                    //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                    //displayMore={undefined}
                                    toolbarActions={[
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddUserPaymentSources ,icon: AddCircleIcon,  },
                                    
                                    ]}
                                    canCheckRow={false} //canFilterColumn={false} canDisplayColumnHeader={false}
                                />                                                               
                            </Box>
                            <Box sx={{ mt: 2, width: '100%' }} key={`userStores-${getValues().id}-${getValues().groupUsers.length}`}>
                                <ArrayFieldTableEx<IUser,IUserStore,'id'> 
                                    mainObject={getValues()} fieldKey='id' 
                                    headCells={headUserStoreCells} rowsPathName='userStores' 
                                    title={`${t('Store')}(s)`} rowActionIcon={userStoreRowActionIcon}  
                                    //onRowSelected={handleRoleEntitySelected}
                                                        
                                    refAppend={refAppendUserStores as MutableRefObject<(value: Partial<FieldArray<IUser>> | Partial<FieldArray<IUser>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateUserStore as MutableRefObject<(index: number,value: Partial<FieldArray<IUser>>) => void>}
                                    refRemove={refRemoveUserStore as MutableRefObject<(index: number) => void>}

                                    //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                    //displayMore={undefined}
                                    toolbarActions={[
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddUserStores ,icon: AddCircleIcon,  },
                                    
                                    ]}
                                    canCheckRow={false} //canFilterColumn={false} canDisplayColumnHeader={false}
                                />
                                { openUserStoreFilter && <FormDialog open={openUserStoreFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Store')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenUserStoreFilter(false);}} onOk={handleOkFilterUserStore}  >
                                        <BasicTextFilterForm<IStore> {...basicFilterStore } />
                                </FormDialog> }  
                                                             
                            </Box>
                        </Stack> 
                    </Grid>
                    
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

