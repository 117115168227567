import React, {FC, PropsWithChildren, useEffect, useRef, useState} from 'react';

// import library.
import { useFieldArray, useForm, useFormContext, Controller, FormProvider, FieldArrayWithId, ArrayPath } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import NumberFormat from 'react-number-format';
// mui ...
import {Avatar, Button, Grid, Typography, Link, Box, Paper, TextField, 
    MenuItem, IconButton, Checkbox, FormControlLabel, Stack } from '@mui/material';

import { ThreeDots, ThreeCircles  } from 'react-loader-spinner';


import { FaSearch } from 'react-icons/fa';
import { pink } from '@mui/material/colors';

import { GrFormPreviousLink, GrFormNextLink } from 'react-icons/gr';

import { DataType } from 'features/configuration/models/ExtensionType';
import { DatePicker } from '@mui/x-date-pickers';
import { IFeatureDescription } from 'library/interface';
import { defaultEnumerationItem, IEnumerationItem } from './models/Enumeration';
import { typographyGroupBoxStyling } from 'themes/commonStyles';
import { FormDialog } from 'components/ui/FormDialog';
import { Title } from '@mui/icons-material';






export interface DialogEnumerationItemFormProps {
    
    open: boolean,
    title: string,
    
    enumerationItem: IEnumerationItem,
    saveEnumerationItem: (enumItem: IEnumerationItem) => Promise<boolean>,
    hideEnumerationItemDialog: () => void,

    afterSave?: () => Promise<void>,
}

export const defaultDialogEnumerationItemFormProps: DialogEnumerationItemFormProps = {
    open: false,
    title: '',
    enumerationItem: defaultEnumerationItem,
    saveEnumerationItem: async (enumItem: IEnumerationItem) => await false,
    hideEnumerationItemDialog: () => {}
}

export function  DialogEnumerationItemForm(props : DialogEnumerationItemFormProps = defaultDialogEnumerationItemFormProps)  {
    
    const {open, title, enumerationItem, saveEnumerationItem, hideEnumerationItemDialog, afterSave} = props;
    const { t, i18n } = useTranslation();
        
    const methods = useForm<IEnumerationItem>({defaultValues: {...enumerationItem}});
    const { register, setValue, getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;
            
    return ( 
    <FormDialog open={open} maxWidth='sm'
        okText={t('Save')} cancelText={t('Cancel')} title={`${t('Codification item')} :: ${title}`} onCancel={()=> {hideEnumerationItemDialog();}} 
        onClose={() => {hideEnumerationItemDialog();}} 
        onOk={async () => {
            if(await saveEnumerationItem( getValues() )){
                if(afterSave) afterSave();
            }}}
        >
        <FormProvider {...methods} > 
            <Grid container component="main" sx={{  width: '100%' }} alignItems="flex-start">
                <Grid item xs={12} sm={12} md={12} component={Paper}  sx={{ borderRadius: 2, ml: 0, }}>
                    <Stack flexDirection='column' sx={{ width: '100%' }} >
                        <Box sx={{ mt: 2.25, width: '100%' }} > 
                          <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                sx={{...typographyGroupBoxStyling}}>
                            {`${t('Codification')} ...`}
                          </Typography>                                                       
                        </Box>                        
                        <Box sx={{ mt: 1, width: '100%' }} > 
                            <TextField sx={{width:'calc(30% - 8px)'}} id="enumerationCode" label={t('Code')} 
                                {...register('enumerationCode')} 
                                inputProps={ { readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                            />
                            <TextField sx={{width:'calc(70% - 8px)'}} id="enumerationName" label={t('Name')} 
                                {...register('enumerationName')} 
                                inputProps={ { readOnly: true,autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                            />
                        </Box>
                        <Box sx={{ mt: 1, mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }} >
                            <TextField sx={{width:'calc(100% - 8px)'}} id="enumerationDescription" label={t('Description')} 
                                {...register('enumerationDescription')} 
                                inputProps={ { readOnly: true,autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                            />                            
                        </Box>      
                        <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                sx={{...typographyGroupBoxStyling}}>
                            {`${t('Item')} ...`}
                          </Typography>
                        <Box sx={{ mt: 1, width: '100%' }} > 
                            <TextField sx={{width:'calc(30% - 8px)'}} id="name" label={t('Code')} 
                                {...register('code')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                            />
                            <TextField sx={{width:'calc(70% - 8px)'}} id="name" label={t('Name')} 
                                {...register('name')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                            />
                        </Box>
                        <Box sx={{ mt: 1, mb: 1, width: '100%', display: 'flex', justifyContent: 'center' }} >
                            <TextField sx={{width:'calc(100% - 8px)'}} id="name" label={t('Description')} 
                                {...register('description')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                            />
                            
                        </Box>                  
                    </Stack>  
                </Grid>
                <Grid item xs={12} component={Paper} elevation={3} square>                
                    
                    
                </Grid>  
            </Grid>
        </FormProvider> 
    </FormDialog> 
    ); 
}


