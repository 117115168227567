

export interface ICertificateAllocation {
    id: number,
    certificateTemplateId: number,
    intermediaryCode: string,
    status: string,
    statusDate: Date,
    reference: string,
    startNumber: number,
    endNumber: number,

    certificatCount: number
  }


  export const defaultCertificateAllocation : ICertificateAllocation = {
    id: 0,
    certificateTemplateId: 0,
    intermediaryCode: '',
    status: '95',
    statusDate: new Date(),
    reference: '',
    startNumber: 0,
    endNumber: 0,  

    certificatCount: 0
  }

  export interface ICertificateAllocationSearch {
    
    type: string,
    intermediaryCode: string,
    reference: string
  }