
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import colorNameList from 'color-name-list';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import BackpackIcon from '@mui/icons-material/Backpack';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';

import { ChromePicker, ColorResult } from 'react-color';



import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom, currentUserSessionAtom } from 'library/store';
import useProductService, { useBasicFilterProduct, useBasicFilterProductSearch, useBasicFilterStoreProduct } from './services/Product';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, Popover, useTheme } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IFormationBillingType, IPackagingProduct, IProduct, IProductOption, IRentalResource, IServiceTask, IStoreProduct, IStoreProductOption, ProductType, defaultProduct, defaultProductPrice, defaultStoreProduct } from './models/Product';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

import useDeliveryService from 'features/configuration/services/Delivery';

import useUtils from 'library/utils';

import IEnumeration, { Enum_LINE_OF_BUSINESS, Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, 
      Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ,IEnumerationItem, Enum_ARTICLE_WRAPPING, 
      Enum_RENTAL_PERIODICITY, Enum_CURRENCY,
      Enum_CONSUMPTION_OPTION_CLASS} from 'features/configuration/models/Enumeration';

import {ISharing} from 'features/setup/models/Sharing';
import useSharingService, { useBasicFilterSharing } from 'features/setup/services/Sharing';

import { defaultExtension, IExtension, IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling, carouselImage } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { debounce, sum } from 'lodash';
import { GrClose, GrSearch } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';
import { IArticleStock } from 'features/configuration/models/Delivery';
import { useBasicFilterResource } from 'features/production/services/Resource';
import { IResource } from 'features/production/models/Resource';
import BasicImportForm from 'components/ui/BasicImportForm';
import { ExtensionFormDialog } from 'components/ExtensionFormDialog';
import { IFamilyProduct } from './models/FamilyProduct';
import { useBasicFilterFamilyProduct } from './services/FamilyProduct';
import useStoreService, { useBasicFilterStore } from 'features/configuration/services/Store';
import { defaultStore, IStore } from 'features/configuration/models/Store';



export const StoreProductForm: FC<IStoreProduct> = (props: IStoreProduct = defaultStoreProduct) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { applicationSetup, userStores} = useRecoilValue(currentUserSessionAtom);

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const {getArticlePerStores } = useDeliveryService();

  const { saveStoreProduct, createBasicImportProduct, getStoreProducts, getStoreProduct } = useProductService();
  //const {getStores } = useStoreService();

  const {capitalizeFirstLetter} = useUtils();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const colors = colorNameList.filter(c => c.name.split(' ').length === 1 && c.name.length <= 5).map((color) => color);
  
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterStoreProduct = useBasicFilterStoreProduct( 0,
    (event: React.MouseEvent<unknown>, row: IStoreProduct) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}

  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  // const [store, setStore] = useState<IStore>(defaultStore);
  // const [product, setProduct] = useState<IProduct>(defaultProduct);
  
  const [openProductFilter, setOpenProductFilter] = useState(false);
  const basicFilterProduct = useBasicFilterProduct( 0,
      async (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, name, description} = row;
          
          //const prod = await retrieveEntity('Product', id) as IProduct;
          
          const storeProduct = await getStoreProduct(watchStoreId, id); // id is productId

          setValue('productId', id);
          setValue('productName', name);
          //setProduct(prod);

          const st = store ? store! : defaultStore;

          const currencyCode = st.storeCurrencies.some(c => c.currencyCode === applicationSetup.defaultCurrencyCode)?
            applicationSetup.defaultCurrencyCode:st.storeCurrencies.length>0 ? st.storeCurrencies[0].currencyCode:'';

          if(isFalsy(storeProduct)) {
            reset({...defaultStoreProduct, productId: id, storeId: watchStoreId, productName: name,
              currentCurrencyCode: currencyCode });
            initStoreProduct(currencyCode);
          } else
            _setId(storeProduct.id);          

          setOpenProductFilter(false);
      }
  );


  const [openStoreFilter, setOpenStoreFilter] = useState(false);
  const basicFilterStore = useBasicFilterStore( 
      (event: React.MouseEvent<unknown>, row: IStore) => {
          const {id, name, description} = row;

                                     
          setOpenStoreFilter(false);
      }
  );
  
  const methods = useForm<IStoreProduct>({defaultValues: {...defaultStoreProduct, 
    currentCurrencyCode: applicationSetup.defaultCurrencyCode, }});

  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  let { fields: productPrices, append: appendProductPrices, update: updateProductPrice ,  } = useFieldArray({ name: 'productPrices', control, });

  // const watchIsTaxable = watch('isTaxable');
  // const watchIsDefaultTaxFormula = watch('isDefaultTaxFormula');

     
  const watchProductType = watch('productType');    
  
  // const watchName = watch('name');    
  // const watchDescription = watch('description');    

  const watchCurrentCurrencyCode = watch('currentCurrencyCode');
  const watchProductId = watch('productId');
  const watchStoreId = watch('storeId');

  

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState<string>('#000');

  // const watchParentProductId = watch('parentProductId');

  // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IStoreProduct>,Error,IStoreProduct>(
        saveStoreProduct, {   
        onSuccess: (data: IResult<IStoreProduct>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Product')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Product',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

      // const {isLoading: isLoadingBasicImport, isError: isErrorBasicImport, error: errorBasicImport,mutate: mutateBasicImport } = useMutation<IResult<boolean>,Error,IProduct[]>(
      //   createBasicImportProduct, {   
      //     onSuccess: (data: IResult<boolean>) => {
      //       enqueueSnackbar( t('_Operation_done'), { variant: 'success',
      //             anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                     
      //       setIsSaveLoading(false);
      //       //_setId(data.data.id);
      //       //setCurrentEntityIdForAction(data.data.id);
            
      //       //queryClient.invalidateQueries(['Product',data.data.id]);
      //     },
      //     onError: (err: Error) => {          
      //       enqueueSnackbar( error?.message, { variant: 'error',
      //             anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      //       setIsSaveLoading(false);
      //     }
      //   });

    const {data: _data, refetch} = useQuery<IStoreProduct>(['StoreProduct', _id], () => retrieveEntity('StoreProduct',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Product'], () => 
                getEnumerationItemsByEnumerationCodes( [Enum_CURRENCY ] ));

    const {data: product} = useQuery<IProduct>( ['StoreProduct', 'Product', getValues().productId], () => 
        retrieveEntity('Product',getValues().productId), {refetchOnWindowFocus: false ,enabled: getValues().productId > 0 } );

    const {data: store} = useQuery<IStore>( ['StoreProduct', 'Store', getValues().storeId], () => 
        retrieveEntity('Store',getValues().storeId), {refetchOnWindowFocus: false ,enabled: getValues().storeId > 0 } );

      //const {data: stores} = useQuery<IStore[]>( ['stores'], () => getStores( { name: '', description: '', location: ''} ));


      const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);

      const handleTabCoverageChange = (event: React.SyntheticEvent, newValue: number) => {  
        
        setValueTabCoverage(newValue);       
      };
   
    

    const handleCurrentCurrency = ( event: React.MouseEvent<HTMLElement>, newCurencyCode: string ) => {

      if(newCurencyCode === null) return;
      setValue('currentCurrencyCode', newCurencyCode);
    };

    
      const [openEntityExpression, setOpenEntityExpression] = useState(false);
      const [currentExpression, setCurrentExpression] = useState(''); 
      const [currentEntityName, setCurrentEntityName] = useState(''); 
      const [currentReturnType, setCurrentReturnType] = useState(''); 

      type ExpressionType = 'price' | 'tax' | 'max-discount' | 'max-loaded' | 'price-unity' | 'customer-point' | 'service-point' | 'packaging-service-point';
      const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('price');

      const handleClickOpenExpression = (event: any, expressionType: ExpressionType, 
          entityName: string, returnType: string, expression: string) => {

        setCurrentExpressionType(expressionType);
        setCurrentEntityName(entityName);
        setCurrentReturnType(returnType);
        setCurrentExpression(expression);
        setOpenEntityExpression(true);
      }

      const handleClickOkExpression = async () => {

        const checkExpression = await checkEntityExpressionSyntax(currentEntityName, currentExpression);
        if(!checkExpression){
          enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        const {syntaxOk, syntaxError, returnType} = checkExpression;
        if(!syntaxOk) {
          enqueueSnackbar( syntaxError , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }
       
        if(returnType !== currentReturnType) {
          enqueueSnackbar( `${t('The result of expression must be')} ${currentReturnType}` , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
                return;
        }
      
        
        if( ['price', 'tax', 'max-discount', 'max-loaded' ,'price-unity', 'customer-point', 'service-point'].includes(currentExpressionType)  ) {

          const idx = getValues().productPrices.findIndex(p => p.currencyCode === watchCurrentCurrencyCode);

          const priceExpression = currentExpressionType === 'price' ? currentExpression: getValues().productPrices[idx].priceExpression;
          const taxExpression = currentExpressionType === 'tax' ? currentExpression: getValues().productPrices[idx].taxExpression;
          const maxDiscountExpression = currentExpressionType === 'max-discount' ? currentExpression: getValues().productPrices[idx].maxDiscountExpression;
          const maxLoadedExpression = currentExpressionType === 'max-loaded' ? currentExpression: getValues().productPrices[idx].maxLoadedExpression;
          //const priceExpressionUnity = currentExpressionType === 'price-unity' ? currentExpression: getValues().priceExpressionUnity;
          
          setValue(`productPrices.${idx}.priceExpression`, priceExpression);
          setValue(`productPrices.${idx}.taxExpression`, taxExpression);
          setValue(`productPrices.${idx}.maxDiscountExpression`, maxDiscountExpression);
          setValue(`productPrices.${idx}.maxLoadedExpression`, maxLoadedExpression);
          //setValue('priceExpressionUnity', priceExpressionUnity);
        } 

        setOpenEntityExpression(false);
      }

            
      const handleClickOpenProduct = (event: any) => {           
        if( watchStoreId <= 0) return;

        setOpenProductFilter(true);
      }
          
      const cellEditableProductOption = (row: IStoreProductOption, cellId: keyof IStoreProductOption) => {
        return true;
      }
      
      const [headStoreProductOptionCells, setHeadStoreProductOptionCells]  = useState<HeadCell<IStoreProductOption>[]>([      
        //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
        {id:'productOptionCode', label : t('Code'),  display: true, type: 'string', width: 35},
        {id:'productOptionName', label : `${t('Name')}`,  display: true, type: 'string', width: 50},       
        
        {id:'isAvailable', label : `${t('Is available ?')}`,  display: true, type: 'boolean', width: 15, isEditable: cellEditableProductOption},       
              
      ]);
  
      const refAppendProductOptions = useRef<(value: Partial<FieldArray<IStoreProduct>> | Partial<FieldArray<IStoreProduct>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateProductOption = useRef<(index: number,value: Partial<FieldArray<IStoreProduct>> ) => void>(null);
      const refRemoveProductOption = useRef<(index: number ) => void>(null);
        
      const [selectedFilterProductOptions, setSelectedFilterProductOptions] = useState<number[]>([]);
      const [openProductOptions, setOpenProductOptions] = useState<boolean>(false);
      const handleAddProductOptions = (event: any) => {
        setOpenProductOptions(true);
        // (refAppendProductOptions.current??emptyFunc)({id: 0,  productId: 0,
        //   code: '', name: '', description: '', isActive: true, fileName: '', base64Picture: '', hasPicture: false});
      }

      const handleClickOkProductOptions = (event : MouseEvent<HTMLButtonElement>) => {

        const prd = product ? product! : defaultProduct;

        const arr = prd.productOptions.filter(x => selectedFilterProductOptions.includes(x.id) && 
                              !getValues().storeProductOptions.some(i => x.id === i.productOptionId )).map(x => 
                              ({storeProductId: _id, productOptionId: x.id, isAvailable: true, 
                                productOptionCode: x.code, productOptionName: x.name }) );        

        (refAppendProductOptions.current??emptyFunc)( arr );
        setOpenProductOptions(false);
      }

      const handleDoubleClickProductOption = (event: React.MouseEvent<unknown>, row: IProductOption) => {

        if( getValues().storeProductOptions.some(i => row.id === i.productOptionId )) {
          setOpenProductOptions(false);
          return;        
        }

        (refAppendProductOptions.current??emptyFunc)( {storeProductId: _id, productOptionId: row.id, isAvailable: true, 
          productOptionCode: row.code, productOptionName: row.name} );
        setOpenProductOptions(false);
      } 
  
      const productOptionRowActionIcon = ( productOption: IProductOption) : ActionIconTableRow<IProduct,IProductOption> => {
    
        const res: ActionIconTableRow<IProduct,IProductOption> = {
          toolTip: 'remove',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: IProductOption) => {
            
             (refRemoveProductOption.current??emptyFunc)(index);            
          }
        }
        return res;
    }
    


useEffect( () => {

  if(watchStoreId > 0 && watchProductId > 0)
    initStoreProduct(watchCurrentCurrencyCode);
  
}, [ watchCurrentCurrencyCode])

const initStoreProduct = (currencyCode: string) => {
  const vProduct = product ? product! : defaultProduct;

  if(isFalsy(currencyCode)) return;
       
  const storeProductPrice = getValues().productPrices.find(p => p.currencyCode === currencyCode);
  
  if(isFalsy(storeProductPrice)){
    
    const productPrice = vProduct.productPrices.find(x => x.currencyCode === currencyCode);

    appendProductPrices( {...defaultProductPrice, 
        defaultPrice: productPrice?.defaultPrice || 0,
        defaultMaxDiscount: productPrice?.defaultMaxDiscount || 0,
        defaultMaxLoaded: productPrice?.defaultMaxLoaded || 0,

        priceExpression: productPrice?.priceExpression || '',
        maxDiscountExpression: productPrice?.maxDiscountExpression || '',
        maxLoadedExpression: productPrice?.maxLoadedExpression || '',

        isTaxable: productPrice?.isTaxable || true,
        isDefaultTaxFormula: productPrice?.isDefaultTaxFormula || true,

        taxExpression: productPrice?.taxExpression || '',
        
        currencyCode });
  }
}


useEffect( () => {
  async function _() {   

    console.log({watchStoreId, watchProductId, data: getValues()});
    if(watchStoreId <= 0) return;
    
    if(watchProductId > 0) {
      const storeProduct = await getStoreProduct(watchStoreId, watchProductId); // id is productId

      const st = store ? store! : defaultStore;

      const currencyCode = st.storeCurrencies.some(c => c.currencyCode === applicationSetup.defaultCurrencyCode)?
            applicationSetup.defaultCurrencyCode:st.storeCurrencies.length>0 ? st.storeCurrencies[0].currencyCode:'';

      const prd = product ? product! : defaultProduct;

      if(isFalsy(storeProduct)) {
        reset({...defaultStoreProduct, productId: prd.id, storeId: watchStoreId, productName: prd.name,
          currentCurrencyCode: currencyCode });
        initStoreProduct(currencyCode)
      } else
        _setId(storeProduct.id);  
    }
  }

  _();
  
  }, [ watchStoreId])

    const refEnumItems = useRef<IEnumerationItem[]>([]);    
    useEffect( () => {   
        refEnumItems.current = (enumItems || []);
      
    }, [enumItems])

    
      useEffect( () => {        
        setCurrentFormNameAtom(t('Product of store'));
        setCurrentBasicTextFilterProps(basicFilterStoreProduct);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('Product of store')} - # ${_id} # -`: t('Product of store') );
            if(_id > 0)
              retrieveData('StoreProduct',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset( {..._data, currentCurrencyCode: applicationSetup.defaultCurrencyCode,              
            } as unknown as IProduct );
          }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        setCurrentFormNameAtom( t('Product of store') );
        
        reset({...defaultProduct, currentCurrencyCode: applicationSetup.defaultCurrencyCode});    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('StoreProduct', _id)) {
          setIsSaveLoading(false);
             return;
        }          
  
        const data = getValues(); 

        if( data.productId <= 0 || data.storeId <= 0) {
          enqueueSnackbar( t('Invalid data !!!'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }
            
        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('StoreProduct', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

    const [productsBasicImport, setProductsBasicImport] = useState<IProduct[]>([]);
    const [openBasicImportForm, setOpenBasicImportForm] = useState<boolean>(false);
    const basicImportData = async (event: MouseEvent<HTMLButtonElement>) => {          
      setOpenBasicImportForm(true); 
    }

    // const handleBasicImportSave = (event: any) => {

    //   if(productsBasicImport.length === 0) {
    //     enqueueSnackbar( t('There is no data to import'), { variant: 'warning',
    //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
    //     return;
    //   }

    //   if(!checkEntitySaveAuthorization('Product', _id)) {
    //     enqueueSnackbar( t('You can not perform this action'), { variant: 'warning',
    //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
    //     return;
    //   }

    //   if(productsBasicImport.some(p => isFalsy(p.name) || isFalsy(p.description) ) ) {
    //     enqueueSnackbar( t('There are some product without name or description'), { variant: 'warning',
    //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
    //     return;
    //   }

    //   if(productsBasicImport.some(p => isFalsy(p.lineOfBusinessCode) ) ) {
    //     enqueueSnackbar( t('There are some product without line of business'), { variant: 'warning',
    //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
    //     return;
    //   }

    //   const badLineOfBusiness = productsBasicImport.filter( p => !refEnumItems.current.some( e => 
    //         e.enumerationCode === Enum_LINE_OF_BUSINESS && e.code === p.lineOfBusinessCode  ) );

    //   if(badLineOfBusiness.length !== 0 ) {
    //     enqueueSnackbar( `${t('There are some product with bad Line of business')} : ${badLineOfBusiness[0].name}` , { variant: 'warning',
    //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
    //     return;
    //   }

    //   mutateBasicImport(productsBasicImport);
    // }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Button id='btnBasicImport' onClick={basicImportData} sx={ {display:'none'}}  />
                                
                                <Controller 
                                  name='storeId' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="storeId"
                                      label={` ${t('Store')} ::`} inputProps={ {readOnly: false}} >
                                      {userStores && userStores.map( 
                                        (x,idx) => <MenuItem key={x.storeId} value={x.storeId}>{x.storeName}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                                />    
                                <TextField key={`productName : ${watchProductId}`}
                                  sx={{width:'calc(50% - 8px)'}} id="productName" label={t('Product')} 
                                  {...register('productName')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none',} } } 
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenProduct}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}  /> 
                                   
                                { openProductFilter && <FormDialog open={openProductFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Product')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenProductFilter(false);}} onOk={()=> {setOpenProductFilter(false);}}  >
                                      <BasicTextFilterForm<IProduct> {...basicFilterProduct } />
                              </FormDialog> }                                                  
                            </Box>   
                                                                                    
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Price'))} -/- ${t(('Discount'))} -/- ${t(('Loaded'))}`}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ToggleButtonGroup value={watchCurrentCurrencyCode} exclusive key={watchCurrentCurrencyCode} 
                                size="small" onChange={handleCurrentCurrency} aria-label="text alignment" fullWidth >
                                  {store && store.storeCurrencies.map( (x,idx) =>                                           
                                    <ToggleButton key={`${x.currencyCode} - ${x.currencyCode}`} value={x.currencyCode} aria-label="centered">
                                      {x.currencyCode}
                                    </ToggleButton> )
                                  }                                 
                              </ToggleButtonGroup>  
                            </Box>
                            {
                              getValues().productPrices.map( (productPrice, index) => {
                              
                                const { currencyCode, defaultPrice, defaultMaxDiscount, defaultMaxLoaded, 
                                    priceExpression, maxDiscountExpression, isTaxable, isDefaultTaxFormula} = productPrice;
                                
                              return (currencyCode === watchCurrentCurrencyCode) && (
                              <Fragment key={`key-${index}-${currencyCode}-${defaultPrice}-${defaultMaxDiscount}-${defaultMaxLoaded}-${priceExpression}-${maxDiscountExpression}`}>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <FormControlLabel sx={{width:'calc(35% - 8px)'}}
                                      label={`${t('Taxable ?')}`}
                                      
                                      control={
                                      <Controller
                                          name={`productPrices.${index}.isTaxable`}
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} />
                                  {isTaxable && <FormControlLabel sx={{width:'calc(65% - 8px)'}}
                                      label={`${t('Default tax formula ?')} : net*tx/100`}
                                      
                                      control={
                                      <Controller
                                          name={`productPrices.${index}.isDefaultTaxFormula`}
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox disabled {...props} checked={value} onChange={onChange} />}                        
                                  />} />  }                                                                                                  
                                </Box>
                                <Box sx={{ mt: 0.25, width: '100%' }} > 
                                  <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat    
                                          label={`${t('Default')} - ${t('Price')}`} sx={{width:'calc(40% - 8px)'}} disabled={false}
                                          allowEmptyFormatting={false}
                                          control={control}    
                                          thousandSeparator={true}
                                          decimalScale={4}
                                          onValueChange={ (v) => onChange(v.floatValue) }
                                          defaultValue={value}
                                          value={value}
                                          customInput={TextFieldRight}                            
                                        />
                                      );
                                    }}
                                    name={`productPrices.${index}.defaultPrice`}
                                    control={control}
                                  />      
                                  <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat    
                                          label={`${t('Default')} - ${t('Max. discount')}`} sx={{width:'calc(30% - 8px)'}} disabled={false}
                                          allowEmptyFormatting={false}
                                          control={control}    
                                          thousandSeparator={true}
                                          decimalScale={4}
                                          onValueChange={ (v) => onChange(v.floatValue) }
                                          defaultValue={value}
                                          value={value}
                                          customInput={TextFieldRight}                            
                                        />
                                      );
                                    }}
                                    name={`productPrices.${index}.defaultMaxDiscount`}
                                    control={control}
                                  />       
                                  <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat    
                                          label={`${t('Default')} - ${t('Max. loaded')}`} sx={{width:'calc(30% - 8px)'}} disabled={false}
                                          allowEmptyFormatting={false}
                                          control={control}    
                                          thousandSeparator={true}
                                          decimalScale={4}
                                          onValueChange={ (v) => onChange(v.floatValue) }
                                          defaultValue={value}
                                          value={value}
                                          customInput={TextFieldRight}                            
                                        />
                                      );
                                    }}
                                    name={`productPrices.${index}.defaultMaxLoaded`}
                                    control={control}
                                  />                                          
                                </Box> 
                                  
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller 
                                      name={`productPrices.${index}.priceExpression`}
                                      control={control}
                                      render={({ field }) => <TextField sx={{width:'calc(100% - 8px)'}} id="priceExpression" label={`${t('Expression')} - ${t('Price')}`} 
                                      multiline rows={2} 
                                      inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                        InputProps={{
                                          readOnly: true,
                                          endAdornment: (
                                            <InputAdornment position="end">                                            
                                              <IconButton color="primary" onClick={(event) =>
                                                  handleClickOpenExpression(event, 'price', `Billing${capitalizeFirstLetter(watchProductType)}`,
                                                              'numeric',getValues().productPrices[index].priceExpression)}>
                                                <DeveloperModeIcon />
                                              </IconButton>                                                                                               
                                          </InputAdornment>
                                        ) 
                                      }}
                                    />}
                                  />                                                                                                                                      
                                </Box>
                              
                                {!isDefaultTaxFormula && <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller 
                                                                        
                                      name={`productPrices.${index}.taxExpression`}
                                      control={control}
                                      render={({ field }) => 
                                        <TextField sx={{width:'calc(100% - 8px)'}} id="taxExpression" label={`${t('Expression')} - ${t('Tax')}`} 
                                      multiline rows={2}
                                        inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                        InputProps={{
                                          readOnly: true,
                                          endAdornment: (
                                            <InputAdornment position="end">                                            
                                              <IconButton color="primary" onClick={(event) =>
                                                  handleClickOpenExpression(event, 'tax', `Billing${capitalizeFirstLetter(watchProductType)}`,
                                                              'numeric',getValues().productPrices[index].taxExpression)}>
                                                <DeveloperModeIcon />
                                              </IconButton>                                                                                               
                                          </InputAdornment>
                                        ) 
                                      }}
                                    />}
                                  />

                                                                                                                                      
                                </Box> }
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller 
                                    
                                      name={`productPrices.${index}.maxDiscountExpression`}
                                      control={control}
                                      render={({ field }) => <TextField sx={{width:'calc(100% - 8px)'}} id="maxDiscountExpression" label={`${t('Expression')} - ${t('Max. discount')}`} 
                                      multiline rows={2}
                                        inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                        InputProps={{
                                          readOnly: true,
                                          endAdornment: (
                                            <InputAdornment position="end">                                            
                                              <IconButton color="primary" onClick={(event) =>
                                                  handleClickOpenExpression(event, 'max-discount',`Billing${capitalizeFirstLetter(watchProductType)}`, 
                                                    'numeric',getValues().productPrices[index].maxDiscountExpression)}>
                                                <DeveloperModeIcon />
                                              </IconButton>                                                                                               
                                          </InputAdornment>
                                        ) 
                                      }}
                                    />}
                                  />
                                                                                                                                      
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller 
                                                                              
                                        name={`productPrices.${index}.maxLoadedExpression`}
                                        control={control}
                                        render={({ field }) => <TextField sx={{width:'calc(100% - 8px)'}} id="maxLoadedExpression" label={`${t('Expression')} - ${t('Max. loaded')}`} 
                                        multiline rows={2}
                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment position="end">                                            
                                                <IconButton color="primary" onClick={(event) =>
                                                    handleClickOpenExpression(event, 'max-loaded', `Billing${capitalizeFirstLetter(watchProductType)}`,
                                                      'numeric',getValues().productPrices[index].maxLoadedExpression)}>
                                                  <DeveloperModeIcon />
                                                </IconButton>                                                                                               
                                            </InputAdornment>
                                          ) 
                                        }}
                                      />}
                                    />                                                                                                                                      
                                </Box>
                                
                              </Fragment> )})
                            }
                            
                            
                            { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                okText={t('OK')} cancelText='' title={`${t('Expression')} ...`} onCancel={()=> {}} 
                                onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                <EntityExpression entityName={currentEntityName} properties={[]} 
                                  expression={currentExpression} setExpression={setCurrentExpression} />
                              </FormDialog>  }
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>      
                                             
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <ArrayFieldTableEx<IStoreProduct,IStoreProductOption,'id'> 
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headStoreProductOptionCells} rowsPathName='storeProductOptions' 
                              title={t('Options of product')} rowActionIcon={undefined}  
                              //onRowSelected={handleRoleEntitySelected}
                                                  
                              refAppend={refAppendProductOptions as MutableRefObject<(value: Partial<FieldArray<IStoreProduct>> | Partial<FieldArray<IStoreProduct>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdateProductOption as MutableRefObject<(index: number,value: Partial<FieldArray<IStoreProduct>>) => void>}
                              refRemove={refRemoveProductOption as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={[
                              { toolTip: `${t('Add')}...`, onClickIcon: handleAddProductOptions ,icon: AddCircleIcon,  },
                              
                              ]}
                              canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                          />  
                          { openProductOptions && <FormDialog open={openProductOptions} maxWidth='md'
                                okText={t('OK')} cancelText='' title={`${t('Options')} ...`} onCancel={()=> {}} 
                                onClose={()=> {setOpenProductOptions(false);}} onOk={handleClickOkProductOptions}  >
                                <Stack flexDirection='column'>      
                                   <Box sx={{ mt: 1, width: '100%' }} >
                                      <EnhancedTable<IProductOption> 
                                        rows={ (product||defaultProduct).productOptions.filter(x => 
                                            x.isActive && !getValues().storeProductOptions.some(i => i.productOptionId === x.id)) } 
                                        headCells={[            
                                          {id:'code', label : t('Code'),  display: true, type: 'string', width: 20,  },
                                          {id:'name', label : t('Name'),  display: true, type: 'string', width: 30  },
                                          {id:'description', label : t('Description'),  display: true, type: 'string', width: 35  },
                                          {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', width: 15  },
                                        ]} 
                                        title={t('Product options')} objKey={'id'} 
                                        rowActionIcon={undefined}
                                        onRowSelected={undefined} onRowDoubleClick={handleDoubleClickProductOption} 
                                        onRowCheckedSelectChange={undefined} toolbarActions={undefined} order='asc' 
                                        orderBy={'id'} rowCheckedMode='multiple'
                                        stateSelected={[selectedFilterProductOptions, setSelectedFilterProductOptions]}
                                      />
                                   </Box>
                                </Stack>
                              </FormDialog>  }                      
                        </Box>
                                             
                        { openBasicImportForm && <FormDialog open={openBasicImportForm} maxWidth='sm' height='45vh'
                                okText={t('Save')} cancelText={t('Cancel')} title={`${t('Import')} ....`} onCancel={()=> {setOpenBasicImportForm(false);}} 
                                onClose={()=> {setOpenBasicImportForm(false);}} onOk={()=> {setOpenBasicImportForm(false);}}  >
                                    <BasicImportForm<IProduct> 
                                      stateItems={[productsBasicImport, setProductsBasicImport]} 
                                      columns={['name','description', 'code', 'lineOfBusinessCode', 
                                        /*'priceExpression', 'priceExpressionUnity'*/]}
                                      defaultItem={defaultProduct} />
                              </FormDialog> }
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

