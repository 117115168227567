
import { PersonType } from "./Person"

export type ExpenseDetailType = 'production' | 'resource' | 'maintenance' | 'exploitation' | 'other' | 'article';



export interface IExpense {
  id: number,

  proxy: { expenseInfos: IExpenseInfo[],},
  
  supplierId: number,
  issueDate: Date,
  expenseDate: Date,
  expenseNumber: string,
  reference: string,
  description: string,
  isPurchaseOrder: boolean,
  status: string,
  statusDate: Date
  statusPurpose: string,

  supplierType: PersonType,
  supplierCorporationName: string,  
  supplierFirstName: string,
  supplierLastName: string,

  supplierParticularSign: string, 

  supplierPortable1: string,
  supplierPortable2: string,
  supplierEmail1: string,
  supplierEmail2: string, 

  netAmount: number,
  tax: number,
  accessories: number,
  accessoriesTax: number,
  amount: number,

  supplierFullName: string,

  expenseDetails: IExpenseDetail[]
}

export interface IExpenseDetail {
  id: number,

  type: ExpenseDetailType,
  
  expenseId: number,
  resourceId: number,

  netAmount: number,
  tax: number,
  amount: number,
  
  description: string,

  temporaryResourceId: number,
  temporaryResourceOption: string,
  temporaryResourceQuantity: number,
  temporaryResourceUnityPrice: number,
  temporaryResourceUnityTax: number,

  temporaryResourceFilterOption: string,

  resourceDescription: string,

  resourceTypeId: number,
  resourceTypeName: string,

  articleId: number,
  articleOption: string,
  articleUnityPrice: number,
  articleUnityTax: number,
  articleQuantity: number,
  articleFilterOption: string,


  expenseReference: string,
  expenseDescription: string,
  expenseSupplierFullName: string,

  expenseDetailInfos: IExpenseDetailInfo[]
}


export interface IExpenseDetailInfo {
  id: number,

  expenseDetailId: number,
  description: string,
  designation: string,
}

export interface IExpenseInfo {
  id: number,

  expenseId: number,
  description: string,
  designation: string,
}
  
export const defaultExpense : IExpense = {
  id: 0,
  
  proxy: { expenseInfos: [],},

  supplierId: 0,
  issueDate: new Date(),
  expenseDate: new Date(),
  expenseNumber: '',
  reference: '',
  description: '',
  isPurchaseOrder: false,
  status: '95',
  statusDate: new Date(),
  statusPurpose: '00',

  supplierType: 'moral',
  supplierCorporationName: '',
  supplierFirstName: '',
  supplierLastName: '',

  supplierParticularSign: '', 

  supplierPortable1: '',
  supplierPortable2: '',
  supplierEmail1: '',
  supplierEmail2: '', 

  netAmount: 0,
  tax: 0,
  accessories: 0,
  accessoriesTax: 0,
  amount: 0,

  supplierFullName: '',

  expenseDetails: []
}

export const defaultExpenseDetail : IExpenseDetail = {
  id: 0,

  type: 'production',
  
  expenseId: 0,
  resourceId: 0,

  netAmount:0,
  tax: 0,
  amount: 0,
  
  description: '',

  temporaryResourceId: 0,
  temporaryResourceOption: '',
  temporaryResourceQuantity: 0,
  temporaryResourceUnityPrice: 0,
  temporaryResourceUnityTax: 0,
  temporaryResourceFilterOption: '',

  resourceDescription: '',

  resourceTypeId: 0,
  resourceTypeName: '',

  articleId: 0,
  articleOption: '',
  articleUnityPrice: 0,
  articleUnityTax: 0,
  articleQuantity: 0,
  articleFilterOption: '',

  expenseReference: '',
  expenseDescription: '',
  expenseSupplierFullName: '',

  expenseDetailInfos: []
}

export interface IDashboardExpense {
  yearExpenseAmount: number,
  yearExpenseAmountPaid: number,
  yearExpenseUnpaidAmount: number,
  yearExpenseCancelledAmount: number,
  yearExpenseUnvalidatedAmount: number,
  
  monthExpenseAmount: number,

  expensesByType: {type: string, amount: number, amountMonth: number}[],
  unvalidatedExpensesByType: {type: string, amount: number, amountMonth: number}[],
  cancelledExpensesByType: {type: string, amount: number, amountMonth: number}[], 
  unvalidatedCancelledExpensesByType: {type: string, amount: number, amountMonth: number}[],  

  
  productionExpenses: {type: string, amount: number, amountMonth: number}[],
  exploitationExpenses: {type: string, amount: number, amountMonth: number}[],
  otherExpenses: {type: string, amount: number, amountMonth: number}[],
  
  resourceExpenses: {type: string, amount: number, amountMonth: number}[],
  maintenanceExpenses: {type: string, amount: number, amountMonth: number}[],
  
  
}

export const defaultDashboardExpense : IDashboardExpense = {
  yearExpenseAmount: 0,
  yearExpenseAmountPaid: 0,
  yearExpenseUnpaidAmount: 0,
  yearExpenseCancelledAmount: 0,
  yearExpenseUnvalidatedAmount: 0,
  
  monthExpenseAmount: 0,

  expensesByType: [],
  unvalidatedExpensesByType: [],
  cancelledExpensesByType: [],
  unvalidatedCancelledExpensesByType: [],      
  
  productionExpenses: [],
  exploitationExpenses: [],
  otherExpenses: [],
  resourceExpenses: [],
  maintenanceExpenses: [],
  
}


export interface IExpenseSearch {

  reference: string,
  description: string,

  supplierFullName: string
}