
import { PersonType } from "features/production/models/Person";
import { IProductOption } from "features/setup/models/Product";

export interface IDelivery {
    id: number,
    reference: string,

    purchaseOrderId: number,

    description: string,
    deliveryDate: Date,
    issueDate: Date,



    supplierId: number,
    supplierType: PersonType,
    supplierCorporationName: string,  
    supplierFirstName: string,
    supplierLastName: string,

    supplierParticularSign: string, 

    supplierPortable1: string,
    supplierPortable2: string,
    supplierEmail1: string,
    supplierEmail2: string, 

    netAmount: number,
    tax: number,
    accessories: number,
    accessoriesTax: number,
    amount: number,

    status: string,
    statusDate: Date,
    statusPurpose: string,

    defaultStoreId: number,
    
    deliveryArticles: IDeliveryArticle[],
  }

  export interface IDeliveryArticle {
    id: number, 
    deliveryId: number,
    articleId: number,
    option: string,

    productOptionId: number,
    productOptions: IProductOption[],

    quantityDemand: number, 
    quantity: number, 
    quantityUsed: number, 

    unityNetAmount: number,
    unityTax: number,
    unityAmount: number,

    articleName: string,
    articleFilterOption: string,

    deliveryArticleStores: IDeliveryArticleStore[],

    amount: number
  }

  export interface IDeliveryArticleStore {
    id: number, 
    deliveryArticleId: number, 
    storeId: number,
    quantity: number,
    quantityDemand: number,

    storeName: string
  }

  export interface IArticleStock {
    id: number,

    articleId: number,
    option: string, 

    articleName: string,
    articleFilterOption: string,

    storeId: number,
    storeName: string,
    
    quantityDelivery: number,
    quantityBilling: number,
    quantityTransfertOut: number,
    quantityTransfertIn: number,
    quantityMovementOut: number,
    quantityMovementIn: number,

    quantityTransfertVariation: number,
    quantityMovementVariation: number,

    quantityInventoryVariation: number,

    quantity: number
  }
  

export const defaultDelivery : IDelivery = {
  id: 0,
  reference: '',
  purchaseOrderId: 0,
  description: '',
  deliveryDate: new Date(),
  issueDate: new Date(),

  supplierId: 0,
  supplierType: 'moral',
  supplierCorporationName: '',  
  supplierFirstName: '',
  supplierLastName: '',

  supplierParticularSign: '', 

  supplierPortable1: '',
  supplierPortable2: '',
  supplierEmail1: '',
  supplierEmail2: '', 
  
  netAmount: 0,
  tax: 0,
  accessories: 0,
  accessoriesTax: 0,
  amount: 0,

  status: '95',
  statusDate: new Date(),
  statusPurpose: '',

  defaultStoreId: 0,
  deliveryArticles: [],
  }

  export const defaultDeliveryArticle : IDeliveryArticle = {
    id: 0, 
    deliveryId: 0,
    articleId: 0,
    option: '',

    productOptionId: 0,
    productOptions: [],

    quantityDemand: 0, 
    quantity: 0, 
    quantityUsed: 0, 

    unityNetAmount: 0,
    unityTax: 0,
    unityAmount: 0,

    articleName: '',
    articleFilterOption: '',

    deliveryArticleStores: [],

    amount: 0
  }

  export const defaultDeliveryArticleStore : IDeliveryArticleStore = {
    id: 0, 
    deliveryArticleId: 0, 
    storeId: 0,
    quantity: 0,
    quantityDemand: 0,

    storeName: ''
  }

  export interface IDeliverySearch {    
    reference: string,
    description: string
  }

  export interface IDashboardDelivery {   

    yearDeliveryAmount: number,   
    yearDeliveryAmountPaid: number, 
    yearDeliveryUnpaidAmount: number,    
    yearDeliveryCancelledAmount: number,
    yearDeliveryUnvalidatedAmount: number,
    
    monthDeliveryAmount: number,    
    
    deliveryArticles: {articleName: string, articleOption: string, 
          quantity: number, quantityMonth: number, netAmount: number, amount: number, amountMonth: number}[],

    articles: {articleId: number, articleName: string, articleOption: string, 
      deliveredQuantity: number, quantitySold: number, quantitySoldYear: number, remainingQuantity: number}[],
    
  }

  export const defaultDashboardDelivery : IDashboardDelivery = {
    yearDeliveryAmount: 0,   
    yearDeliveryAmountPaid: 0, 
    yearDeliveryUnpaidAmount: 0,    
    yearDeliveryCancelledAmount: 0,
    yearDeliveryUnvalidatedAmount: 0,
    
    monthDeliveryAmount: 0,    

    deliveryArticles: [],
    articles: []
  }