import React  from 'react';

import {TextField} from '@mui/material';


export interface TextFieldRightProps {
  handleKeyDow?: (event : React.KeyboardEvent<HTMLInputElement>) => void,
  props : any
}

const TextFieldRight = (props : any) => {
    return(<TextField {...props} inputProps={{style: { textAlign: 'right' }}} /> );
  }


export default TextFieldRight;